<div class="saved-list-modals">

  <div aria-label="Confirm Deactivation" class="confirmDeactivateDialog share-my-list" role="dialog" tabindex="-1">
    <div class="md-toolbar-tools toolbar">
      <h2 *ngIf="step == 1">Select List to Share (Step 1 of 3)</h2>
      <h2 *ngIf="step == 2">Select Users to Share List With (Step 2 of 3)</h2>
      <h2 *ngIf="step == 3">Share Lists Summary (Step 3 of 3)</h2>
      <span flex="" class="flex"></span>
      <button tabindex="0" class="md-icon-button md-button" type="button" (click)="onClose()" (keypress)="onClose()"
        aria-label="close dialog" style="margin-left: auto; margin-right: 0;">
        <i class="fa fa-times ng-scope"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <!--Main body of dialog-->
      <div>
        <!--Step 1-->
        <div class="layout-column" *ngIf="step == 1">
          <p>Use the List Search box to search for the name of the list you want to share, then select the desired list
            from the dropdown results.
            The list name will appear in the Selected Lists box.</p>
          <br />
          <div class="layout-row selectButtons">
            <b>LIST SEARCH:</b> &nbsp;
            <p>Select</p>
            <a tabindex="0" style="margin-inline: 0.4rem;" (click)="onSelectAll()" (keypress)="onSelectAll()">All</a>
          </div>
          <ng-container>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectList($event)">
              <ng-container *ngFor="let item of listItems; trackBy: trackByListName">
                <mat-option [value]="item.saveListKey" *ngIf="!checkListsForItem(item)">
                  {{item.saveListName}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
            <div class="auto-search-section">
              <input #searchTerm class="auto-complete" type="text" (ngModelChange)="onListSearchChange($event)"
                [(ngModel)]="savedListsSearchText" matInput [matAutocomplete]="auto">
              <button *ngIf="savedListsSearchText" matSuffix mat-icon-button aria-label="Clear"
                (click)="savedListsSearchText = ''">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </ng-container>
          <!--Display this message if there are no lists with students in them (My Default List is included in mysavedListsArray but should not count)-->
          <b class="custom-label" style="margin-top:10px" *ngIf='shareInfo.mySavedListsArray.length <= 1'>You have no
            Lists available to share.</b>
          <mat-divider></mat-divider>
          <br>
          <p>Use the green “X” to remove lists from the selection.
            Select “Next” to choose the users who will receive the lists.</p>
          <br />
          <div class="layout-row selectButtons">
            <b>SELECTED LIST(S):</b> &nbsp;
            <p>Select</p>
            <a tabindex="0" style="margin-inline: 0.4rem;" (click)="onSelectNone()" (keypress)="onSelectNone()">None</a>
          </div>
          <section tabindex="0" class="selected-area">
            <mat-chip-listbox class="selected-items">
              <mat-basic-chip *ngFor="let item of shareInfo.multiSelectMySavedListsData">
                <button tabindex="0" matChipRemove (click)="removeList(item)">
                  <mat-icon tabindex="0" (keydown.enter)="removeList(item)">clear</mat-icon>
                </button>
                {{item.saveListName}}
              </mat-basic-chip>
            </mat-chip-listbox>
          </section>
        </div>

        <!--Step 2-->
        <div class="layout-column" *ngIf="step == 2">
          <p>Search for the users who will receive the lists using their username, first name, last name, or email.
            Select the desired users from the dropdown results. The users will appear in the Selected Users box.</p>
          <br />
          <div class="layout-row selectButtons">
            <b>USER SEARCH:</b> &nbsp;
            <!--no Select All for step 2-->
          </div>
          <ng-container>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectUser($event)">
              <ng-container *ngFor="let item of userItems; trackBy: trackByUserDisplayString">
                <mat-option [value]="item.userKey" *ngIf="!checkUsersForItem(item)">
                  {{item.userDisplayString}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
            <div class="auto-search-section">
              <input #searchTerm class="auto-complete" type="text" (ngModelChange)="onUserSearchChange($event)"
                [(ngModel)]="shareUsersSearchText" matInput [matAutocomplete]="auto">
              <button *ngIf="shareUsersSearchText" matSuffix mat-icon-button aria-label="Clear"
                (click)="shareUsersSearchText = ''">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </ng-container>
          <mat-divider></mat-divider>
          <br>
          <p>Use the green “X” to remove users from the selection. Select “Review” to view the Share Lists Summary and
            verify your selected lists and users.</p>
          <br />
          <div class="layout-row selectButtons">
            <b>SELECTED USER(S):</b> &nbsp;
            <p>Select</p>
            <a tabindex="0" style="margin-inline: 0.4rem;" (click)="onSelectNone()" (keypress)="onSelectNone()">None</a>
          </div>
          <section class="selected-area">
            <mat-chip-listbox class="selected-items">
              <mat-basic-chip *ngFor="let item of shareInfo.multiSelectSelectedUsers">
                <button tabindex="0" matChipRemove (click)="removeUser(item)">
                  <mat-icon tabindex="0" (keydown.enter)="removeUser(item)">clear</mat-icon>
                </button>
                {{item.userDisplayString}}
              </mat-basic-chip>
            </mat-chip-listbox>
          </section>
        </div>

        <!--Step 3-->
        <div class="layout-column" *ngIf="step == 3">
          <p>Verify the selected lists and users. Use the green “X” to remove list or user from the selections. Enter an
            optional comment to provide additional information regarding the shared lists. Select “Submit” to share the
            lists. The users will receive an email notification of the shared lists and be prompted to accept or delete
            the list on their My Lists page.</p>
          <b>You are sharing the following list(s):</b> &nbsp;
          <section class="selected-area">
            <mat-chip-listbox class="selected-items">
              <mat-basic-chip *ngFor="let item of shareInfo.multiSelectMySavedListsData">
                <button tabindex="0" matChipRemove (click)="removeList(item)">
                  <mat-icon tabindex="0" (keydown.enter)="removeList(item)">clear</mat-icon>
                </button>
                {{item.saveListName}}
              </mat-basic-chip>
            </mat-chip-listbox>
          </section>
          <b>With the following users(s):</b> &nbsp;
          <section class="selected-area">
            <mat-chip-listbox class="selected-items">
              <mat-basic-chip *ngFor="let item of shareInfo.multiSelectSelectedUsers">
                <button tabindex="0" matChipRemove (click)="removeUser(item)">
                  <mat-icon tabindex="0" (keydown.enter)="removeUser(item)">clear</mat-icon>
                </button>
                {{item.userDisplayString}}
              </mat-basic-chip>
            </mat-chip-listbox>
          </section>
          <label class="custom-label">Add a comment below</label>
          <textarea class="custom-input" rows="3" [(ngModel)]="comments" maxlength="250"></textarea>
        </div>

      </div>

      <!--Navigation buttons-->
      <div class="layout-row" style="margin: 0px 25px 0px 0px;">
        <a tabindex="0" class="resetButton" style="margin: 25px 0px 0px 30px" (click)="shareMyListBack()"
          (keypress)="shareMyListBack()" *ngIf="step == 2 || step == 3">
          Back
        </a>
        <span flex></span>
        <button *ngIf="step == 1" class="md-button right-align"
          [disabled]="shareInfo.multiSelectMySavedListsData.length <= 0" (click)="shareMyListNext()"
          (keypress)="shareMyListNext()">
          Next
        </button>
        <button *ngIf="step == 2" class="md-button right-align"
          [disabled]="shareInfo.multiSelectSelectedUsers.length <= 0" (click)="shareMyListNext()"
          (keypress)="shareMyListNext()">
          Review
        </button>
        <button *ngIf="step == 3" class="md-button right-align"
          [disabled]="shareInfo.multiSelectMySavedListsData.length <= 0 || shareInfo.multiSelectSelectedUsers.length <= 0"
          (click)="shareMyListNext()" (keypress)="shareMyListNext()">
          Submit
        </button>
      </div>
    </div>

  </div>
</div>