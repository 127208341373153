import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';
// import * as angular from "angular";
import { AppConstants } from 'src/app/config/index.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormsModule, FormControl, FormGroup, Validators, Form, FormArray } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { catchError, EMPTY, Observable, of, tap } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/toast.service';
import { Toast } from 'ngx-toastr';
import { Jobs } from '../selectJobTitles/selectJobTitles.component';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { clone, cloneDeep, isEqual } from "lodash";
// import { equals } from 'angular';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { IStateDataShort } from 'src/app/types/interfaces';

@Component({
  selector: 'confirmDeactivate',
  templateUrl: './confirmDeactivate.html',
  styleUrls: ['./userDetails.component.scss']
})
export class ConfirmDeactivate {

  @Output() callUpdate = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<ConfirmDeactivate>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router) {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  confirmDeactivation() {
    // Parent (i.e. User Details) has subscribed to this output event so it will be triggered with parameter value set to 'true'
    this.callUpdate.emit(true);
  }
}

@Component({
  selector: 'resetPassword',
  templateUrl: './resetPassword.html',
  styleUrls: ['./userDetails.component.scss']
})
export class ResetPassword {

  constructor(public dialogRef: MatDialogRef<ResetPassword>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _Toast: ToastService,
    private userService: UserService) {
  }

  onSubmit() {
    this.dialogRef.close();
    //console.warn(this.form.value);
  }

  resetPasswordApiCall() {
    let apiCall = this.userService.adminResetPassword(this.data.username);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log('data: ', data);
        this.dialogRef.close();
        this._Toast.showToast('Password reset!');
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error resetting user's password from the userDetails component: ", error);
        this.dialogRef.close();
        this._Toast.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'resetMFA',
  templateUrl: './resetMFA.html',
  styleUrls: ['./userDetails.component.scss']
})
export class ResetMFA {
  private isMFAConfigured: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;

  constructor(public dialogRef: MatDialogRef<ResetMFA>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private _Toast: ToastService) {

    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.username = data.username;
  }

  resetMFA() {
    let apiCall = this.userService.adminResetMFA(this.username);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log('data: ', data);
        this.isMFAConfigured = 'No';
        this._Toast.showToast('MFA reset!');
        this.dialogRef.close();
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error resetting user's MFA from the userDetails component: ", error);
        this._Toast.showToast(error.data.developerMessage, 0);
        this.dialogRef.close();
        subscribed.unsubscribe();
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'confirmCancel',
  templateUrl: './confirmCancel.html',
  styleUrls: ['./userDetails.component.scss']
})
export class ConfirmCancel {

  constructor(public dialogRef: MatDialogRef<ConfirmCancel>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router) {

  }

  closeDialog() {
    this.dialogRef.close();
  }

  cancelGoBack() {
    this.dialogRef.close();
    this._router.navigate(['home']);
  }
}

export interface UserJob {
  selected: boolean;
  jobTitleDesc: string;
  jobTitle: string;
  otherJobTitleDesc: string;
}

export interface State {
  stateKey: number,
  name: string
}

@Component({
  selector: 'newUser',
  templateUrl: './newUser.component.html',
  styleUrls: ['./userDetails.component.scss']
})
export class NewUser implements OnInit {

  loginForm: {
    firstName: string,
    lastName: string,
    email: string,
    userPhone: string
  };
  newUserJobsArray: UserJob[];
  newUserRolesObj: UserJob;
  formData: {
    firstName: string,
    lastName: string,
    email?: string,
    userPhone?: string,
    userPhoneExt?: string,
    activationDateStr?: string,
    expirationDateStr?: string,
    addLine1?: string,
    addLine2?: string,
    addCity?: string,
    userState?: string,
    districts?: string[],
    regions?: string[],
    rolesStr?: string[],
    disableUser?: string,
    receiveNotification?: string,
    jobTitles: UserJob[]
  };
  rolesObj: UserJob;
  rolesObjCopy: UserJob;
  jobTitle: {
    newOther: string,
    other: string
  };
  rolesArray = AppConstants.userRoles.allUsers;
  userRoles = AppConstants.userRoles;
  private authFlags: any;
  firstName: string;
  //states: State[];
  states: IStateDataShort[] = [];
  authRoleClasses: any;
  adminState: any;
  disabledFlag: boolean;
  authorizedRoleClasses: any;
  stateRegions: any;
  districtsArray: any;
  jobsArray: any;
  newUserOtherIsSelected: boolean;
  //newUserSelectedJobsList: string[];
  roleFlag: boolean;
  newUserForm: FormGroup;
  floatLabelControl: FormControl;
  errors: any;

  constructor(private commonModule: CommonModule,
    private _router: Router,
    private userService: UserService,
    private stateService: StateService,
    private _Toast: ToastService,
    private authService: AuthService,
    private reportInfoService: ReportInfoService,
    private fb: FormBuilder,
    public responsiveUI: ResponsiveUIService) {

    this.loginForm = {
      firstName: '',
      lastName: '',
      email: '',
      userPhone: ''
    }

    this.formData = {
      firstName: '',
      lastName: '',
      jobTitles: new Array(),
      userState: '-1',
      regions: new Array(),
      districts: new Array()
    }

    this.newUserRolesObj = {
      selected: false,
      jobTitleDesc: '',
      jobTitle: '',
      otherJobTitleDesc: ''
    };

    this.jobTitle = {
      newOther: '',
      other: ''
    }

    //this.newUserSelectedJobsList = new Array<string>();
  }
  ngOnInit(): void {
    this.newUserForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      userPhone: new FormControl('', [Validators.required]),
      userPhoneExt: new FormControl(''),
      activeDate: new FormControl(''),
      expirationDate: new FormControl(''),
      addLine1: new FormControl(''),
      addLine2: new FormControl(''),
      addCity: new FormControl(''),
      addState: new FormControl('-1'),
      floatLabel: new FormControl('auto' as FloatLabelType),
      jobTitles: new FormArray([], Validators.required),
      rolesStr: new FormArray([], Validators.required),
      districts: new FormArray([]),
      regions: new FormArray([])
    });

    this.getStateList();
    this.getCurrentUser();
    this.getJobTitles('');
    this.checkRoleClasses();
  }

  newUserFormErrorCallback = (data) => {
    let controlName = data.controlName;
    let errorName = data.errorName;

    this.errors.firstName = (this.newUserForm.controls[controlName].touched && this.newUserForm.controls[controlName].hasError(errorName));
  }
  newUserFormError = (controlName: string, errorName: string) => {
    // let controlName = data.controlName;
    // let errorName = data.errorName;
    return this.newUserForm.controls[controlName].hasError(errorName);
  }

  // When adding new user: This function checks if any job titles have been selected. 
  // If 'Other' option is selected, then it also verifies if the Other Job Title text has been entered.
  areAllValidJobTitleSelectedForNewUser() {
    var foundValidJobTitleSelected = false;

    for (let item of this.newUserJobsArray) {
      if (item.selected) {
        if (item.jobTitleDesc === 'Other') {
          if (item.otherJobTitleDesc.trim().length <= 0) {
            return false;
          } else {
            foundValidJobTitleSelected = true;
          }
        } else {
          foundValidJobTitleSelected = true;
        }
      }
    }

    return foundValidJobTitleSelected;
  }

  // This function checks that at least 1 district is selected when DDA role is selected and
  // at least 1 region is selected when RDA or RUA role is selected.
  areDistrictRegionSelectionValid(rolesObj = this.rolesObj) {
    var flag = false;
    if (rolesObj['District Data Administrator']) {
      if (this.formData.districts.length === 0) {
        flag = true;
      }
    }
    if (rolesObj['Regional Data Administrator'] || rolesObj['Regional User Administrator']) {
      if (this.formData.regions.length === 0) {
        flag = true;
      }
    }
    return flag;
  }

  addNewUser() {
    let formData: any = this.newUserForm.value;

    formData.rolesStr = this.setRoles(formData.rolesStr);
    formData.districts = this.formData.districts;
    formData.regions = this.formData.regions;

    formData.receiveNotification = 'YES';
    if (!formData.userState) {
      formData.userState = formData.addState;
    }

    /*
    apiCall.pipe(
      tap(data => {
        this._Toast.hideAllToast();
        this._Toast.showToast('User created!');
        this._router.navigate(['userDetails'], {
          queryParams: {
            username: data.username
          }
        });
      }),
      catchError((error) => {
        this._Toast.hideAllToast();
        this._Toast.showToast(error.error.developerMessage, 0);
        return of(EMPTY)
      })
      ).subscribe()
    */

    let apiCall = this.userService.addUser(formData);
    apiCall.pipe(
      tap(data => {
        this._Toast.hideAllToast();
        this._Toast.showToast('User created!');

        let params = { username: data.username };
        this._router.navigate(['userDetails', params]);
      }),
      catchError((error) => {
        this._Toast.hideAllToast();
        this._Toast.showToast(error.error.developerMessage, 0);
        return of(EMPTY);
      })
    ).subscribe();
    /* let subscribed = apiCall.subscribe({
      next: data => {
        console.log('data: ', data);
        this._Toast.hideAllToast();
        this._Toast.showToast('User created!');
        this._router.navigate(['userDetails'], {
          queryParams: {
            username: data.username
          }
        });
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error adding a new user from the userDetails component: ", error);
        this._Toast.hideAllToast();
        this._Toast.showToast(error.error.developerMessage, 0);
        subscribed.unsubscribe();
      }
    }); */
  }

  setRoles(roles) {
    var rolesEnumArray = new Set();
    for (var key in this.userRoles) {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i] === this.userRoles[key]) {
          rolesEnumArray.add(key);
        }
      }
    }
    return Array.from(rolesEnumArray.values());
  }

  async getAdminFlags() {
    let data = await this.stateService.getStateRegionalAdmin();
    console.log('getAdminFlags() data: ', data);
    this.authFlags = data[0];
  }

  /* getAdminFlags() {
    let apiCall = this.stateService.getStateRegionalAdmin();
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log('data: ', data);
        this.authFlags = data[0];

        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error retrieving state regional admin from the userDetails component: ", error);
        this._Toast.showToast(error.data.developerMessage, 0);

        subscribed.unsubscribe();
      }
    }); 
  }*/

  checkRoleDisabled(role, type = "update") {
    var flag = false;

    if (!this.authRoleClasses || !this.adminState) {
      return true;
    }
    if (role === 'Super User') {
      if (!this.authRoleClasses.superUser || this.authRoleClasses.omeUser) {
        flag = true;
      }
    } else if (role === 'OME User Administrator' || role === 'Government Administrator' || role === 'MSIX Privacy Act Administrator') {
      if (!this.authRoleClasses.govUser) {
        flag = true;
      }
    } else if (role === 'State Batch Submitter') {
      if (!this.authRoleClasses.stateAdmin || this.authRoleClasses.omeUser) {
        flag = true;
      }
    } else if (role === 'District Data Administrator') {
      if ((!this.authRoleClasses.stateAdmin && !this.authRoleClasses.regionalAdmin && !this.authRoleClasses.districtAdmin) ||
        this.adminState !== this.formData.userState || this.authRoleClasses.govUser) {
        flag = true;
      }
    } else if (role === 'Regional Data Administrator') {
      if (/*this.authFlags.rda === "Disabled" ||*/ (!this.authRoleClasses.stateAdmin && !this.authRoleClasses.regionalAdmin) ||
        this.adminState !== this.formData.userState || this.authRoleClasses.govUser) {
        flag = true;
      }
    } else if (role === 'State Data Administrator') {
      if (!this.authRoleClasses.stateAdmin || this.authRoleClasses.omeUser) {
        flag = true;
      }
    } else if (role === 'Regional User Administrator') {
      if (/*this.authFlags.rua === "Disabled" ||*/ (!this.authRoleClasses.stateAdmin && !this.authRoleClasses.regionalAdmin) ||
        this.adminState !== this.formData.userState || this.authRoleClasses.govUser) {
        flag = true;
      }
    } else if (role === 'State User Administrator') {
      if (!this.authRoleClasses.stateAdmin) {
        flag = true;
      }
    } else if (role === 'State Regional Administrator') {
      if (!this.authRoleClasses.stateAdmin || this.authRoleClasses.omeUser) {
        flag = true;
      }
    } else if (role === 'MSIX Primary User' || role === 'Secondary User') {
      if (this.authRoleClasses.omeUser) {
        flag = true;
      }
    }
    if (type != 'new') {
      return (flag || this.disabledFlag);
    }

    return flag;
  }

  checkRoleClasses() {
    this.authService.isRoleClassAuthorized(['superUser', 'omeUser', 'govUser', 'stateAdmin', 'regionalAdmin', 'districtAdmin']).then(data => {
      let isAuthorizedForClasses: any = data;
      if (isAuthorizedForClasses) {
        this.authRoleClasses = isAuthorizedForClasses;
        this.getAdminFlags();

        this.adminState = this.reportInfoService.returnObj.userState;
        this.getStateRegions();
        this.getDistricts();
      } else {
        console.error('Error occurred in checkRoleClasses() for newUser.components.');
      }
    });
  }

  /* async getStateList(): Promise<void> {
    await this.stateService.getStates().then(
      data => {
        console.log('data: ', data);
        this.states = data;
      }, 
      error => {
        console.log("Error retrieving user's states from the newUser component: ", error);
        this._Toast.showToast(error.data.developerMessage, 0);
      }
    );
  } */

  /* getStateList() {
    this.stateService.states$.subscribe(res => {
      this.states = res;
      //res.forEach(state => this.form.addControl(state.stateKey, new FormControl()));
    });
  } */

  async getStateList(): Promise<void> {
    await this.stateService.getStates().then(
      data => {
        console.log('data: ', data);
        this.states = data;
      },
      error => {
        console.log("Error retrieving user's states from the userDetails component: ", error);
        this._Toast.showToast(error.data.developerMessage, 0);
      }
    );
  }

  getStateRegions() {
    this.authService.isRoleClassAuthorized(['stateCreateAdmin', 'regionalAdmin']).then(data => {
      let authorizedRoleClasses: any = data;

      //var condition = angular.equals(res, {})
      //if (!condition && this.adminState>-1) {
      if (!(isEqual(this.authorizedRoleClasses, {})) && this.adminState > -1) {
        let apiCall = this.stateService.getStateRegions();
        let subscribed = apiCall.subscribe({
          next: data => {
            console.log("getStateRegions(): ", data);
            this.stateRegions = data;

            subscribed.unsubscribe();
          },
          error: error => {
            console.log("Error retrieving state's regions from the userDetails component: ", error);
            this._Toast.showToast(error.data.developerMessage, 0);

            subscribed.unsubscribe();
          }
        });

      };
    });
  }

  getDistricts() {
    this.authService.isRoleClassAuthorized(['stateCreateAdmin', 'regionalAdmin']).then(data => {
      let authorizedRoleClasses: any = data;

      //var condition = angular.equals(res, {})
      //if (!condition && this.adminState>-1) {
      if (!(isEqual(this.authorizedRoleClasses, {})) && this.adminState > -1) {
        let apiCall = this.stateService.getUserDistricts();
        let subscribed = apiCall.subscribe({
          next: data => {
            console.log("getUserDistricts(): ", data);
            this.districtsArray = data;

            subscribed.unsubscribe();
          },
          error: error => {
            console.log("Error retrieving districts from the userDetails component: ", error);
            this._Toast.showToast(error.data.developerMessage, 0);

            subscribed.unsubscribe();
          }
        });

      };
    });
  }

  //Gets the list of the job titles to display and select
  getJobTitles(username) {
    this.jobsArray = new Array();
    this.newUserJobsArray = [];
    let apiCall = this.userService.getJobTitles(username);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log('data: ', data);
        this.jobsArray = data;
        this.newUserJobsArray = data;
        for (let job of data) {
          if (job.jobTitleDesc === 'Other') {
            this.jobTitle.other = job.otherJobTitleDesc;
            this.jobTitle.newOther = job.otherJobTitleDesc;
          }
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error retrieving user's job titles from the userDetails.newUser component: ", error);
        this._Toast.showToast(error.data.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  getCurrentUser() {
    // Get the current user data and set the user's state on the form.
    this.userService.getCurrentUser().then(data => {
      let userData: any = data;
      //console.log('getCurrentUser(): ', userData);
      this.formData.userState = userData.userState;
      this.newUserForm.controls['addState'].setValue(userData.userState);
      console.log('getCurrentUser(): ', userData.userState);
    });
  }

  addRemoveJobNewUser(event, job: UserJob) {
    const slctdJobTitles = <FormArray>this.newUserForm.get('jobTitles') as FormArray;

    if (event.checked) {
      if (job.jobTitleDesc === 'Other') {
        this.jobTitle.newOther = '';
        job.otherJobTitleDesc = '';
      }
      job.selected = true;

      slctdJobTitles.push(new FormControl(job));

    } else {
      job.selected = false;
      if (job.jobTitleDesc === 'Other') {
        job.otherJobTitleDesc = '';
      }

      let i = slctdJobTitles.controls.findIndex(x => x.value.jobTitleDesc === job.jobTitleDesc);
      if (i >= 0) {
        slctdJobTitles.removeAt(i);
      }
    }

    /* var index; 
    for (let item of this.newUserJobsArray){
      if (item.jobTitleDesc === job.jobTitleDesc){
        job.selected = !job.selected;
        
        index = this.newUserSelectedJobsList.indexOf(job.jobTitleDesc);
        if (index === -1) {
          this.newUserSelectedJobsList.push(job.jobTitleDesc);
        } else {
          this.newUserSelectedJobsList.splice(index, 1);
        }
      }
    }
    this.formData.jobTitles = this.newUserJobsArray; */
  }

  addRemoveRole(event) {
    let role = event.source.value;

    const slctdUserRoles = <FormArray>this.newUserForm.get('rolesStr') as FormArray;

    if (event.checked) {
      slctdUserRoles.push(new FormControl(role));
      this.newUserRolesObj[role] = true;
    } else {
      const i = slctdUserRoles.controls.findIndex(x => x.value === role);
      if (i >= 0) {
        slctdUserRoles.removeAt(i);
        this.newUserRolesObj[role] = false;
      }
    }

    if (((role === 'Regional Data Administrator') || (role === 'Regional User Administrator')) && (!this.isRegionRoleSelected())) {
      this.formData.regions = [];
    } else if ((role === 'District Data Administrator') && (!this.isDistrictDataRoleSelected())) {
      this.formData.districts = [];
    }
  }

  isAssignedRegion(region) {
    return this.formData.regions.indexOf(region.key) !== -1;
  }

  isAssignedDistrict(district) {
    return (this.formData.districts.indexOf(district.key) !== -1);
  }

  // This function is a common function use to add region/district to the selected list.
  addRegionOrDistrict(region, regList) {
    var regionIndex = regList.indexOf(region.key);
    if (regionIndex !== -1) {
      regList.splice(regionIndex, 1);
    } else {
      regList.push(region.key);
    }
  }

  // Determines if either 'Regional User Administrator' or 'Regional Data Administrator' are selected.
  isRegionRoleSelected() {
    return this.isRoleSelected(['Regional User Administrator', 'Regional Data Administrator']);
  }

  // Determines if either 'District Data Administrator' are selected.
  isDistrictDataRoleSelected() {
    return this.isRoleSelected(['District Data Administrator']);
  }

  // Determines if any of the roles from the given role array has been selected
  private isRoleSelected(roles: string[]) {
    const slctdUserRoles = <FormArray>this.newUserForm.get('rolesStr') as FormArray;
    let roleSelected = false;

    for (let count = 0; !roleSelected && count < roles.length; count++) {
      let idx = slctdUserRoles.controls.findIndex(
        x => (x.value === roles[count])
      );

      if (idx >= 0) {
        // Found role
        roleSelected = true;
      }
    }

    return roleSelected;
  }
}

@Component({
  selector: 'userDetails',
  templateUrl: './userDetails.component.html',
  styleUrls: ['./userDetails.component.scss']
})
export class UserDetailsComponent implements OnInit {
  router: string;
  username: string;
  jobsArray: any[];
  jobsArrayCopy: any[];
  districtsArray: any;
  states: State[];
  //selectedJobsList: string[];
  //newUserSelectedJobsList = [];
  authRoleClasses: any;
  authFlags: any;
  adminState: any;
  formData: UserData;
  currUser: UserData;
  otherJobTitle: string = '';
  /* jobTitle: {
    other: string
  }; */
  userRoles = AppConstants.userRoles;
  rolesArray = AppConstants.userRoles.allUsers;
  rolesArrayWithSelection = [];
  rolesArrayWithSelectionCopy = [];
  //newUserRolesObj = {};
  authorizedRoleClasses = {};
  stateRegions: any = [];
  disabledFlag: boolean;
  roleFlag: boolean;    // Indicates if the role list has changed (true = changed)
  otherIsSelected: boolean;
  isMFAConfigured: string;
  userShowDistricts: boolean;
  isSuperUser: boolean;
  hasRegionOnDistrictTouched: boolean = false;
  userDetailsForm: FormGroup;
  showModal: boolean;
  errors: string[] = new Array<string>();
  errorCheck: any;
  inputError: boolean = false;

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private stateService: StateService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private _router: Router,
    public dialog: MatDialog,
    private _Toast: ToastService,
    private activatedRoute: ActivatedRoute,
    private reportInfoService: ReportInfoService,
    public responsiveUI: ResponsiveUIService) {
    this.router = _router.url;

    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.username = (routeParams.get('username'));

    this.formData = this.setDefaultUserData();

    this.currUser = this.setDefaultUserData();

    this.otherJobTitle = '';
    /* this.jobTitle = {
      other: ''
    }; */
    this.jobsArray = [];
    this.jobsArrayCopy = [];
    this.districtsArray = [];
    this.disabledFlag = false;
    this.roleFlag = false;
    this.otherIsSelected = false;
    this.isMFAConfigured = 'No';
    this.userShowDistricts = false;
    this.isSuperUser = false;
    //this.adminState = null;
    this.rolesArrayWithSelection = new Array();
    this.rolesArrayWithSelectionCopy = new Array();
  }

  ngOnInit(): void {
    this.userDetailsForm = new FormGroup({
      firstName: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required]),
      lastName: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required]),
      email: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required, Validators.email]),
      userPhone: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required]),
      userPhoneExt: new FormControl({ value: '', disabled: this.disabledFlag }),
      disableUser: new FormControl({ value: '', disabled: this.disabledFlag }),
      activeDate: new FormControl({ value: '', disabled: this.disabledFlag }),
      expirationDate: new FormControl({ value: '', disabled: this.disabledFlag }),
      //robDate: new FormControl({value: '', disabled: this.disabledFlag}),
      //firstLogin: new FormControl(''),
      //lastLogin: new FormControl(''),
      addLine1: new FormControl({ value: '', disabled: this.disabledFlag }),
      addLine2: new FormControl({ value: '', disabled: this.disabledFlag }),
      addCity: new FormControl({ value: '', disabled: this.disabledFlag }),
      addState: new FormControl({ value: '', disabled: this.disabledFlag }),
      userState: new FormControl({ value: '', disabled: this.disabledFlag })/* ,  // Need to set the user State and map it to the address State mat-select as the backend updates the address state to match this. (See MSIX-5764)
      jobTitles: new FormArray([], Validators.required),
      rolesStr: new FormArray([], Validators.required) */
    });

    //this.checkRoleDisabled(role, type="update");

    // Load the states list
    this.getStateList();

    if (this._router.url.includes('username')) {
      if (this.username === undefined) {
        this.activatedRoute.queryParams.subscribe(params => {
          this.username = params['username'];
        });
      }
      this.getUserDetails();
    } else {
      // No username provided for the User Details page. Something went wrong.
      console.error('No username provided for the User Details page. This should not happen.');
      return;
    }

    /* if (this._router.url.includes('userDetails')) {
      this.getUserDetails();
    } else {
      this.getCurrentUser();
    } */

    this.userAuthDistricts();
    this.checkRoleClasses();
  }

  private setDefaultUserData() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      userPhone: '',
      userPhoneExt: '',
      activeDate: '',
      expirationDate: '',
      addLine1: '',
      addLine2: '',
      addCity: '',
      addState: '',
      robDate: '',
      firstLogin: '',
      lastLogin: '',
      isMFA: false,
      username: '',
      showRegistrationDecision: true,
      isRegistrationWorkflow: false,
      mfaCode: '',
      password: '',
      needQRCode: false,
      verifyQRCode: false,
      disableUser: '',
      districts: [],
      regions: [],
      rolesStr: [],
      receiveNotification: '',
      jobTitles: [],
      userState: '',
      deactivated: '',
      mfaregistered: false,
      roles: []
    };
  }

  getStateRegions() {
    this.authService.isRoleClassAuthorized(['stateCreateAdmin', 'regionalAdmin']).then(data => {
      let authRoleClasses: any = data;

      if (!(isEqual(authRoleClasses, {})) && this.adminState > -1) {
        let apiCall = this.stateService.getStateRegions();
        let subscribed = apiCall.subscribe({
          next: data => {
            console.log("getStateRegions(): ", data);
            this.stateRegions = data;
            subscribed.unsubscribe();
          },
          error: error => {
            console.log("Error retrieving state's regions from the userDetails component: ", error);
            this._Toast.showToast(error.data.developerMessage, 0);
            subscribed.unsubscribe();
          }
        });
      } else if (isEqual(authRoleClasses, {})) {
        // Object is empty so that means logged in user does not have appropriate roles

      };
    });
  }

  userDetailsFormError = (controlName: string, errorName: string) => {
    this.inputError = this.userDetailsForm.controls[controlName].hasError(errorName);
  }

  hasSelectedRole() {
    let isRoleSelected = false;

    this.rolesArrayWithSelection.forEach(rObj => {
      if (rObj.selected == true) {
        isRoleSelected = true;
      }
    });

    return isRoleSelected;
  }

  async getAdminFlags() {
    let data = await this.stateService.getStateRegionalAdmin();
    console.log('getAdminFlags() data: ', data);
    this.authFlags = data[0];
  }

  getDistricts() {
    this.authService.isRoleClassAuthorized(['stateCreateAdmin', 'regionalAdmin']).then(data => {
      let authRoleClasses: any = data;

      if (!(isEqual(authRoleClasses, {})) && this.adminState > -1) {
        let apiCall = this.stateService.getUserDistricts();
        let subscribed = apiCall.subscribe({
          next: data => {
            console.log("getUserDistricts(): ", data);
            this.districtsArray = data;
            subscribed.unsubscribe();
          },
          error: error => {
            console.log("Error retrieving districts from the userDetails component: ", error);
            this._Toast.showToast(error.data.developerMessage, 0);
            subscribed.unsubscribe();
          }
        });
      };
    });
  }

  // Verifies if searched user (the user for whom the user details page is being loaded) has specified role.
  hasRole(roleToCheck) {
    let doesRoleExist = false;

    for (let idx in this.rolesArrayWithSelection) {
      let roleObj: any = this.rolesArrayWithSelection[idx];
      if (roleObj.role === roleToCheck && roleObj.selected) {
        doesRoleExist = true;
        break;
      }
    }

    return doesRoleExist;
  }

  checkRoleClasses() {
    this.authService.isRoleClassAuthorized(['superUser', 'omeUser', 'govUser', 'stateAdmin', 'regionalAdmin', 'districtAdmin']).then(data => {
      let isAuthorizedForClasses: any = data;

      if (isAuthorizedForClasses) {
        this.authRoleClasses = isAuthorizedForClasses;
        this.getAdminFlags();

        this.adminState = this.reportInfoService.returnObj.userState;
        this.getStateRegions();
        this.getDistricts();
      } else {
        console.error('Error occurred in checkRoleClasses() for userDetails.components.');
      }
    });
  }

  checkRoleDisabled(roleObj, type = "update") {
    let flag = false;
    let role = roleObj.role;
    if (!this.authRoleClasses || !this.adminState) {
      return true;
    }
    if (role === 'Super User') {
      if (!this.authRoleClasses.superUser || this.authRoleClasses.omeUser) {
        flag = true;
      }
    } else if (role === 'OME User Administrator' || role === 'Government Administrator' || role === 'MSIX Privacy Act Administrator') {
      if (!this.authRoleClasses.govUser) {
        flag = true;
      }
    } else if (role === 'State Batch Submitter') {
      if (!this.authRoleClasses.stateAdmin || this.authRoleClasses.omeUser) {
        flag = true;
      }
    } else if (role === 'District Data Administrator') {
      if ((!this.authRoleClasses.stateAdmin && !this.authRoleClasses.regionalAdmin && !this.authRoleClasses.districtAdmin) ||
        this.adminState !== this.formData.userState || this.authRoleClasses.omeUser) {
        flag = true;
      }
    } else if (role === 'Regional Data Administrator') {
      if ((!this.authRoleClasses.stateAdmin && !(this.authRoleClasses.regionalAdmin)) ||
        this.adminState !== this.formData.userState || this.authRoleClasses.omeUser) {
        flag = true;
      }
    } else if (role === 'State Data Administrator') {
      if (!this.authRoleClasses.stateAdmin || this.authRoleClasses.omeUser) {
        flag = true;
      }
    } else if (role === 'Regional User Administrator') {
      if ((!this.authRoleClasses.stateAdmin && !this.authRoleClasses.regionalAdmin) ||
        this.adminState !== this.formData.userState || this.authRoleClasses.omeUser) {
        flag = true;
      }
    } else if (role === 'State User Administrator') {
      if (!this.authRoleClasses.stateAdmin) {
        flag = true;
      }
    } else if (role === 'State Regional Administrator') {
      if (!this.authRoleClasses.stateAdmin || this.authRoleClasses.omeUser) {
        flag = true;
      }
    } else if (role === 'MSIX Primary User' || role === 'Secondary User') {
      if (this.authRoleClasses.omeUser) {
        flag = true;
      }
    }

    if (type === 'new') {
      return flag;
    } else {
      return (flag || this.disabledFlag);
    }
  }

  updateUser(ev) {
    if (this.userDetailsForm.controls['disableUser'].value === 'Deactivated') {

      this.showModal = true;
      let dialogRef = this.dialog.open(ConfirmDeactivate, {
        data: {
          firstName: this.userDetailsForm.get('firstName').value,
          lastName: this.userDetailsForm.get('lastName').value,
          username: this.username
        }
      });

      // This function will be called when user confirms the account to be deactivated.
      dialogRef.componentInstance.callUpdate.subscribe((callUpdate) => {
        if (callUpdate) {
          this.processUpdate();
          dialogRef.close();
        } else {
          console.error(' This shoule never be called as there is a separate function when user hits Cancel.');
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        this.showModal = false;
        console.log('The confirm deactivate dialog was closed', result);
      });

    } else {
      this.processUpdate();
    }
  }

  private processUpdate() {
    this.formData.rolesStr = this.setRoles(this.rolesArrayWithSelection);
    this.formData.jobTitles = cloneDeep(this.jobsArray);
    if (this.formData.receiveNotification) {
      this.formData.receiveNotification = this.formData.receiveNotification.toUpperCase();
    }

    // Settings these values from form control back to the formData as (TODO) we still need to figure out how to handle Form Array properly for User Roles and Job Titles.
    this.formData.firstName = this.userDetailsForm.controls['firstName'].value;
    this.formData.lastName = this.userDetailsForm.controls['lastName'].value;
    this.formData.email = this.userDetailsForm.controls['email'].value;
    this.formData.userPhone = this.userDetailsForm.controls['userPhone'].value;
    this.formData.userPhoneExt = this.userDetailsForm.controls['userPhoneExt'].value;
    this.formData.activeDate = this.userDetailsForm.controls['activeDate'].value;
    this.formData.expirationDate = this.userDetailsForm.controls['expirationDate'].value;
    this.formData.addLine1 = this.userDetailsForm.controls['addLine1'].value;
    this.formData.addLine2 = this.userDetailsForm.controls['addLine2'].value;
    this.formData.addCity = this.userDetailsForm.controls['addCity'].value;
    this.formData.addState = this.userDetailsForm.controls['userState'].value;
    this.formData.userState = this.userDetailsForm.controls['userState'].value;
    this.formData.disableUser = this.userDetailsForm.controls['disableUser'].value;

    console.log('User Details - processUpdate() - ' + this.formData);

    let apiCall = this.userService.updateUser(this.username, this.formData);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log('data: ', data);
        this._Toast.showToast('User updated!');
        if (this.userDetailsForm.controls['disableUser'].value === 'Deactivated') {
          this.disabledFlag = true;
          this.deactivateUser();
        } else {
        }
        this.currUser = cloneDeep(this.formData);
        this.rolesArrayWithSelectionCopy = cloneDeep(this.rolesArrayWithSelection);
        this.roleFlag = false;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error updating the user from the userDetails component: ", error);
        this.setToOriginal();
        this._Toast.showToast(error.error.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  jsonStringify(obj) {
    return JSON.stringify(obj);
  }

  openResetPasswordDialog(ev) {
    this.showModal = true;

    let dialogRef = this.dialog.open(ResetPassword, {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      ariaModal: true,
      data: {
        firstName: this.userDetailsForm.get('firstName').value,
        lastName: this.userDetailsForm.get('lastName').value,
        email: this.userDetailsForm.get('email').value,
        username: this.username
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.showModal = false;
      console.log('The reset password dialog was closed', result);
    });
  }

  openResetMfaDialog(ev) {
    this.showModal = true;
    let dialogRef = this.dialog.open(ResetMFA, {
      panelClass: 'cdk-overlay-container',
      data: {
        firstName: this.userDetailsForm.get('firstName').value,
        lastName: this.userDetailsForm.get('lastName').value,
        email: this.userDetailsForm.get('email').value,
        username: this.username
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.showModal = false;
      console.log('The reset MFA dialog was closed', result);
    });
  }

  setToOriginal() {
    this.formData = cloneDeep(this.currUser);
  }

  cancel(ev) {
    this.showModal = true;
    let dialogRef = this.dialog.open(ConfirmCancel, {
      panelClass: 'cdk-overlay-container',
      height: 'auto',
      minHeight: 'fit-content',
      maxHeight: 'fit-content',
      maxWidth: 'fit-content'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.showModal = false;
      console.log('The cancel update dialog was closed', result);
    });
  }

  setRoles(roles) {
    var rolesEnumArray = [];
    for (var key in this.userRoles) {

      roles.forEach(roleObj => {
        if (this.userRoles[key] === roleObj.role && roleObj.selected === true) {
          rolesEnumArray.push(key);
        }
      })
    }
    return rolesEnumArray;
  }

  // TODO - Where is this called from?
  viewDetails(userId) {
    this._router.navigate(['userDetails', {
      username: userId
    }]);
  }

  // Gets the user details for the searched user.
  async getUserDetails() {
    let data: any = await this.userService.getUser(this.username);

    console.log('data: ', data);
    this.districtsArray = [];
    for (let i = 0; i < data.districtNames.length; i++) {
      this.districtsArray.push({
        'key': data.districts[i],
        'name': data.districtNames[i]
      });
    }

    // Set the user data in the local variable.
    this.currUser = data;

    this.userDetailsForm.patchValue(data);

    // Set if the disbaled flag to indicate if user account has been deactivated.
    if (this.currUser.deactivated) {
      this.disabledFlag = true;
    }

    // Set if user has configured MFA or not.
    if (this.currUser.mfaregistered) {
      this.isMFAConfigured = 'Yes';
    } else {
      this.isMFAConfigured = 'No';
    }

    // Set the formData using the user data returned from the API.
    this.formData = cloneDeep(this.currUser);

    // Get searched user's roles and sets rolesObj and rolesObjCopy defined locally.
    this.getRoles();

    // Get the list of job titles associated with the searched user and sets in the jobsArray and newUserJobsArray defined locally.
    this.getJobTitles(this.username);
  }

  userAuthDistricts() {
    this.authService.isRoleClassAuthorized(['stateCreateAdmin', 'regionalAdmin', 'superUser']).then(data => {
      let roles: any = data;
      this.userShowDistricts = !isEqual(roles, {});

      this.isSuperUser = (roles.superUser == undefined) ? false : roles.superUser;
    });
  }

  // Get user's roles
  getRoles() {

    // Converting from JSON object to an array, but this will create an array of array
    // For example: If value of this.formData.roles = { 2: 'State Data Administrator' }, then
    //   userRolesMap = [ [ '2', 'State Data Administrator' ] ]
    let userRolesMap = Object.entries(this.formData.roles);

    this.rolesArray.forEach((role) => {
      userRolesMap.forEach((userRole) => {

        let roleObj = this.rolesArrayWithSelection.find(r => r.role === role);
        let shouldBeSelected = (userRole[1] === role);

        if (roleObj && !roleObj.selected && shouldBeSelected) {
          // Role already exists and has selected set to false, but should be selected now
          roleObj.selected = true;
        } else if (!roleObj) {
          // Role has not been added
          this.rolesArrayWithSelection.push({
            role: role,
            selected: shouldBeSelected
          });
        }
      });
    });

    this.rolesArrayWithSelectionCopy = cloneDeep(this.rolesArrayWithSelection);
  }

  isAssignedRegion(region) {
    return this.formData.regions.indexOf(region.key) !== -1;
  }

  isAssignedDistrict(district) {
    return (this.formData.districts.indexOf(district.key) !== -1);
  }

  // This function is a common function use to add region/district to the selected list.
  addRegionOrDistrict(regionOrDistrict, regOrDistList) {
    let regOrDistIndx = regOrDistList.indexOf(regionOrDistrict.key);
    if (regOrDistIndx !== -1) {
      regOrDistList.splice(regOrDistIndx, 1);
    } else {
      regOrDistList.push(regionOrDistrict.key);
    }

    this.hasRegionOnDistrictTouched = true;
  }

  // This function checks that at least 1 district is selected when DDA role is selected and
  // at least 1 region is selected when RDA or RUA role is selected.
  areDistrictRegionSelectionValid(rolesObj = this.rolesArrayWithSelection) {
    let flag = true;

    // Verify if at least 1 region/district has been selected for appropriate role.
    let roleObjIndx = rolesObj.findIndex(roleObj => roleObj.role == 'District Data Administrator');
    if (roleObjIndx >= 0 && rolesObj[roleObjIndx].selected) {
      if (this.formData.districts.length === 0) {
        flag = false;
      }
    }
    if (flag) {
      roleObjIndx = rolesObj.findIndex(roleObj => roleObj.role == 'Regional Data Administrator');
      if (roleObjIndx >= 0 && rolesObj[roleObjIndx].selected) {
        if (this.formData.regions.length === 0) {
          flag = false;
        }
      } else {
        roleObjIndx = rolesObj.findIndex(roleObj => roleObj.role == 'Regional User Administrator');
        if (roleObjIndx >= 0 && rolesObj[roleObjIndx].selected) {
          if (this.formData.regions.length === 0) {
            flag = false;
          }
        }
      }
    }

    return flag;
  }

  deactivateUser() {
    let apiCall = this.userService.deactivateUser(this.username, this.formData);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log('data: ', data);
        this._Toast.showToast('User deactivated');

        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error deactivating user from the userDetails component: ", error);
        this._Toast.showToast(error.data.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  addRemoveJob(event, job) {
    if (job.jobTitleDesc === 'Other') {
      this.otherIsSelected = event.checked;

      if (!this.otherIsSelected) {
        //this.jobTitle.other = '';
        this.otherJobTitle = '';
        job.otherJobTitleDesc = '';
      }
    }
    job.selected = event.checked;
  }

  updateOtherJobTitle(event, job) {
    job.otherJobTitleDesc = event.target.value;
    job.selected = true;
  }

  // This function returns true when no job title has been selected.
  // It also verifies if 'Other' job title has been selected, then associated description for title has been filled.
  isJobTitleNotSelected() {
    return !this.isJobTitleSelected();
  }

  // This function checks if the job title has been selected. If selected, then it verifies if the list has been modified. Returns 'true' if not modified.
  isJobTitleSelectionValid() {
    if (this.isJobTitleSelected()) {
      // Job title has been selected

      // Verify if it has been modified
      return this.isJobTitleListNotModified();
    } else {
      // Job title is not selected
      return false;
    }
  }

  // This function checks if job title list has not been modified. 
  private isJobTitleListNotModified() {
    // Check if the job array has not been modified
    if (isEqual(this.jobsArray, this.jobsArrayCopy)) {
      return true;
    } else {
      return false;
    }
  }

  // Helper function to verify if job title has been selected. In case of 'Other' job title, it also verifies if 'Other' job title text is not empty.
  private isJobTitleSelected() {
    let isJobSelected = false;
    let isOtherSelectionValid = true;
    let jobsArraySize = this.jobsArray.length;
    let idx = 0;

    while (idx < jobsArraySize) {
      if (this.jobsArray[idx].selected == true) {
        if (this.jobsArray[idx].jobTitleDesc == 'Other') {
          if (this.jobsArray[idx].otherJobTitleDesc.trim() !== '') {
            isJobSelected = true;
          } else {
            isOtherSelectionValid = false;
          }
        } else {
          isJobSelected = true;
        }
      }

      idx++;
    }

    return (isJobSelected && isOtherSelectionValid);
  }

  /* get jobTitlesFormArray() {
    return this.userDetailsForm.get('jobTitles') as FormArray;
  } */

  // Gets the list of the job titles to display and select. If username has been passed in, then this will retrieve
  // data for that user, otherwise, it will pull for the current user.
  getJobTitles(username) {
    this.jobsArray = new Array();
    let apiCall = this.userService.getJobTitles(username);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log('data: ', data);

        this.jobsArray = data;
        this.jobsArrayCopy = cloneDeep(this.jobsArray);
        for (let job of data) {
          if (job.jobTitleDesc === 'Other') {
            //this.jobTitle.other = job.otherJobTitleDesc;
            this.otherJobTitle = job.otherJobTitleDesc;
          }
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error retrieving user's job titles from the userDetails component: ", error);
        this._Toast.showToast(error.error.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  getCurrentUser() {
    // Get current user data and set the user's state on the form.
    this.userService.getCurrentUser().then(data => {
      let userData: any = data;
      this.formData.userState = userData.userState;
    });

    this.getJobTitles('');
  }

  async getStateList(): Promise<void> {
    await this.stateService.getStates().then(
      data => {
        console.log('data: ', data);
        this.states = data;
      },
      error => {
        console.log("Error retrieving user's states from the userDetails component: ", error);
        this._Toast.showToast(error.data.developerMessage, 0);
      }
    );
  }

  // This function is called when user updates the role selection on the user details page.
  addRemoveRole(event: any, rolesObj, type = 'update') {
    let role = rolesObj.role;

    if (type === 'new') {
      // TODO - Does this even get called now that new user component is separate in here?
      //rolesObj = this.newUserRolesObj;

      const slctdUserRoles = <FormArray>this.userDetailsForm.get('rolesStr') as FormArray;

      if (event.checked) {
        slctdUserRoles.push(new FormControl(role));
      } else {
        const i = slctdUserRoles.controls.findIndex(x => x.value === role);
        if (i >= 0) {
          slctdUserRoles.removeAt(i);
        }
      }

    }

    if (((role === 'Regional Data Administrator') || (role === 'Regional User Administrator')) &&
      ((role === 'Regional Data Administrator' && rolesObj.selected === false) &&
        (role === 'Regional User Administrator' && rolesObj.selected === false))) {
      this.formData.regions = [];
    } else if ((role === 'District Data Administrator') && (role.selected === false)) {
      this.formData.districts = [];
    }

    this.rolesArrayWithSelection.forEach(rObj => {
      if (rObj.role == role) {
        rObj.selected = event.checked;
      }
    });
    this.checkRoleFlag();
  }

  private checkRoleFlag() {
    if (JSON.stringify(this.rolesArrayWithSelection) !== JSON.stringify(this.rolesArrayWithSelectionCopy)) {
      this.roleFlag = true;
    } else {
      this.roleFlag = false;
    }
  }
}

interface UserData {
  firstName: string,
  lastName: string,
  email: string,
  userPhone: string,
  userPhoneExt: string,
  activeDate: string,
  expirationDate: string,
  robDate: string,
  firstLogin: string,
  lastLogin: string,
  addLine1: string,
  addLine2: string,
  addCity: string,
  addState: string,
  isMFA: boolean,
  username: string,
  showRegistrationDecision: boolean,
  isRegistrationWorkflow: boolean,
  mfaCode: string,
  password: string,
  needQRCode: boolean,
  verifyQRCode: boolean,
  disableUser: string,
  districts: string[],
  regions: string[],
  rolesStr: string[],
  receiveNotification: string,
  jobTitles: string[],
  userState: string,
  deactivated: string,
  mfaregistered: boolean,
  roles: string[]
}
