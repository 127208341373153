import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants }  from '../config/index.constants';

@Injectable({
  providedIn: 'root'
})
export class HelpPagesService {

  private apiUrl = AppConstants.api;
  private renderer: Renderer2;

  constructor(private http: HttpClient, private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // This function makes the API call to get the secured documents
  getPPTXFile(link) {
    console.log(link);
    let apiCall = this.http.post<any>(this.apiUrl + 'helppages/getPPTX/' + link, '', { responseType: 'blob' as 'json' });
    let subscribed = apiCall.subscribe(
      data => {

        var file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'});

        var downloadLink = this.renderer.createElement('a');

        downloadLink.setAttribute('target', '_blank');
        downloadLink.setAttribute('href', window.URL.createObjectURL(file));
        downloadLink.setAttribute('download', link + '.pptx');
        downloadLink.click();
        downloadLink.remove();
      }
    );
  }
}