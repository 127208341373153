import { Component, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { AppConstants } from 'src/app/config/index.constants';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'studentAchievement',
  templateUrl: './studentAchievement.component.html',
  styleUrls: ['./studentAchievement.component.scss']
})
export class StudentAchievementComponent {
  gradHseId: string = "gradHse";
  gradHseChart: string = "gradHseChart";
  grade11AlgId: string = "grade11Alg";
  grade11AlgChart: string = "grade11AlgChart";
  chart: string = "gradHse";
  graduationHseExportCall: string = "graduationHseExport";
  grade11AlgebraExportCall: string = "grade11AlgebraExport";
  private appConst = AppConstants;
  private userStateName: string;
  private studentAchievementFilterParams: any = {};
  private activestudentAchievementFilter: any = {};
  private gradHseData: any;
  private grade11AlgData: any;
  private drillDown: any;
  drillDownInfo: any = {};
  showDrill: boolean;
  private ua: string;
  private isMobile: boolean;
  private isTablet: boolean;
  private isDesktop: boolean;
  private activeTab: string;
  private periodText: string;
  private reportGroupVar: string;
  private reportDrillDownVar: string;
  private blankRes: boolean;
  private tabChangeDone: boolean;
  private drillDownData: any;
  private roleClasses: any[];
  private authRoleClasses: any;
  private dateObj: any;
  private displayData: any;
  private studentAchievementExportObject: any;
  private gradHseDataHasLoaded: boolean;
  private hideGraduationHse: boolean;
  private grade11AlgDataHasLoaded: boolean;
  private hideCourseHistSubmission: boolean;
  private dataHasLoaded: boolean;
  private lastUpdated: any;
  sortAscending: boolean = false;
  currentSortColumn: string = 'column1';
  showFilter: boolean;
  constructor(
    public dialog: MatDialog,
    private cookieService: CookieService,
    private reportsService: ReportsService,
    private reportInfoService: ReportInfoService,
    private authService: AuthService,
    private toastService: ToastService) {
      this.showDrill = false;
      this.ua = navigator.userAgent;
      this.isMobile = false;
      this.isTablet = false;
      this.isDesktop = false;
      this.reportGroupVar = 'studentInfo';
      this.reportDrillDownVar = 'studentAchievementDrilldown';
      this.activeTab = 'totalLogins';
      this.periodText = '';
      this.blankRes = true;
      this.tabChangeDone = false;
      this.gradHseDataHasLoaded = false;
      this.hideGraduationHse = false;
      this.grade11AlgDataHasLoaded = false;
      this.hideCourseHistSubmission = false;
      this.gradHseData = {};
      this.grade11AlgData = {};
      this.dataHasLoaded = true;
      this.lastUpdated = new Date();
      this.showFilter = true;
      this.dateObj = {
        current: '',
        recent: '',
        currentStartYear: '',
        currentEndYear: '',
        recentStartYear: '',
        recentEndYear: ''
      };
      this.displayData = {
        current: "",
        recent: "",
        state: "",
        recordName: ""
      };
      this.authRoleClasses = {};
      this.drillDown = {
        beginRow: 1,
        endRow: 20,
        sortField: "MONTH_DESC",
        stateName: this.studentAchievementFilterParams.selectedState,
        performancePeriod: this.studentAchievementFilterParams.periodType,
        chartType: 'accountManagement',
        tab: this.activeTab
      };
      this.roleClasses = [
        'dataDashboard_studentAchievement_with_sua',
        'dataDashboards',
        'dataAdmins'
      ];
  }

  ngOnInit() {
    if (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(this.ua)) {
      this.isMobile = false;
      this.isTablet = true;
      this.isDesktop = false;
    }
    else if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(this.ua) || window.innerWidth < this.appConst.mobilePixels) {
      this.isMobile = true;
      this.isTablet = false;
      this.isDesktop = false;
    }
    else {
      this.isMobile = false;
      this.isTablet = false;
      this.isDesktop = true;
    }

    this.getReportInfo();
  }

  goToReport() {
    this.showDrill = false;
  }

  //Gets info needed to display the filter: list of states, user's state, both performance periods, and the authorized role classes
  async getReportInfo() {
    let reportInfo = await this.reportInfoService.getReportInfo(this.roleClasses);

    console.log("StudentAchievement.getReportInfo(): ", reportInfo);
    this.userStateName = reportInfo.userStateName;
    this.dateObj.current = reportInfo.currentPeriod;
    this.dateObj.recent = reportInfo.recentPeriod;
    this.authRoleClasses = reportInfo.authRoleClasses;
    if (reportInfo.authRoleClasses.dataAdmins) {
      this.studentAchievementFilterParams = {
        rptGrping: 'State',
        selectedState: this.userStateName,
        periodType: 'CURRENT',
        authRoleClasses: this.authRoleClasses,
        dateObj: this.dateObj,
        states: []
      };
    } else {
      this.studentAchievementFilterParams = {
        rptGrping: 'National',
        selectedState: "",
        periodType: 'CURRENT',
        authRoleClasses: this.authRoleClasses,
        dateObj: this.dateObj,
        states: []
      };
    }
    this.setStatesArray(reportInfo.stateData);

    //Generate the report automatically if in mobile mode
    //Otherwise open the filter dialog
    if (this.isMobile) {
      this.submitFilter();
    } else {
      this.filterActivate();
    }
  }

  // Trigger Filter Modal
  filterActivate() {    
    this.showFilter = true;
    console.log('filter should be showing');
    const dialogRef = this.dialog.open(FilterStudentAchievement, {
      height: 'auto',
      width: '60%',
      minHeight: '325px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,  
      data: {params: this.studentAchievementFilterParams},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.activestudentAchievementFilter = result;
        this.studentAchievementFilterParams = result;
        this.submitFilter();
        this.showFilter = false;
      }
    });
  }

  submitFilter() {
    this.gradHseDataHasLoaded = false;
    this.hideGraduationHse = false;
    let apiCall = this.reportsService.getStudentAchievementDataFilter(this.studentAchievementFilterParams.selectedState, this.studentAchievementFilterParams.periodType);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.studentAchievementFilterParams = JSON.parse(JSON.stringify(this.activestudentAchievementFilter));


        console.log("StudentAchievement.submitFilter(): ", data);
        this.gradHseData = data.gradHseData;
        this.grade11AlgData = data.enrollAlgData;
        this.dataHasLoaded = true;
        this.gradHseDataHasLoaded = true;
        this.grade11AlgDataHasLoaded = true;

        // Set performance period text for 'Migratory Graduation/HSE' details
        this.dateObj.gradHSEPPText = data.gradHseData.xValues.join(" / ");

        this.setPeriodText();
        this.setLastUpdated();

        if (this.gradHseData.data.length == 0) {
          this.hideGraduationHse = true;
        }

        if (this.studentAchievementFilterParams.selectedState != '' && this.studentAchievementFilterParams.periodType != '') {
          this.studentAchievementExportObject = {
            stateName: this.studentAchievementFilterParams.selectedState,
            performancePeriod: this.studentAchievementFilterParams.periodType
          };
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - StudentAchievement.submitFilter(): ", error);
        this.dataHasLoaded = true;
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  setLastUpdated() {
    this.reportsService.getFirstDayOfMonth().subscribe({
      next: data => {
        this.lastUpdated = data.date;
      },
      error: error => {
        console.log("Error - StudentAchievement.setLastUpdated(): ", error);
      }
    });
  }

  // removes DC, PR, and WY from states array and sets the fullStateArray with list of states
  setStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i
          ].key
        };
        this.studentAchievementFilterParams.states.push(responseData[i]);
      }
    }
  }

  //Open pop up window with more information on the dashboard
  goToLearnMore() {
    this.showFilter = true;
    const dialogRef = this.dialog.open(LearnMoreStudentAchievement, {
      height: 'auto',
      width: '50%',
      minHeight: 'fit-content',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: {params: this.authRoleClasses},
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.showFilter = false;
    });
  }

  goToDDLearnMore() {
    this.showFilter = true;
    const dialogRef = this.dialog.open(LearnMoreDDStudentAchievement, {
      height: 'auto',
      width: '50%',
      minHeight: 'fit-content',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: {params: this.chart},
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.showFilter = false;
    });
  }

  showDrilldown(chart) {
    this.showDrill = true;
    this.chart = chart;
    this.sortAscending = false;
    this.currentSortColumn = 'column1';
    this.drillDownInit();
  }

  //Pull in Drilldown data
  drillDownInit() {
    this.drillDown = {
      beginRow: 1,
      endRow: 20,
      stateName: this.studentAchievementFilterParams.selectedState,
      performancePeriod: this.studentAchievementFilterParams.periodType,
      chartType: this.chart
    };
    if (this.chart === 'gradHse') {
      this.drillDown.sortField = 'PERFORMANCE_PERIOD_DESC';
    } else {
      this.drillDown.sortField = 'MSIX_ID_DESC';
    }
    this.updateDrilldown(this.drillDown);
  }

  updateDrilldown(drillDown) {
    this.drillDownData = {};
    this.reportGroupVar = 'dashboard';
    this.reportDrillDownVar = 'studentAchievementDrilldown';
    let apiCall = this.reportsService.drillDownView(drillDown, this.reportGroupVar, this.reportDrillDownVar);
    let subscribed = apiCall.subscribe({
      next: data => {
        if (data.rows.length === 0) {
          this.blankRes = true;
        } else {
          console.log("StudentAchievement updateDrilldown(): ", data);
          this.blankRes = false;
          this.drillDownData = data;
          this.drillDownData.query = drillDown;
          this.drillDownInfo = {
            drillDown: drillDown, 
            reportGroupVar: this.reportGroupVar,
            reportDrillDownVar: this.reportDrillDownVar
          }
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - StudentAchievement.updateDrilldown(): ", error);
        subscribed.unsubscribe();
      }
    });
  }

  //Sort Drill Down Data
  handleDrillDownSortClick(newSortKey) {
    this.drillDown.sortField = newSortKey.sortKey;
    this.updateDrilldown(this.drillDown);
  }

  //Updates which column and direction the table will be sorted by
  sortByColumn(data: {column: string, ascending: boolean}) {
    this.currentSortColumn = data.column;
    this.sortAscending = data.ascending;
  }

  setPeriodText() {
    switch (this.studentAchievementFilterParams.periodType) {
      case ("CURRENT"):
        this.periodText = "Current performance period (" + this.dateObj.current + ")";
        break;
      case ("PREVIOUS"):
        this.periodText = "Last performance period (" + this.dateObj.recent + ")";
        break;
      default:
        this.periodText = "Current performance period (" + this.dateObj.current + ")";
        break;
    }
  }

  //stringifies an object
  jsonStringify(obj) {
    return JSON.stringify(obj);
  }
}

// Separate component for the StudentAchievement report's filter
export interface FilterParameters {
  params: {
    rptGrping: string,
    selectedState: string,
    periodType: string,
    authRoleClasses: any,
    dateObj: any,
    states: any[]
  }
}
@Component({
  selector: 'filterStudentAchievement',
  templateUrl: './filterStudentAchievement.html',
  styleUrls: ['./studentAchievement.component.scss']
})
export class FilterStudentAchievement {
  studentAchievementFilterParams: {
      rptGrping: string,
      selectedState: string,
      periodType: string,
      authRoleClasses: any,
      dateObj: any,
      states: any[]
  };
  constructor(
    public dialogRef: MatDialogRef<FilterStudentAchievement>,
    public responsiveUI: ResponsiveUIService,
    @Inject(MAT_DIALOG_DATA) public data: FilterParameters) {
      this.studentAchievementFilterParams = cloneDeep(this.data.params);
      if (this.studentAchievementFilterParams.rptGrping == '' || !this.studentAchievementFilterParams.rptGrping){
        this.resetFilter();
      }
  }

  resetFilter() {
    if (this.studentAchievementFilterParams.authRoleClasses.dataAdmins) {
      this.studentAchievementFilterParams = {
        rptGrping: 'State',
        selectedState: this.data.params.selectedState,
        periodType: 'CURRENT',
        authRoleClasses: this.data.params.authRoleClasses,
        dateObj: this.data.params.dateObj,
        states: this.data.params.states
      };
    } else {
      this.studentAchievementFilterParams = {
        rptGrping: 'National',
        selectedState: "",
        periodType: 'CURRENT',
        authRoleClasses: this.data.params.authRoleClasses,
        dateObj: this.data.params.dateObj,
        states: this.data.params.states
      };
    }
  }

  onSubmit(){
    this.dialogRef.close(this.studentAchievementFilterParams);
  }

  selectState() {
    this.studentAchievementFilterParams.rptGrping = 'State';
  }

  selectNational() {
    this.studentAchievementFilterParams.rptGrping = 'National';
    this.studentAchievementFilterParams.selectedState = '';
  }
  selectDrilldownState(state) {
    this.studentAchievementFilterParams.selectedState = state;
  }

  selectPeriodType(periodType){
    this.studentAchievementFilterParams.periodType = periodType;
  }

  onClose() {
    this.studentAchievementFilterParams = cloneDeep(this.data.params);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.studentAchievementFilterParams = cloneDeep(this.data.params);
    this.dialogRef.close();
  }
}

// Separate component for the StudentAchievement report's Learn More dialog box
export interface LearnMoreParameters {
  params: {
    dataDashboards: boolean, 
    dataAdmins: boolean
  }
}
@Component({
  selector: 'learnMoreStudentAchievement',
  templateUrl: 'learnMoreStudentAchievement.html',
  styleUrls: ['./studentAchievement.component.scss']
})
export class LearnMoreStudentAchievement {
  authRoleClasses: any = {};
  constructor(
    public dialogRef: MatDialogRef<LearnMoreStudentAchievement>,
    @Inject(MAT_DIALOG_DATA) public data: LearnMoreParameters
  ) {
    this.authRoleClasses = data.params;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the StudentAchievement report's drilldown Learn More dialog box
@Component({
  selector: 'learnMoreDDStudentAchievement',
  templateUrl: 'learnMoreDDStudentAchievement.html',
  styleUrls: ['./studentAchievement.component.scss']
})
export class LearnMoreDDStudentAchievement {
  constructor(
    public dialogRef: MatDialogRef<LearnMoreDDStudentAchievement>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}

