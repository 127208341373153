<!--START Validate Record Merge Desktop-->

<div *ngIf="responsiveUI.isDesktop && validateMergeItems.length > 0" class="worklist-page layout-margin-worklist-module layout-padding-worklist-module validate-records">
  <div class="layout-padding-worklist-submodule background-white">
    <div class="desktopEnrollments removeBorders">
      <div class="layout-flex-row tableRow headerRow">
        <h3>Validate Record Merge</h3>
      </div>
    </div>
    <div class="desktopEnrollments removeBorders">
      <p class="worklist-description-text">
        Expand the row to view details of each merge request and validate or reject merging of the records. Validating
        the merge will consolidate records under one MSIX ID. Rejecting the merge will release the records as separate
        student records.
      </p>
    </div>

    <div class="worklist-header-titles layout-flex-row flex">
      <div class="flex-95">
        <div class="flex-container" style="flex: 100%">
          <div class="block2 flex-20">
            <a (click)="sortColumns('column1')">
              Student
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-10">
            <a (click)="sortColumns('column2')">
              Worklist ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-10">
            <a (click)="sortColumns('column3')">
              State
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-10">
            <a (click)="sortColumns('column4')">
              Sex
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column5')">
              DOB
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column6')">
              MSIX ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column7')">
              State ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column8')">
              Created
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column8'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column8'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="layout-flex-row table2Row" *ngFor="let student of validateMergeItems" style="flex: 100%">
      <div class="flex-95 background-white">
        <div class="layout-flex-row">
          <div class="layout-row section1 flex" (click)="showValidateMergeDetailsFn(student.key); updateReadFlag(student)">
            <span class="flex-20 column" [ngClass]="{highlight: currentSortColumn === 'column1'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.demographics.lastName2 !=''"> {{student.demographics.lastName2}}</span>,
                {{student.demographics.firstName}}
                <span *ngIf="student.demographics.midName != ''"> {{student.demographics.midName}}</span>
              </p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column2'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.key}}</p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column3'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.demographics.submittingStateAbbv}}</p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column4'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.demographics.gender}}</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column5'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.demographics.birthDate}}</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column6'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.demographics.msixId}}</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column7'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.demographics.studentStateID}}</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column8'}">
              <p class="flex layout-flex-column" [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                <span class="days-in-list">{{convertDateCreated(student.dateCreated)}}</span>
                <span class="days-in-list">{{daysOnList(student.dateCreated)}} days in list</span>
              </p>
            </span>

          </div>
          <!-- <div style="flex: 10" class="flex-container">
            <p>
              <a flex *ngIf="!showResolve" (click)="viewDetails(student)" class="top-bottom-margin-8 flexCenter">
                <span class="no-underline">Full Record</span>
              </a>
            </p>
          </div> -->
        </div>

        <!--child record(s)-->

        <div class="validate-records-open background-white" *ngIf="validateMergeIndex === student.key">
          <div class="parentRecord" style="border: 1px solid #D3D3D3; background-color: #FAFAFA;">
            <div class="layout-flex-row flex-container section1">
              <div style="flex: 100%" (click)="showValidateMergeDetailsFn(student.key); updateReadFlag(student)" class="layout-flex-row flex-container">
                <span class="flex-20 column">
                  <a (click)="viewDetails(student)">
                    {{student.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.demographics.lastName2 !=''"> {{student.demographics.lastName2}}</span>,
                    {{student.demographics.firstName}}
                    <span *ngIf="student.demographics.midName != ''"> {{student.demographics.midName}}</span>
                  </a>
                </span>
                <span class="flex-10 column">
                  <p flex>{{student.key}}</p>
                </span>
                <span class="flex-10 column">
                  <p flex>{{student.demographics.submittingStateAbbv}}</p>
                </span>
                <span class="flex-10 column">
                  <p flex>{{student.demographics.gender}}</p>
                </span>
                <span class="flex column">
                  <p flex>{{student.demographics.birthDate}}</p>
                </span>
                <span class="flex column">
                  <p flex>{{student.demographics.msixId}}</p>
                </span>
                <span class="flex column">
                  <p flex>{{student.demographics.studentStateID}}</p>
                </span>
                <span class="flex column">
                  <p class="flex layout-flex-column">
                    <span class="days-in-list"></span> <!-- {{convertDateCreated(student.dateCreated)}} -->
                    <span class="days-in-list"></span> <!-- {{daysOnList(student.dateCreated)}} days in list -->
                  </p>
                </span>
              </div>
            </div>
            <div class="flex section2 worklist-accordion-section2">
              <div class="col1 flex-25 layout-column">
                <div class="top-bottom-margin-8">
                  <p><b>Parent 1:</b></p>
                  <p>{{student.demographics.maleParentFirstName}} {{student.demographics.maleParentLastName}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 1 Email Address:</b></p>
                  <p>{{student.demographics.parent1EmailAddress}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 1 Phone Number:</b></p>
                  <p>{{student.demographics.parent1PhoneNumber}}
                  </p>
                </div>
              </div>
              <div class="col1 flex-25 layout-column">
                <div class="top-bottom-margin-8">
                  <p><b>Parent 2:</b></p>
                  <p>
                    {{student.demographics.femaleParentFirstName}} {{student.demographics.femaleParentLastName}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 2 Email Address:</b></p>
                  <p>{{student.demographics.parent2EmailAddress}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 2 Phone Number:</b></p>
                  <p>{{student.demographics.parent2PhoneNumber}}
                  </p>
                </div>
              </div>
              <div class="col1 top-bottom-margin-8 flex-25 layout-column">
                <div class="top-bottom-margin-8">
                  <p><b>State ID:</b> {{student.demographics.studentStateID}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Multi-birth:</b></p>
                  <p>{{student.demographics.multipleBirthFlag}}</p>
                </div>
              </div>
              <!-- Commenting out until functionality implemented on backend -->
              <div class="flex-25 col1">
                <div class="top-bottom-margin-8 layout-column">
                  <p class="master">Master Record</p>
                  <a (click)="viewDetails(student)">
                    <span class="no-underline">Full Record</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div *ngFor="let studentChild of student.children" class="studentRecord" style="border: 1px solid #D3D3D3; background-color: #FAFAFA;">

            <div class="layout-flex-row flex-container section1">
              <div style="flex: 100%" (click)="showValidateMergeDetailsFn(student.key); updateReadFlag(student)" class="layout-flex-row flex-container">
                <span class="flex-20 column">
                  <a (click)="viewDetails(studentChild)">
                    {{studentChild.demographics.lastName1}}<span class="removeUnderline" *ngIf="studentChild.demographics.lastName2 !=''"> {{student.demographics.lastName2}}</span>,
                    {{studentChild.demographics.firstName}}
                    <span *ngIf="studentChild.demographics.midName != ''"> {{studentChild.demographics.midName}}</span>
                  </a>
                </span>
                <span class="flex-10 column">
                  <p flex>{{student.key}}</p>
                </span>
                <span class="flex-10 column">
                  <p flex>{{studentChild.demographics.submittingStateAbbv}}</p>
                </span>
                <span class="flex-10 column">
                  <p flex>{{studentChild.demographics.gender}}</p>
                </span>
                <span class="flex column">
                  <p flex>{{studentChild.demographics.birthDate}}</p>
                </span>
                <span class="flex column">
                  <p flex>{{studentChild.demographics.msixId}}</p>
                </span>
                <span class="flex column">
                  <p flex>{{studentChild.demographics.studentStateID}}</p>
                </span>
                <span class="flex column">
                  <p class="flex layout-flex-column">
                    <span class="days-in-list"></span>
                    <span class="days-in-list"></span>
                  </p>
                </span>
              </div>
            </div>

            <div class="flex section2 worklist-validate-accordion">
              <div class="col1 flex-25 layout-column">
                <div class="top-bottom-margin-8">
                  <p><b>Parent 1:</b></p>
                  <p>{{studentChild.demographics.maleParentFirstName}}
                    {{studentChild.demographics.maleParentLastName}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 1 Email Address:</b></p>
                  <p>{{studentChild.demographics.parent1EmailAddress}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 1 Phone Number:</b></p>
                  <p>{{studentChild.demographics.parent1PhoneNumber}}
                  </p>
                </div>
              </div>
              <div class="col1 flex-25 layout-column">
                <div class="top-bottom-margin-8">
                  <p><b>Parent 2:</b></p>
                  <p>
                    {{studentChild.demographics.femaleParentFirstName}}
                    {{studentChild.demographics.femaleParentLastName}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 2 Email Address:</b></p>
                  <p>{{studentChild.demographics.parent2EmailAddress}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 2 Phone Number:</b></p>
                  <p>{{studentChild.demographics.parent2PhoneNumber}}
                  </p>
                </div>
              </div>
              <div class="col1 top-bottom-margin-8 flex-25 layout-column">
                <div class="top-bottom-margin-8">
                  <p><b>State ID:</b> {{studentChild.demographics.studentStateID}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Multi-birth:</b></p>
                  <p>{{studentChild.demographics.multipleBirthFlag}}</p>
                </div>
              </div>
              <div class="flex-25 col1">
                <div class="top-bottom-margin-8">
                  <a (click)="viewDetails(studentChild)">
                    <span class="no-underline">Full Record</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--merge history-->

        <div class="layout-flex-row top-bottom-margin-15 worklist-box-border background-white" *ngIf="validateMergeIndex === student.key">

          <div class="flex-95">
            <div class="worklist-validate-accordion">
              <a>
                <span>Merge History</span>
              </a>
            </div>

            <div class="worklist-history-accordion" *ngIf="mergeHistIndex === student.demographics.msixId">
              <div class="worklist-header-titles" style="flex: 100%" class="layout-flex-row flex-container">
                <div class="block2 flex">Workflow</div>
                <div class="block2 flex">State</div>
                <div class="block2 flex">User</div>
                <div class="block2 flex">Status</div>
                <div class="block2 flex">Date</div>
              </div>
              <div *ngFor="let wLitem of worklistHistory" style="flex: 100%" class="layout-flex-row flex-container row">
                <p class="flex">{{wLitem.eventType}}</p>
                <p class="flex">{{wLitem.userState}}</p>
                <p class="flex">{{wLitem.actingUser}}</p>
                <p class="flex">{{wLitem.status}}</p>
                <p class="flex">{{wLitem.eventDate}}</p>
              </div>
            </div>

          </div>

          <div style="flex: 5%" (click)="showMergeHistFn(student.demographics.msixId)" class="flex-5 expandColumn-grey3 flex-container">
            <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(mergeHistIndex === student.demographics.msixId)"></i>
            <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="mergeHistIndex === student.demographics.msixId"></i>
          </div>

        </div>

        <!--comments-->

        <div class="layout-flex-row top-margin-10  validateMergeComments" *ngIf="validateMergeIndex === student.key">
          <div class="layout-flex-column" style="flex: 100%">
            <div>
              <div class="custom-input-container">
                <mat-form-field class="md-block md-input-container-margins background-white comment-box" 
                style="border: 2px solid gray; width: 100%; padding-right:1px; padding-bottom:1px">
                  <mat-label for="inputField" class="custom-label">Comments</mat-label>
                  <textarea matInput #inputField="matInput" name="inputField" class="custom-input comment-text" [(ngModel)]="validateOrRejectMergeObject.comments" maxlength="250"
                    md-maxlength="250" rows="2" md-select-on-focus>
                  </textarea>
                  <div class="md-errors-spacer">
                    <div class="md-char-counter">{{validateOrRejectMergeObject.comments.length}} / 250</div>
                  </div>
                </mat-form-field>
              </div>
              <p class="small worklist-commentbox-instructions flex">
                To protect the student's privacy, do not include any sensitive information such as Social Security
                Numbers or other Personally
                Identifiable Information in Comments fields. Comments entered will be shared unedited and in their
                entirety
                with other MSIX Users.
              </p>
            </div>
          </div>
        </div>
        <div class="layout-flex-row" *ngIf="validateMergeIndex === student.key">
          <div class="layout-flex-column right-align">
            <a *ngIf="!showResolve" (click)="goToReassign(student)" class="top-bottom-margin-8 flexCenter" style="margin: auto;">
              <span>Reassign</span>
            </a>
          </div>
          <div class="layout-flex-column button-margin right-align">
            <button flex (click)="validateOrRejectMergeClick('reject')" class="worklist-reject-button md-button">
              Reject Merge
            </button>
          </div>
          <div class="layout-flex-column button-margin right-align">
            <button class="md-button confirmButton" style="width:100%" (click)="validateOrRejectMergeClick('validate')" 
              [disabled]="!student.canValidate">
              Validate Merge
            </button>
          </div>
        </div>

      </div>

      <div (click)="showValidateMergeDetailsFn(student.key); updateReadFlag(student)"
        class="flex-5 expandColumn-green flex-container">
        <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(validateMergeIndex === student.key)"></i>
        <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="validateMergeIndex === student.key"></i>
      </div>

    </div>

  </div>
</div>

<!--END Validate Record Merge Desktop-->


<!--MOBILE AND TABLET START-->

<div class="worklist-page worklist-module top-bottom-margin-5" *ngIf="!responsiveUI.isDesktop && validateMergeItems.length > 0">
  <div layout-padding class="dashboard-module-body">
    <!--mobile & tablet validate merge-->
    <div class="top-bottom-margin-8">
      <div class="layout-padding-l layout-padding-r">
        <div class="desktopEnrollments removeBorders">
          <div class="tableHeader">
            <div class="layout-flex-row tableRow headerRow no-padding">
              <p class="worklist-section-header">Validate Merge Records</p>
            </div>
          </div>
        </div>
        <div class="layout-padding-bottom-15">
          <p>To validate a merge, access your worklist on desktop.</p>
        </div>
        <div hide-gt-md>
          <div class="results-border top-bottom-padding-8" *ngFor="let student of validateMergeItems">
            <div class="layout-flex-row" (click)="viewDetails(student)">
              <div class="flex layout-flex-column">
                <a class="uppercase">
                  {{student.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.demographics.lastName2 !=''"> {{student.demographics.lastName2}}</span>,
                  {{student.demographics.firstName}}
                  <span *ngIf="student.demographics.midName != ''"> {{student.demographics.midName}}</span>
                </a>
                <div flex>
                  <p class="small small-grey2andahalf uppercase">ID: {{student.key}} | DOB:
                    {{student.demographics.birthDate}} |&nbsp;{{student.demographics.gender}} |
                    {{student.demographics.submittingStateAbbv}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<!--MOBILE AND TABLET END-->
