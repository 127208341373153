import { Component, Input, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { AppConstants } from 'src/app/config/index.constants';
import { CookieService } from 'ngx-cookie';
import { AuthService } from 'src/app/services/auth.service';
import { ReportsService } from 'src/app/services/reports.service';
import { UserService } from 'src/app/services/user.service';
import { WorklistService } from 'src/app/services/worklist.service';
import { Observable } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { HTMLExportService } from 'src/app/services/html-export.service';
import { browserRefresh } from 'src/app/app.component';


@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class AppHeaderComponent {
  private apiUrl = AppConstants.api;
  private helpUrl = AppConstants.help;
  private appConstantsRoles = AppConstants.userRoles;
  gtmd: boolean;
  currentPath: string;
  medium: boolean;
  loggedIn: boolean;
  privacyComplete: boolean;
  needsJobTitle: boolean;
  authRoleClasses: {
    studentSearch: boolean,
    worklist: boolean,
    sendInfo: boolean,
    enableRegion: boolean,
    editRegion: boolean,
    stateAdmin: boolean,
    regionalAdmin: boolean,
    districtAdmin: boolean,
    passwords: boolean,
    reports: boolean
  };
  showSideNav: boolean;
  router: string;
  betaUser: any;
  betaActive: any;
  userLoaded: boolean;
  isLoggedInWithOTP: boolean;
  userRoles: [];
  isUserAdmin: boolean;
  isDataAdmin: boolean;
  fromState: string;
  menuOpen: boolean;

  @Input() user!: any;

  @ViewChild('clickHoverMenuTrigger') clickMenuTrigger: MatMenuTrigger;
  worklistCount: object;

  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private reportsService: ReportsService,
    private userService: UserService,
    private worklistService: WorklistService,
    private _router: Router,
    private _Toast: ToastService,
    public responsiveUI: ResponsiveUIService,
    public htmlExportService: HTMLExportService) {
    this.router = _router.url;
    this.gtmd = true;
    this.currentPath = "";
    this.medium = false;
    this.loggedIn = false;
    this.privacyComplete = true;
    this.needsJobTitle = false;
    this.authRoleClasses = {
      studentSearch: true,
      worklist: true,
      sendInfo: true,
      enableRegion: true,
      editRegion: true,
      stateAdmin: true,
      regionalAdmin: true,
      districtAdmin: true,
      passwords: true,
      reports: true
    };
    this.user = "";
    this.showSideNav = false;
    this.userLoaded = true;
    this.isLoggedInWithOTP = false;
    this.menuOpen = false;

    this._router.events.subscribe(async (val) => {
      this.loggedIn = this.authService.isAuthenticated();
      //console.log("Inside Constructor, loggedIn:", this.loggedIn);
      if (val instanceof NavigationStart) {
        console.log("Header.constructor() - FromState:", _router.url);
        this.fromState = _router.url;

        if (!this.loggedIn) {
          return;
        } else {
          //Logged in user
          let userObj: any = this.cookieService.getObject('user');
          this.isLoggedInWithOTP = userObj.isOTP;
          if (!this.privacyComplete && userObj) {
            this.privacyComplete = userObj.privacyFlag;
          }

          this.needsJobTitle = userObj.needsJobTitle;

          //Need to verify roles when coming from login page
          if ((this.fromState === '/login')) {
            this.verifyAuthorizedRoles();
            console.log('updating worklist count in init.');
            await this.worklistService.getWorklistCount();
          }
        }
      } else if (val instanceof NavigationEnd) {
        console.log("Header.constructor() - ToState:", _router.url);
        this.router = _router.url;

        let userObj: any = this.cookieService.getObject('user');
        // Update following values to control header and sidenav
        this.loggedIn = this.authService.isAuthenticated();
        if (userObj) {
          this.user = userObj.name;
          this.needsJobTitle = userObj.needsJobTitle;
          this.privacyComplete = userObj.privacyFlag;
        } else {
          this.user = '';
          this.privacyComplete = false;
          this.needsJobTitle = false;
        }
      }
    });

    //Need to verify roles when refreshing, as checked in app.component
    if (browserRefresh) {
      this.verifyAuthorizedRoles();
    }

    /* console.log('authRoleClasses values in constructor: ');
    console.log(this.authRoleClasses.stateAdmin);
    console.log(this.authRoleClasses.regionalAdmin);
    console.log(this.authRoleClasses.districtAdmin);
    console.log(this.authRoleClasses.passwords);

    console.log('HEADER component constructor START');
    console.log(this.loggedIn);
    console.log(this.privacyComplete);
    console.log(this.needsJobTitle);
    console.log(this.router);
    console.log(this.router === '/');
    console.log('toState: ' + this.router)
    console.log('HEADER component constructor END'); */
  }

  ngOnInit() {
    this.loggedIn = this.authService.isAuthenticated();
    console.log("Header.ngOnInit() - LOGGEDIN: ", this.loggedIn);

    this.currentPath = this.router;

    this.getCurrUser();

    /* if (!this.loggedIn) {
      return;
    } else {
      // Logged in user
      this.isLoggedInWithOTP = userObj.isOTP;
      if (!this.privacyComplete && userObj) {
        this.privacyComplete = userObj.privacyFlag;
      }
      
      this.needsJobTitle = userObj.needsJobTitle;
  
      if ((this.fromState === '/login')) {
        this.authService.isRoleClassAuthorized(['myAccount', 'studentSearch', 'reports', 
          'sendInfo', 'worklist', 'stateAdmin', 'regionalAdmin', 'districtAdmin', 'enableRegion', 
          'editRegion']).then(data => {
            this.authRoleClasses = data;
        });

        console.log('updating worklist count in init.');
        this.worklistService.getWorklistCount();
       }
    } */
  }

  async verifyAuthorizedRoles() {
    await this.authService.isRoleClassAuthorized(['myAccount', 'studentSearch', 'reports',
      'sendInfo', 'worklist', 'stateAdmin', 'regionalAdmin', 'districtAdmin', 'enableRegion',
      'editRegion']).then(data => {
        this.authRoleClasses = data;
      });
  }
  openOnMouseClick() {
    this.clickMenuTrigger.openMenu();
  }

  openMenu() {
    this.menuOpen = !this.menuOpen;
  }

  toggleSideNav() {
    this.showSideNav = !this.showSideNav;
    if (document.getElementById("sidenav-right").style.width === "220px") {
      document.getElementById("sidenav-right").style.width = "0";
    } else {
      document.getElementById("sidenav-right").style.width = "220px";
    }
  }

  signOut() {
    //this.closeDialog();
    let apiCall = this.authService.logout('');
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: data => {
        // this.goToLoginPage();
        console.log('header.component signOut(): ', data);

        // Navigate after the user has been successfully logged out and cookies have been cleared. TODO - Do we need this?
        this._router.navigate(['/']);

        subscribed.unsubscribe();
      },
      // Callback for failure
      error: error => {
        console.error("Error logging out from the header component: ", error);
        this._Toast.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  goToLoginPage() {
    this._router.navigate(['login']);
  }

  goToHomePage() {
    this._router.navigate(['/home']);
  }

  goToSearchPage() {
    this._router.navigate(['searchResults']);
  }

  goToWorkList() {
    this._router.navigate(['worklist']);
  }

  goToSavedList() {
    this._router.navigate(['savedList']);
  }

  /* goToReportsOld() {
    this.reportsService.getReportsURL().then(
      (res) => {
        this.$window.location.href = res.data;
        this.$rootScope.trackOutboundLink(res.data);
      },
      (err) => {
        console.log(err);
        //TODO: upgrade Toast
        //this._Toast.showToast(err.data.developerMessage, 0);
      }
    );
  } */

  goToReportsNew() {
    this._router.navigate(['reports']);
  }

  goToAccount() {
    this._router.navigate(['/myaccount']);
  }

  goToAdminPage() {
    this._router.navigate(['userAdmin']);
  }

  goToTraining() {
    this._router.navigate(['training']);
  }

  goToContact() {
    this._router.navigate(['help']);
  }

  goToResources() {
    this._router.navigate(['resources']);
  }

  goToStateRegion() {
    this._router.navigate(['dataAdmin']);
  }

  goToRequestAccount() {
    this._router.navigate(['requestAccount']);
  }

  goToHelp() {
    var newWindow = window.open();
    newWindow.opener = null;
    newWindow.location = this.helpUrl + 'help/index.htm';
  }

  checkIfUserAdmin() {
    for (var role in this.userRoles) {
      if ((this.userRoles[role] === this.appConstantsRoles.rua) || (this.userRoles[role] === this.appConstantsRoles.sua)) {
        this.isUserAdmin = true;
        break;
      }
    }
  }

  checkIfDataAdmin() {
    for (var role in this.userRoles) {
      if ((this.userRoles[role] === this.appConstantsRoles.sra) || (this.userRoles[role] === this.appConstantsRoles.su)) {
        this.isDataAdmin = true;
        break;
      }
    }
  }

  getCurrUser() {
    if (!this.loggedIn) {
      return;
    } else {

      this.userService.getCurrentUser().then(data => {
        let userData: any = data;
        console.log('header.component getCurrUser(): ', userData);
        this.userRoles = userData?.roles;
        this.checkIfUserAdmin();
        this.checkIfDataAdmin();
        this.betaUser = userData.betaUser;
        this.betaActive = userData.betaActive;
        this.userLoaded = true;
        this.user = userData.firstName + " " + userData.lastName;
      })

      /* let userData:any = this.userService.getCurrentUser();
      console.log('header.component getCurrUser(): ', userData);
      this.userRoles = userData.roles;
      this.checkIfUserAdmin();
      this.checkIfDataAdmin();
      this.betaUser = userData.betaUser;
      this.betaActive = userData.betaActive;
      this.userLoaded = true; */
    }


  }
}