<div class="pwReset-page background-awesome">
  <div class="md-content" 
    [ngClass]="{'pwRstBxDsktp': responsiveUI.isDesktop, 'pwRstBxTbltPrtrt': (responsiveUI.isTablet && responsiveUI.isPortrait), 'pwRstBxTbltLndscp': (responsiveUI.isTablet && !responsiveUI.isPortrait), 'pwRstBxMblPrtrt': (responsiveUI.isMobile && responsiveUI.isPortrait), 'pwRstBxMblLndscp': (responsiveUI.isMobile && !responsiveUI.isPortrait)}" >

    <h2>{{header}}</h2>
    <p *ngIf="header === 'Resetting Your Password'">For a password reset, please search for, and contact your designated MSIX State Contact.</p>
    <p *ngIf="header !== 'Resetting Your Password'">Search for your designated MSIX State Contact.</p>

    <div  *ngIf="states && states.length > 0">
      <form name="pwResetForm" (ngSubmit)="goToAdminSearchResults()">
        <mat-form-field appearance="outline">
          <mat-label>State</mat-label>
          <mat-select [(ngModel)]="selectedState" (selectionChange)="getDistrictList()" 
            name="stateSelect">
            <mat-option *ngFor="let state of states" [value]="state">
              {{state.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        
        <mat-form-field *ngIf="selectedState && districts && districts.length > 0" appearance="outline">
          <mat-label>District</mat-label>
          <mat-select [(ngModel)]="selectedDistrict" name="districtSelect" (selectionChange)="selectDistrictFromList()">
            <mat-option  *ngFor="let district of districts | sortBy:'asc':'name'" [value]="district.name">
              {{district.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button class="md-button submit-btn" type="Submit" [disabled]="determineDisableStatus()">Submit</button>
      </form>
    </div>

    <hr class="thickHr">

    <div class="helpDesk">
      <h2>Help Desk</h2>
      <div>
        <a href="mailto:MSIXSupport@deloitte.com?Subject=MSIX%20Password%20Reset%20Request"><i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;MSIXSupport@deloitte.com</a>
      </div>
      <a href="tel:+1-866-878-9525"><i class="fa fa-phone" aria-hidden="true"></i>&nbsp;1-866-878-9525</a>
      <p>NOTE: The Help Desk cannot reset passwords.</p>
    </div>

  </div>

</div>