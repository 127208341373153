import * as d3 from 'd3';
import { Component, Input, SimpleChanges} from '@angular/core';
import * as _ from 'lodash'

const ChartTypes = Object.freeze({
  TOPRECORDSEXCHANGE: "topRecordsExchangeChart"
});

// Make sure that the keys match the ChartTypes constant!
const TooltipHeights = {
  "topRecordsExchangeChart": "50px"
};

const TooltipWidths = {
  "topRecordsExchangeChart": "250px"
};

// Reference the examples housed under d3js.org with questions
@Component({
  selector: 'msix-bar-chart',
  templateUrl: './msixBarChart.component.html',
  styleUrls: ['./msixBarChart.component.scss']
})
  
// Reference the examples housed under d3js.org with questions
export class MsixBarChartComponent {
  // Gets info from Angular bindings, data is coming from the API as a DatabaseWrapper class containing the majority of chart info
  @Input() chart: any;
  @Input() data: any;
  @Input() id: any;

  constructor() {}

  ngOnInit() {
    this.paint();
  }
  // Call the paint function, add listeners for resize or if the chart attribute changes value (filter change)
  
  ngOnChanges(changes: SimpleChanges) {
    window.addEventListener("resize", this.paint);
    this.paint();
  }

  // Function for building the full chart
  paint() {
    // Removes old iterations of the chart in order to allow for resize
    d3.select("#" + this.id).selectAll("*").remove();

    var margin = resolveMargin(this.chart);
    var height = window.innerHeight / 1.8 > 500 ? window.innerHeight / 1.8 : 500;
    var width = document.getElementById("chart-section").offsetWidth > 800 ? document.getElementById("chart-section").offsetWidth : 800;

    var groupCategories = resolveGroupCategories(this.data);
    var colors = resolveColors(this.chart);

    var xValues = resolveXValues(this.data);
    var legendMap = resolveLegendMap(this.data);
    var data = resolveData(this.data);

    // The x-axis scale (IE entire scale)
    var x = d3.scaleBand()
        .domain(xValues)
        .rangeRound([margin.left, width - margin.right])
        .paddingInner(0.1);

    // The x-axis scale contained within each group
    var xGroup = d3.scaleBand()
      .domain(groupCategories)
      .rangeRound([0, x.bandwidth()])
      .padding(0.05);

    // The y-axis scaled, automatically calculated
    var y = d3.scaleLinear()
      .domain([0, (data.length == 0) ? 10 : d3.max(data, d => {
        return d.value;
      })]).nice()
      .rangeRound([height - margin.bottom, margin.top]);

    // The color of the bars. Assigned numerically to the order of groupCategories
    var color = d3.scaleOrdinal()
      .domain(groupCategories)
      .range(colors);

    var xAxis = g => g
      .call(d3.axisBottom(x).tickSizeOuter(0))
      .attr("transform", `translate(${0}, ${height - margin.bottom})`)
      .attr("class", "axis")
      .style("font-family", "Work Sans, sans-serif")
      .style("font-size", "15px")
      .style("color", "#4A4A4A")
      .call(g => g.select(".domain").remove());

    var yAxis = g => g
      .attr("transform", `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).ticks(null, "s").tickFormat(d3.format(",")))
      .attr("class", "axis")
      .style("font-family", "Work Sans, sans-serif")
      .style("font-size", "15px")
      .style("color", "#4A4A4A")
      .call(g => g.select(".domain").remove());

    var legend = svg => {
      const g = svg
        .attr("transform", `translate(${margin.left},${height - margin.bottom + 50})`)
        .attr("text-anchor", "start")
        .attr("class", "legend")
        .style("font-family", "Work Sans, sans-serif")
        .style("font-size", "16px")
        .style("color", "#4A4A4A")
        .selectAll("g")
        .data(color.domain().slice())
        .join("g")
        .attr("transform", (d, i) => `translate(${0},${i * 25})`);

      g.append("rect")
        .attr("x", 0)
        .attr("width", 19)
        .attr("height", 19)
        .attr("fill", color);

      g.append("text")
        .attr("x", 30)
        .attr("y", 9.5)
        .attr("dy", "0.35em")
        .text(d => legendMap[d]);
    }
    // Vertical gridlines
    var grid = g => g
      .attr("class", "grid") //css has to be manually added below
      .style("stroke", "cacaca")
      .style("stroke-opacity", "#0.5")
      .style("shape-rendering", "crispEdges")
      .attr("transform", `translate(${(x.bandwidth() + x.paddingInner() * x.step()) / 2}, ${margin.top})`)
      .call(d3.axisBottom(x).ticks(5)
        .tickSize(height - margin.top - margin.bottom)
        .tickFormat("")
      );

    // Small screen adjustment (carryover from original lineChart directive)
    var horizShift = 0
    if (window.innerWidth < 1100) {
      horizShift = 50
    }

    // Define the div for the tooltip
    var div = d3.select("#" + this.id).append("div")
      .attr("class", "linechart-tooltip")
      .style("opacity", 0);

    // The actual svg element of the chart
    var svg = d3.select("#" + this.id).append('svg')
      .attr("class", "dashboard-svg")
      .attr("viewBox", [0, 0, width, height])
      .style("overflow", "visible");

    var month = svg.append("g");
    // .selectAll("g")
    // .data(data)
    // .join("g")
    // .attr("transform", d => `translate(${x(d.category)},0)`)
    // .attr('pointer-events', 'bounding-box');

    var minPix = 8; // Minimum pixel size of a bar 
    // because of the difference in bar size possible,
    // smaller bars may have a calculated size of <1 pixel and then don't show

    // Generates the bar
    month.selectAll("rect")
      .data(data)
      .join("rect")
      .attr("class", "bar")
      .attr("x", d => xGroup(d.subcategory) + x(d.category))
      .attr("y", d => {
        if (d.value != 0 && y(d.value) > y.range()[0] - minPix) {
          return y.range()[0] - minPix;
        }
        return y(d.value);
      })
      .attr("width", xGroup.bandwidth())
      .attr("height", d => {
        if (d.value != 0 && y(d.value) > y.range()[0] - minPix)
          return minPix;
        return y(0) - y(d.value);
      })
      .attr("fill", d => color(d.subcategory))
      .on("pointerover", (event, d) => { // Tooltip stuff
        if (event.pointerType == "touch") {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        div.transition()
          .duration(200)
          .style("opacity", .9);

        div.html(resolveHover(this.chart, d, legendMap))
          .style("left", (event.pageX) + "px")
          .style("top", (event.pageY) + "px")
          .style("height", TooltipHeights[this.chart])
          .style("width", TooltipWidths[this.chart])
          .style("position", "absolute") // had to add styles from "linechart-tooltip" class to here specifically, class wasnt being applied
          .style("text-align", "center")
          .style("font", "12px sans-serif")
          .style("border", "0px")
          .style("padding", "2px")
          .style("background", "lightsteelblue")
          .style("border-radius", "8px")
          .style("pointer-events", "none");
      })
      .on("pointerout", (event, d) => {
        if (event.pointerType == "touch") {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        div.transition()
          .duration(500)
          .style("opacity", 0);
      })
      .on('pointermove', (event, d) => {
        if (event.pointerType == "touch") {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        div.style('top', (event.pageY - 60) + 'px')
          .style('left', (event.pageX - horizShift - 100) + 'px');
      })
      .on("touchend touchstart", (event, d) => {
        event.preventDefault();
        event.stopPropagation();
      });

        svg.append("g")
          .call(xAxis);

        svg.append("g")
          .call(yAxis);

        svg.append("g")
          .call(legend);

        svg.append("g")
          .call(grid);

        // add the X gridlines
        // svg.append("g")
        //     .attr("class", "grid")
        //     .attr("transform", `translate(${x.bandwidth() / 2}, ${0})`)
        //     .call(d3.axisBottom(x).ticks(5)
        //         .tickSize(-height)
        //         .tickFormat("")
        //     );

        // Y Axis label
        svg.append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 0)
          .attr("x", - ((height - margin.bottom + margin.top) / 2))
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .style("font-family", "Work Sans, sans-serif")
          .style("font-size", "15px")
          .style("color", "#4A4A4A")
          .attr("class", "axis")
          .text(this.data.y);

        // X Axis Label
        svg.append("text")
          .attr("class", "axis")
          .attr("y", height - margin.bottom + 25)
          .attr("x", margin.left + (width - margin.left - margin.right) / 2)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .style("font-family", "Work Sans, sans-serif")
          .style("font-size", "15px")
          .style("color", "#4A4A4A")
          .text(this.data.x);
      

    function resolveMargin(chartType) {
      if (chartType == ChartTypes.TOPRECORDSEXCHANGE)
        return { top: 70, right: 10, bottom: 150, left: 90 };
    }

    function resolveGroupCategories(fullData) {
      return fullData.groupCategories;
    }

    function resolveXValues(fullData) {
      return fullData.xValues;
    }

    function resolveColors(chartType) {
      if (chartType == ChartTypes.TOPRECORDSEXCHANGE)
        return ["#112E51", "#2CA02C", "#77113F", "#F74B00"];
    }

    function resolveData(fullData) {
      let adjustedData = _.cloneDeep(fullData.data);
      for (let row of adjustedData) {
        for (let point of row) {
          point.value = Number(point.value);
        }
      }
      return adjustedData[0];
    }

    function resolveLegendMap(fullData) {
      return fullData.legendMap;
    }

    function resolveHover(chartType, element, legendMap) {
      if (chartType == ChartTypes.TOPRECORDSEXCHANGE) {
        return legendMap[element.subcategory] + "<br/>" + element.category + ": " + d3.format(",")(element.value);
      }
    }

    // Wraps text utilizing tspans
    function wrap(text, width, sizeChange) {
      text.each(function () {
        var text = d3.select(this);
        var words = text.text().split(/\s+/).reverse();
        if (words.length < 2)
            return;
        var word = words.pop();
        var line = [];
        var lineNumber = 0;
        var lineHeight = 1; // ems
        var y = text.attr("y");
        var dy = parseFloat(text.attr("dy"));
        var tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
        while (word != undefined) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
            // counter++;
          }
          word = words.pop();
        }
      });
    }
  }
}