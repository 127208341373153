<div class="setChallengeQuestions-page background-awesome">
  <div class="md-content" [ngClass]="{'layoutMobP': (responsiveUI.isMobile && responsiveUI.isPortrait), 'layoutMobL': (responsiveUI.isMobile && !responsiveUI.isPortrait),
  'layoutTabP': (responsiveUI.isTablet && responsiveUI.isPortrait), 'layoutTabL': (responsiveUI.isTablet && !responsiveUI.isPortrait), 'layoutDesktop': responsiveUI.isDesktop}">

    <div class="title center">
      <h1>Set Challenge Questions</h1>
    </div>

    <div class="errors flex-inline" *ngIf="errors">
      <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
      <p class="validation">{{errors}}</p>
    </div>
      <div class="questions-wrapper">
        <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Challenge Question 1</mat-label>
              <mat-select [(value)]="question1">
                <mat-option *ngFor="let key of question1Array" [value]="key"
                  (click)="addAndRemoveSelectedQuestion(1, key)">
                  {{allChallengeQuestions[key]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
           
            <mat-form-field appearance="outline">
              <mat-label>Response 1</mat-label>
              <input matInput  type="text" [formControl]="response1FormControl" required>
              <mat-error  *ngIf="response1FormControl.touched && response1FormControl.errors?.required">
                Please provide a response.
              </mat-error>
              <mat-error *ngIf="response1FormControl.errors?.maxlength">
                Responses must be 35 characters or less.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Challenge Question 2</mat-label>
              <mat-select [(value)]="question2" [ngClass]="{'mat-input-has-value': question2}">
                <mat-option *ngFor="let key of question2Array" [value]="key"
                  (click)="addAndRemoveSelectedQuestion(2, key)">
                  {{allChallengeQuestions[key]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          
            <mat-form-field appearance="outline">
              <mat-label>Response 2<span [ngClass]="{'error-asterisk': response2FormControl.touched && (response2FormControl.errors?.required || response2FormControl.errors?.maxlength) }"></span></mat-label>
              <input matInput  type="text" inputmode="numeric" 
                [(value)]="response2"
                [formControl]="response2FormControl"
                required>
              <mat-error class="error-message" *ngIf="response2FormControl.touched && response2FormControl.errors?.required">
                Please provide a response.
              </mat-error>
              <mat-error class="error-message" *ngIf="response2FormControl.errors?.maxlength">
                Responses must be 35 characters or less.
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Challenge Question 3</mat-label>
              <mat-select [(value)]="question3">
                <mat-option *ngFor="let key of question3Array" [value]="key"
                  (click)="addAndRemoveSelectedQuestion(3, key)">
                  {{allChallengeQuestions[key]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Response 3<span [ngClass]="{'error-asterisk': response3FormControl.touched && (response3FormControl.errors?.required || response3FormControl.errors?.maxlength) }"></span></mat-label>
              <input matInput type="text" inputmode="numeric" 
                [(value)]="response3" [formControl]="response3FormControl"
                required>
              <mat-error *ngIf="response3FormControl.touched && response3FormControl.errors?.required">
                Please provide a response.
              </mat-error>
              <mat-error *ngIf="response3FormControl.errors?.maxlength">
                Responses must be 35 characters or less.
              </mat-error>
            </mat-form-field>
        </div>
        
        <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Challenge Question 4</mat-label>
              <mat-select [(value)]="question4" [ngClass]="{'mat-input-has-value': question4}">
                <mat-option *ngFor="let key of question4Array" [value]="key" 
                  (click)="addAndRemoveSelectedQuestion(4, key)">
                  {{allChallengeQuestions[key]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          
            <mat-form-field appearance="outline">
              <mat-label >Response 4<span [ngClass]="{'error-asterisk': response4FormControl.touched && (response4FormControl.errors?.required || response4FormControl.errors?.maxlength) }"></span></mat-label>
              <input matInput type="text" inputmode="numeric" 
                [(value)]="response4"
                [formControl]="response4FormControl"
                [ngClass]="{'mat-input-has-value': response4FormControl.value, 'input-error': (response4FormControl.touched && response4FormControl.errors?.required) || response4FormControl.errors?.maxlength}"
                required>
              <mat-error class="error-message" *ngIf="response4FormControl.touched && response4FormControl.errors?.required">
                Please provide a response.
              </mat-error>
              <mat-error class="error-message" *ngIf="response4FormControl.errors?.maxlength">
                Responses must be 35 characters or less.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Challenge Question 5</mat-label>
              <mat-select [(value)]="question5" [ngClass]="{'mat-input-has-value': question5}">
                <mat-option *ngFor="let key of question5Array" [value]="key"
                  (click)="addAndRemoveSelectedQuestion(5, key)">
                  {{allChallengeQuestions[key]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          
            <mat-form-field appearance="outline">
              <mat-label>Response 5<span [ngClass]="{'error-asterisk': response5FormControl.touched && (response5FormControl.errors?.required || response5FormControl.errors?.maxlength) }"></span></mat-label>
              <input matInput type="text" inputmode="numeric" 
                [(value)]="response5"
                [formControl]="response5FormControl"
                [ngClass]="{'mat-input-has-value': response5FormControl.value, 'input-error': (response5FormControl.touched && response5FormControl.errors?.required) || response5FormControl.errors?.maxlength}"
                required>
              <mat-error class="error-message" *ngIf="response5FormControl.touched && response5FormControl.errors?.required">
                Please provide a response.
              </mat-error>
              <mat-error class="error-message" *ngIf="response5FormControl.errors?.maxlength">
                Responses must be 35 characters or less.
              </mat-error>
            </mat-form-field>

        </div>
      </div>

      <div class="action-button">
        <button class="md-button" (click)="submitForm()" type="submit" 
          [disabled]="(!question1 || !question2 || !question3 || !question4 || !question5
          || response1FormControl.errors || response1FormControl.value?.length < 2
          || response2FormControl.errors || response2FormControl.value?.length < 2
          || response3FormControl.errors || response3FormControl.value?.length < 2
          || response4FormControl.errors || response4FormControl.value?.length < 2
          || response5FormControl.errors || response5FormControl.value?.length < 2)">Submit
        </button>
      </div>
    </div>

</div>
  