import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsService } from 'src/app/services/reports.service';
import { SearchService } from 'src/app/services/search.service';
// import * as angular from 'angular';
import { COLUMN_NUMBER } from 'src/app/views/reports/types/enums';
import { Subscription } from 'rxjs';

@Component({
  selector: 'reports-table',
  templateUrl: './reportsTable.component.html',
  styleUrls: ['./reportsTable.component.scss']
})

export class ReportsTableComponent implements OnInit, OnDestroy {
  @Input() responsedata: any;
  @Input() hasreportlevelspecialsort?: any;
  @Input() isreportlevelspecialsortinit?: any;
  @Input() doubleHeader?: any;
  @Input() qadToggle?: any;
  @Input() isinitaldesc?: boolean;
  @Input() shownavigation?: boolean;
  @Input() drilldowninfo?: any;
  @Input() stopnavigate?: boolean;
  @Input() enableworklink?: boolean;
  @Input() mdereport?: string;
  @Input() showcheckbox?: boolean;
  @Input() currentsortcolumn: COLUMN_NUMBER;
  @Input() sortascending: boolean;
  @Input() pfsReport: boolean;
  @Input() emitRowClick: boolean;
  @Input() msixIdShouldNavigate: boolean = true;

  @Output() mycallback = new EventEmitter<object>();
  @Output() checkboxcallback?= new EventEmitter<object>();
  @Output() drilldownsort?= new EventEmitter();
  @Output() reportlevelspecialsort?= new EventEmitter();
  @Output() sortbycolumn?= new EventEmitter<object>();
  @Output() navigatestart?= new EventEmitter();
  @Output() navigateend?= new EventEmitter();
  @Output() handleMSIXIDClick?= new EventEmitter<any>();

  router: string;
  redBottom: boolean;
  isDrilldown: boolean;
  currentSortColumn: COLUMN_NUMBER;
  isMultiLeveled: boolean;
  colHdrWidthClass: string;
  showHeader: boolean;
  qadRight: boolean;
  sortAsc: boolean;
  sortAscending: boolean;
  beginRow: number;
  endRow: number;
  subscription = new Subscription();
  constructor(
    private reportsService: ReportsService,
    private searchService: SearchService,
    private _router: Router) {
    this.router = _router.url;
    this.redBottom = false;
    this.isDrilldown = false;
    this.currentSortColumn = COLUMN_NUMBER.COLUMN_ONE; // setting default sort column to 1
    this.isMultiLeveled = false;
    this.colHdrWidthClass = '';
    this.showHeader = true;
    this.qadRight = this.qadToggle;
    this.sortAsc = false;
    this.sortAscending = true;
    this.pfsReport = false;
  }

  get colHdrWidthClassGetter() {
    if (this.isMultiLeveled === true) {
      return 'width' + this.responsedata.columnCount;
    } else {
      return "widthfull" + this.responsedata.columnCount;
    }
  }

  ngOnInit(): void {
    this.subscription.add(this.reportsService.filterParamsUpdated$.subscribe(val => {
      if (val) {
        this.currentSortColumn = COLUMN_NUMBER.COLUMN_ONE;
        this.sortAscending = true;
        this.sortascending = true;
      }
    }
    ))
    this.subscription.add(this.reportsService.sortColumn$.subscribe(val => {
      // val will be null if showing drill down
      if (!val) return;
      // if this is main table component, set the sort column to what it was before navigating to drill down
      this.currentSortColumn = val.columnNumber;
      this.sortAscending = val.sortOrder === 'asc';
    }));
    if (!this.sortascending) {
      this.sortAscending = this.sortascending;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.currentSortColumn = this.currentsortcolumn = changes.currentsortcolumn ? changes.currentsortcolumn.currentValue : this.currentSortColumn;
    // add red bottom border to Reconciliation MDE Completeness / Validity drilldowns
    this.redBottom = false;
    if (this.responsedata.columnHeaders && this.responsedata.columnHeaders.header7) {
      if (this.responsedata.columnHeaders.header7.value === 'Missing Data Element' || this.responsedata.columnHeaders.header7.value === 'Invalid Data Element') {
        this.redBottom = true;
      }
    }

    var newValue: any;
    if (changes.responseData !== undefined) {
      newValue = changes.responsedata.currentValue;
    } else {
      newValue = this.responsedata;
    }
    if (Object.keys(newValue).length === 0) {
      return;
    }

    if (newValue.columnHeaders &&
      newValue.columnHeaders.header1 &&
      newValue.columnHeaders.header1.sortAsc && newValue.columnHeaders.header1.sortDesc) {
      this.isDrilldown = true;
      if (this.isreportlevelspecialsortinit) {
        this.currentSortColumn = null;
      }
    } else {
      this.isDrilldown = false;
      this.currentSortColumn = null;
    }

    if (newValue.rows) {
      this.isMultiLeveled = newValue.rows.some(function (row) {
        return (row.groupingRow === true);
      });
    }

    if (this.isMultiLeveled === true) {
      this.colHdrWidthClass = 'width' + this.responsedata.columnCount;
    } else {
      this.colHdrWidthClass = 'widthfull' + this.responsedata.columnCount;
    }

    if (this.doubleHeader) {
      this.showHeader = true;
    } else {
      this.showHeader = false;
    }

    if (this.qadToggle) {
      this.qadRight = true;
    } else {
      this.qadRight = false;
    }

    // if responsedata has rows and columnheaders populated
    if (newValue && newValue.rows && newValue.columnHeaders) {
      newValue.depth = 0;
      if (newValue.rows && newValue.rows.length > 0) {
        newValue.rows.forEach(function (row) {
          row.depth = 1;
          if (row.rows && row.rows.leqadRightqadRightngth > 0) {
            row.rows.forEach(function (row2) {
              row2.depth = 2;
              if (row2.rows && row2.rows.length > 0) {
                row2.rows.forEach(function (row3) {
                  row3.depth = 3;
                });
              }
            });
          }
        });
      }
      if (!this.currentSortColumn) {
        if (newValue.query) {
          if (newValue.query.studentIDFilter) {
            for (var i = 1; i < newValue.columnCount; i++) {
              if (newValue.columnHeaders['header' + i].sortAsc === newValue.query.studentIDFilter.sortFields) {
                this.sortAsc = false;
                this.sortAscending = true;
                this.currentSortColumn = 'column' + i as COLUMN_NUMBER;
              } else if (newValue.columnHeaders['header' + i].sortDesc === newValue.query.studentIDFilter.sortFields) {
                this.sortAsc = true;
                this.sortAscending = false;
                this.currentSortColumn = 'column' + i as COLUMN_NUMBER;
              }
            }
          } else {
            for (i = 1; i <= newValue.columnCount; i++) {
              if (newValue.columnHeaders['header' + i].sortAsc === newValue.query.sortField) {
                this.sortAsc = false;
                this.sortAscending = true;
                this.currentSortColumn = 'column' + i as COLUMN_NUMBER;
              } else if (newValue.columnHeaders['header' + i].sortDesc === newValue.query.sortField) {
                this.sortAsc = true;
                this.sortAscending = false;
                this.currentSortColumn = 'column' + i as COLUMN_NUMBER;
              }
            }
          }
          if (!this.currentSortColumn) {
            this.currentSortColumn = COLUMN_NUMBER.COLUMN_ONE;
            this.sortAscending = true;
          }
        } else {
          this.currentSortColumn = (this.currentsortcolumn) ? this.currentsortcolumn : 'column1' as COLUMN_NUMBER;
          this.sortAscending = (this.sortascending == false) ? false : true;

          if (this.isinitaldesc) {
            this.sortAscending = !this.sortAscending;
            this.sortColumn(this.currentSortColumn, this.responsedata.columnHeaders.header1.sortAsc, this.responsedata.columnHeaders.header1.sortDesc);
          }
        }
      } else {
        if (this.isinitaldesc) {
          this.sortAscending = true;
          this.sortColumn(this.currentSortColumn, this.responsedata.columnHeaders.header1.sortAsc, this.responsedata.columnHeaders.header1.sortDesc);
        }
      }
    }

    // Resetting the value for beginRow and endRow as for some reports, the drillDown is not set on the responsdata
    // and is not part of the StudentIDFilter that is returned as part of the response object.
    if (this.shownavigation === true) {
      if (this.responsedata.drillDown) {
        this.drilldowninfo.drillDown.beginRow = this.responsedata.drillDown.beginRow;
        this.drilldowninfo.drillDown.endRow = this.responsedata.drillDown.endRow;
      }
      if (this.drilldowninfo?.drillDown?.studentIDFilter) {
        this.beginRow = this.drilldowninfo.drillDown.studentIDFilter.beginRow;
        this.endRow = this.drilldowninfo.drillDown.studentIDFilter.endRow;
      } else {
        this.beginRow = this.drilldowninfo.drillDown.beginRow;
        this.endRow = this.drilldowninfo.drillDown.endRow;
      }
    }
  };

  sortColumn(columnNumber, sortAscKey, sortDescKey) {
    if (columnNumber == this.currentSortColumn) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.currentSortColumn = columnNumber;
      this.sortAscending = true;
    }

    if (this.reportsService.sortColumn$.value) { // if table is the main table vs drill down table
      // set the sort column to the one it was before drilling down
      this.reportsService.sortColumn = columnNumber;
      this.reportsService.sortOrder = this.sortAscending ? 'asc' : 'desc';
    }

    this.currentsortcolumn = this.currentSortColumn;
    this.sortascending = this.sortAscending;
    this.sortbycolumn.emit({ column: this.currentSortColumn, ascending: this.sortAscending, sortAscKey, sortDescKey });


    // if keys exist then is drillDown and sorts are done
    // on the backend by the SQL
    if (sortAscKey && sortDescKey) {
      if (this.hasreportlevelspecialsort) {
        if (this.sortAscending) {
          this.reportlevelspecialsort.emit({ sortKey: sortAscKey });
        } else {
          this.reportlevelspecialsort.emit({ sortKey: sortDescKey });
        }
      } else {
        if (this.sortAscending) {
          this.drilldownsort.emit({ sortKey: sortAscKey });
        } else {
          this.drilldownsort.emit({ sortKey: sortDescKey });
        }
      }
    } else {
      const sortConst = this.sortAscending;
      function compare(a, b) {
        // Setting value to empty string when it is null
        var val1 = (a[columnNumber].value) ? a[columnNumber].value.toLowerCase() : '';
        var val2 = (b[columnNumber].value) ? b[columnNumber].value.toLowerCase() : '';

        if (a[columnNumber].number) {
          val1 = Number(a[columnNumber].value);
          val2 = Number(b[columnNumber].value);
        } else if (a[columnNumber].hasSortValue) {
          //Added to handle Data Request Report where
          //two columns at the Report level have special sort values
          if (a.originalResult.sortValueTwo && b.originalResult.sortValueTwo) {
            val1 = a.originalResult.sortValueTwo;
            val2 = b.originalResult.sortValueTwo;
          } else {
            val1 = a.originalResult.sortValue;
            val2 = b.originalResult.sortValue;
          }
        } else if (a[columnNumber].value.includes('Days') || b[columnNumber].value.includes('Days')) {
          //Added to account for values that have a number and the word 'Days' in them
          val1 = Number(a[columnNumber].value.replace(' Days', ''));
          val2 = Number(b[columnNumber].value.replace(' Days', ''));
        }
        //TODO: add date compare

        if (sortConst) {
          if (val1 < val2) {
            return -1;
          }
          if (val1 > val2) {
            return 1;
          }
          return 0;
        } else {
          if (val1 < val2) {
            return 1;
          }
          if (val1 > val2) {
            return -1;
          }
          return 0;
        }
      }
      this.sortRecursively(this.responsedata, compare, columnNumber);
    }
  };

  rowItemClicked($event) {
    //Identify groupingRow boolean in 2 layers of depth
    if ($event.row?.groupingRow) {
      $event.row.expanded = !$event.row.expanded;
    } else if ($event.row?.row?.groupingRow) {
      $event.row.row.expanded = !$event.row.row.expanded;
    }
    else {
      //Callback for row in 3rd cascade
      if (!$event.row?.originalResult && !$event.row?.row?.originalResult) {
        this.mycallback.emit({
          data: $event.row.row.row.originalResult,
          column: $event.row.row.column,
          columnName: $event.row.row.columnName,
          drillDownCount: $event.row.row.row[$event.row.row.column]?.value
        });
      } else {
        this.mycallback.emit({
          data: $event.row?.originalResult ? $event.row.originalResult : $event.row.row.originalResult,
          column: $event.column ? $event.column : $event.row.column,
          columnName: $event.columnName ? $event.columnName : $event.row.columnName,
          drillDownCount: $event.row[$event.column]?.value
        }
        );
      }
    }
  };

  checkboxClicked($event) {
    this.checkboxcallback.emit({
      row: $event.row,
      isChecked: $event.isChecked
    });
  };

  isHeader(header, sort) {
    if (header.sortAsc.contains(sort)) {
      return true;
    } else if (header.sortDesc.contains(sort)) {
      return true;
    }
    return false;
  }

  navigateDrillDown(direction) {
    //don't hardcode 20 pages
    this.navigatestart.emit();
    if (this.drilldowninfo.drillDown.studentIDFilter) {
      if (direction === "forward") {
        this.drilldowninfo.drillDown.studentIDFilter.beginRow = this.drilldowninfo.drillDown.studentIDFilter.beginRow + 20;
        this.drilldowninfo.drillDown.studentIDFilter.endRow = this.drilldowninfo.drillDown.studentIDFilter.endRow + 20;
      } else {
        this.drilldowninfo.drillDown.studentIDFilter.beginRow = this.drilldowninfo.drillDown.studentIDFilter.beginRow - 20;
        this.drilldowninfo.drillDown.studentIDFilter.endRow = this.drilldowninfo.drillDown.studentIDFilter.endRow - 20;
      }
      this.beginRow = this.drilldowninfo.drillDown.studentIDFilter.beginRow;
      this.endRow = this.drilldowninfo.drillDown.studentIDFilter.endRow;
    }
    else if (this.drilldowninfo.drillDown.beginRow && this.drilldowninfo.drillDown.endRow) {
      if (direction === "forward") {
        this.drilldowninfo.drillDown.beginRow = this.drilldowninfo.drillDown.beginRow + 20;
        this.drilldowninfo.drillDown.endRow = this.drilldowninfo.drillDown.endRow + 20;
      } else {
        this.drilldowninfo.drillDown.beginRow = this.drilldowninfo.drillDown.beginRow - 20;
        this.drilldowninfo.drillDown.endRow = this.drilldowninfo.drillDown.endRow - 20;
      }
      this.beginRow = this.drilldowninfo.drillDown.beginRow;
      this.endRow = this.drilldowninfo.drillDown.endRow;
    }
    let apiCall = this.reportsService.drillDownView(this.drilldowninfo.drillDown, this.drilldowninfo.reportGroupVar, this.drilldowninfo.reportDrillDownVar);
    let subscribed = apiCall.subscribe({
      next: data => {
        window.scrollTo(0, 0);

        // We are resetting the totalAvailableRows value as it is set in the frontend when the first page is loaded for 
        // the User Account Metrics drilldown page. And resetting drillDown data object, in specifically for the 
        // beginRow and endRow values for pagination. Once this.responsedata is set, $watch will be called and in that function
        // we are resetting the drillDown object under response data object.
        if (this.drilldowninfo.reportDrillDownVar == 'findUserAccountMetricsDrillDown') {
          data.totalAvailableRows = this.responsedata.totalAvailableRows;
        } else if (this.drilldowninfo.reportDrillDownVar == 'findUserAccountMetrics') {
          //res.data.drillDown = res.config.data;
          data.drillDown = data.config.data;
        }

        this.responsedata = data;
        this.navigateend.emit()
        subscribed.unsubscribe();
      },
      error: error => {
        console.log('Login component error: ', error.error.message);
        subscribed.unsubscribe();
      }
    });
  }


  sortRecursively(row, compare, columnNumber) {
    function sortInnerRows(row, compare, columnNumber) {
      if (row.rows) {
        var columnHasValues = false;
        row.rows.some(function (row) {
          if (row[columnNumber].value) {
            columnHasValues = true;
            return true;
          }
        });
        //sort the rows under the specified column based on the custom sort defined above
        if (row.rows.length > 1 && columnHasValues) {
          row.rows.sort(compare);
        }

        //if multi-leveled and rows within the rows to sort as well, sort those inner rows too
        // let innerColumnHasValues = false;
        // innerColumnHasValues = row.rows.some(function (row) {
        //   function checkRows(element, i, array) {
        //     return element[columnNumber].value;
        //   }
        //   if (row[columnNumber].value || row.rows.some(checkRows)) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // });
        // //sort the inner rows based on the custom sort defined above
        // if (innerColumnHasValues) {
          row.rows.forEach(function (row) {
            // row.rows.sort(compare);
            sortInnerRows(row, compare, columnNumber);
          });
        // }

        /* row.rows.forEach.bind(this)(function(row){
            this.sortRecursively(row, compare, columnNumber);
        }); */
      }
    }
    sortInnerRows(row, compare, columnNumber);
  }


  onMSIXIDClick(msixId: string) {
    const row = this.responsedata.rows.find(row => row.column1.value === msixId);
    const id = row?.originalResult?.id
    this.handleMSIXIDClick.emit(id);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
