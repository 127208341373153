import { Component, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
// import * as angular from "angular";
import { AppConstants } from 'src/app/config/index.constants';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'dataManagement',
  templateUrl: './dataManagement.component.html',
  styleUrls: ['./dataManagement.component.scss']
})
export class DataManagementComponent {
  recordSubmissionId: string = "recordSubmission";
  recordSubmissionChart: string = "recordSubmissionChart";
  withdrawalCourseHistoryId: string = "withdrawalCourseHistory";
  withdrawalCourseHistoryChart: string = "withdrawalCourseHistoryChart";
  chart: string = "recordSubmission";
  recordSubmissionExportCall: string = 'recordSubmissionExport';
  withdrawalCourseHistoryExportCall: string = 'withdrawalCourseHistoryExport';
  private appConst = AppConstants;
  private userStateName: string;
  private dataManagementFilterParams: any = {};
  private activeDataManagementFilter: any = {};
  private recordSubmissionData: any;
  private withdrawalCourseHistoryData: any;
  private drillDown: any;
  drillDownInfo: any = {};
  showDrill: boolean;
  private ua: string;
  private isMobile: boolean;
  private isTablet: boolean;
  private isDesktop: boolean;
  private activeTab: string;
  private periodText: string;
  private reportGroupVar: string;
  private reportDrillDownVar: string;
  private blankRes: boolean;
  private tabChangeDone: boolean;
  private drillDownData: any;
  private roleClasses: any[];
  private authRoleClasses: any;
  private dateObj: any;
  private displayData: any;
  private dataManagementExportObject: any;
  private recordSubmissionDataHasLoaded: boolean;
  private hideRecordSubmission: boolean;
  private courseHistSubmissionDataHasLoaded: boolean;
  private hideCourseHistSubmission: boolean;
  private dataHasLoaded: boolean;
  private lastUpdated: any;
  sortAscending: boolean = true;
  currentSortColumn: string = 'column1';
  showFilter: boolean;
  constructor(
    public dialog: MatDialog,
    private cookieService: CookieService,
    private reportsService: ReportsService,
    private reportInfoService: ReportInfoService,
    private authService: AuthService,
    private toastService: ToastService) {
      this.showDrill = false;
      this.ua = navigator.userAgent;
      this.isMobile = false;
      this.isTablet = false;
      this.isDesktop = false;
      this.reportGroupVar = 'studentInfo';
      this.reportDrillDownVar = 'dataManagementDrilldown';
      this.activeTab = 'totalLogins';
      this.periodText = '';
      this.blankRes = true;
      this.tabChangeDone = false;
      this.recordSubmissionDataHasLoaded = false;
      this.hideRecordSubmission = false;
      this.courseHistSubmissionDataHasLoaded = false;
      this.hideCourseHistSubmission = false;
      this.recordSubmissionData = {};
      this.withdrawalCourseHistoryData = {};
      this.dataHasLoaded = true;
      this.lastUpdated = new Date();
      this.showFilter = true;
      this.dateObj = {
        current: '',
        recent: '',
        currentStartYear: '',
        currentEndYear: '',
        recentStartYear: '',
        recentEndYear: ''
      };
      this.displayData = {
        current: "",
        recent: "",
        state: "",
        recordName: ""
      };
      this.authRoleClasses = {};
      this.drillDown = {
        beginRow: 1,
        endRow: 20,
        sortField: "MONTH_DESC",
        stateName: this.dataManagementFilterParams.selectedState,
        performancePeriod: this.dataManagementFilterParams.periodType,
        chartType: 'accountManagement',
        tab: this.activeTab
      };
      this.roleClasses = [
        'dataDashboards',
        'dataAdmins',
        'dataDashboard_studentAchievement_with_sua'
      ];
  }

  ngOnInit() {
    if (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(this.ua)) {
      this.isMobile = false;
      this.isTablet = true;
      this.isDesktop = false;
    }
    else if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(this.ua) || window.innerWidth < this.appConst.mobilePixels) {
      this.isMobile = true;
      this.isTablet = false;
      this.isDesktop = false;
    }
    else {
      this.isMobile = false;
      this.isTablet = false;
      this.isDesktop = true;
    }

    this.getReportInfo();
  }

  goToReport() {
    this.showDrill = false;
  }

  //Gets info needed to display the filter: list of states, user's state, both performance periods, and the authorized role classes
  async getReportInfo() {
    let reportInfo = await this.reportInfoService.getReportInfo(this.roleClasses);
    console.log("reportInfo: ", reportInfo);

    this.userStateName = reportInfo.userStateName;
    this.dateObj.current = reportInfo.currentPeriod;
    this.dateObj.recent = reportInfo.recentPeriod;
    console.log("this.dateObj.current: " + this.dateObj.current + " and this.dateObj.recent: " + this.dateObj.recent);
    this.authRoleClasses = reportInfo.authRoleClasses;
    if (reportInfo.authRoleClasses.dataAdmins) {
      this.dataManagementFilterParams = {
        rptGrping: 'State',
        selectedState: this.userStateName,
        periodType: 'CURRENT',
        authRoleClasses: this.authRoleClasses,
        dateObj: this.dateObj,
        states: []
      };
    } else {
      this.dataManagementFilterParams = {
        rptGrping: 'National',
        selectedState: "",
        periodType: 'CURRENT',
        authRoleClasses: this.authRoleClasses,
        dateObj: this.dateObj,
        states: []
      };
    }
    this.setStatesArray(reportInfo.stateData);

    //Generate the report automatically if in mobile mode
    //Otherwise open the filter dialog
    if (this.isMobile) {
      this.submitFilter();
    } else {
      this.filterActivate();
    }

  }

  // Trigger Filter Modal
  filterActivate() {
    this.showFilter = true;
    console.log('DataManagement filter has been opened');
    const dialogRef = this.dialog.open(FilterDataManagement, {
      height: 'auto',
      width: '60%',
      minHeight: '325px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: {params: this.dataManagementFilterParams},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.activeDataManagementFilter = result;
        this.dataManagementFilterParams = result;
        this.submitFilter();
        this.showFilter = false;
      }
    });
  }

  submitFilter() {
    this.recordSubmissionDataHasLoaded = false;
    this.hideRecordSubmission = false;
    let apiCall = this.reportsService.getDataManagementFilter(this.dataManagementFilterParams.selectedState, this.dataManagementFilterParams.periodType);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.dataManagementFilterParams = JSON.parse(JSON.stringify(this.activeDataManagementFilter));

        this.recordSubmissionData = data.recordSubmission;
        this.withdrawalCourseHistoryData = data.withdrawalCourseHistory;
        console.log("DataManagement.submitFilter().recordSubmissionData: ", this.recordSubmissionData);
        this.dataHasLoaded = true;
        this.recordSubmissionDataHasLoaded = true;
        this.courseHistSubmissionDataHasLoaded = true;
        console.log("DataManagement.submitFilter().withdrawalCourseHistoryData: ", this.withdrawalCourseHistoryData);
        this.setPeriodText();
        this.setLastUpdated();

        if (this.recordSubmissionData.data.length == 0) {
          this.hideRecordSubmission = true;
        }

        if (this.withdrawalCourseHistoryData.data.length == 0) {
          this.hideCourseHistSubmission = true;
        }

        if (this.dataManagementFilterParams.selectedState && this.dataManagementFilterParams.periodType) {
          this.dataManagementExportObject = {
            stateName: this.dataManagementFilterParams.selectedState,
            performancePeriod: this.dataManagementFilterParams.periodType
          };
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - DataManagement.submitFilter()", error);
        this.dataHasLoaded = true;
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  setLastUpdated() {
    this.reportsService.getFirstDayOfMonth().subscribe({
      next: data => {
        this.lastUpdated = data.date;
      },
      error: error => {
        console.log("Error - DataManagement.setLastUpdated(): ", error);
      }
    });
  }

  // removes DC, PR, and WY from states array and sets the fullStateArray with list of states
  setStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i].key
        };
        this.dataManagementFilterParams.states.push(responseData[i]);
      }
    }
  }

  //Open pop up window with more information on the dashboard
  goToLearnMore() {
    this.showFilter = true;
    const dialogRef = this.dialog.open(LearnMoreDataManagement, {
      height: 'auto',
      width: '50%',
      minHeight: 'fit-content',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: {params: this.authRoleClasses},
    });
    dialogRef.afterClosed().subscribe(result => {
      this.showFilter = false;
    });
  }

  goToDDLearnMore() {
    this.showFilter = true;
    const dialogRef = this.dialog.open(LearnMoreDDDataManagement, {
      height: 'auto',
      width: '50%',
      minHeight: 'fit-content',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,  
      data: {params: this.chart},
    });
    dialogRef.afterClosed().subscribe(result => {
      this.showFilter = false;
    });
  }

  showDrilldown(chart) {
    this.showDrill = true;
    this.chart = chart;
    this.sortAscending = true;
    this.currentSortColumn = 'column1';
    this.drillDownInit();
  }

  //Pull in Drilldown data
  drillDownInit() {
    this.drillDown = {
      beginRow: 1,
      endRow: 20,
      stateName: this.dataManagementFilterParams.selectedState,
      performancePeriod: this.dataManagementFilterParams.periodType,
      chartType: this.chart,
      tab: this.activeTab
    };
    if (this.chart === 'recordSubmission') {
      this.drillDown.sortField = 'MONTH_DESC';
    } else {
      this.drillDown.sortField = 'MSIX_ID_DESC';
    }
    this.updateDrilldown(this.drillDown);
  }

  updateDrilldown(drillDown) {
    this.drillDownData = {};
    this.reportGroupVar = 'dashboard';
    this.reportDrillDownVar = 'dataManagementDrilldown';
    let apiCall = this.reportsService.drillDownView(drillDown, this.reportGroupVar, this.reportDrillDownVar);
    let subscribed = apiCall.subscribe({
      next: data => {
        if (data.rows.length === 0) {
          this.blankRes = true;
        } else {
          console.log("DataManagement updateDrilldown(): ", data);
          this.blankRes = false;
          this.drillDownData = data;
          this.drillDownData.query = drillDown;
          this.drillDownInfo = {
            drillDown: drillDown, 
            reportGroupVar: this.reportGroupVar,
            reportDrillDownVar: this.reportDrillDownVar
          }
          subscribed.unsubscribe();
        }
      },
      error: error => {
        console.log("Error - DataManagement.updateDrilldown(): ", error);
        subscribed.unsubscribe();
      }
    });
  }

  //Sort Drill Down Data
  handleDrillDownSortClick(newSortKey) {
    this.drillDown.sortField = newSortKey.sortKey;
    this.updateDrilldown(this.drillDown);
  }

  //Updates which column and direction the table will be sorted by
  sortByColumn(data: {column: string, ascending: boolean}) {
    this.currentSortColumn = data.column;
    this.sortAscending = data.ascending;
  }

  setPeriodText() {
    switch (this.dataManagementFilterParams.periodType) {
      case ("CURRENT"):
        this.periodText = "Current performance period (" + this.dateObj.current + ")";
        break;
      case ("PREVIOUS"):
        this.periodText = "Last performance period (" + this.dateObj.recent + ")";
        break;
      default:
        this.periodText = "Current performance period (" + this.dateObj.current + ")";
        break;
    }
  }
  //stringifies an object
  jsonStringify(obj) {
    return JSON.stringify(obj);
  }
}

// Separate component for the DataManagement report's filter
export interface FilterParameters {
  params: {
    rptGrping: string, 
    selectedState: string,
    periodType: string,
    authRoleClasses: any,
    dateObj: any,
    states: any[]
  }
}
@Component({
  selector: 'filterDataManagement',
  templateUrl: './filterDataManagement.html',
  styleUrls: ['./dataManagement.component.scss']
})
export class FilterDataManagement {
  dataManagementFilterParams: {
    rptGrping: string, 
    selectedState: string,
    periodType: string,
    authRoleClasses: any,
    dateObj: any,
    states: any[]
  };
  constructor(
    public dialogRef: MatDialogRef<FilterDataManagement>,
    public responsiveUI: ResponsiveUIService,
    @Inject(MAT_DIALOG_DATA) public data: FilterParameters) {
      this.dataManagementFilterParams = cloneDeep(data.params);
      if (this.dataManagementFilterParams.rptGrping == '' || !this.dataManagementFilterParams.rptGrping){
        this.resetFilter();
      }
  }

  resetFilter() {
    if (this.dataManagementFilterParams.authRoleClasses.dataAdmins) {
      this.dataManagementFilterParams = {
        rptGrping: 'State',
        selectedState: this.data.params.selectedState,
        periodType: 'CURRENT',
        authRoleClasses: this.data.params.authRoleClasses,
        dateObj: this.data.params.dateObj,
        states: this.data.params.states
      };
    } else {
      this.dataManagementFilterParams = {
        rptGrping: 'National',
        selectedState: "",
        periodType: 'CURRENT',
        authRoleClasses: this.data.params.authRoleClasses,
        dateObj: this.data.params.dateObj,
        states: this.data.params.states
      };
    }
  }

  onSubmit(){
    this.dialogRef.close(this.dataManagementFilterParams);
  }

  selectState() {
    this.dataManagementFilterParams.rptGrping = 'State';
  }

  selectNational() {
    this.dataManagementFilterParams.rptGrping = 'National';
    this.dataManagementFilterParams.selectedState = '';
  }
  selectDrilldownState(state) {
    this.dataManagementFilterParams.selectedState = state;
  }

  selectPeriodType(periodType){
    this.dataManagementFilterParams.periodType = periodType;
  }

  onClose() {
    this.dataManagementFilterParams = cloneDeep(this.data.params);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dataManagementFilterParams = cloneDeep(this.data.params);
    this.dialogRef.close();
  }
}

// Separate component for the DataManagement report's Learn More dialog box
export interface LearnMoreParameters {
  params: {
    dataDashboards: boolean, 
    dataAdmins: boolean
  }
}
@Component({
  selector: 'learnMoreDataManagement',
  templateUrl: 'learnMoreDataManagement.html',
  styleUrls: ['./dataManagement.component.scss']
})
export class LearnMoreDataManagement {
  authRoleClasses: any = {};
  constructor(
    public dialogRef: MatDialogRef<LearnMoreDataManagement>,
    @Inject(MAT_DIALOG_DATA) public data: LearnMoreParameters
  ) {
    this.authRoleClasses = data.params;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the DataManagement report's drilldown Learn More dialog box
@Component({
  selector: 'learnMoreDDDataManagement',
  templateUrl: 'learnMoreDDDataManagement.html',
  styleUrls: ['./dataManagement.component.scss']
})
export class LearnMoreDDDataManagement {
  constructor(
    public dialogRef: MatDialogRef<LearnMoreDDDataManagement>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
