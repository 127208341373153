<div class="answerCQs-page background-awesome">
  <div class="md-content" [ngClass]="{'layoutMobP': (responsiveUI.isMobile && responsiveUI.isPortrait), 'layoutMobL': (responsiveUI.isMobile && !responsiveUI.isPortrait),
  'layoutTabP': (responsiveUI.isTablet && responsiveUI.isPortrait), 'layoutTabL': (responsiveUI.isTablet && !responsiveUI.isPortrait), 'layoutDesktop': responsiveUI.isDesktop}">

    <div class="title">
      <h1>Challenge Question Response Page</h1>
    </div>

    <div name="answerhallengeQuestionForm" >
      <div class="question-container">
        <p>Challenge Question 1: {{question1}}</p>
        <mat-form-field appearance='outline'>
          <mat-label>Response 1</mat-label>
          <input matInput 
            [(ngModel)]="response1" 
            name="response1"/>
        </mat-form-field>
      </div>

      <div class="question-container">
        <p>Challenge Question 2: {{question2}}</p>
        <mat-form-field appearance='outline'>
          <mat-label>Response 2</mat-label>
          <input matInput [(ngModel)]="response2" 
          name="response2"/>
        </mat-form-field>
      </div>

      <div class="question-container">
        <p>Challenge Question 3: {{question3}}</p>
        <mat-form-field appearance='outline'>
          <mat-label>Response 3</mat-label>
          <input matInput [(ngModel)]="response3" 
          name="response3"/>
        </mat-form-field>
      </div>

      <div class="action-buttons center">
        <button class="md-button button" (click)="submitForm()" [disabled]="(!response1 || !response2 || !response3)">Submit</button>
      </div>
    </div>

  </div>
  <p class="version-number">{{appVersion}}</p>
</div>
