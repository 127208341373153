import { Component, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { ToastService } from 'src/app/services/toast.service';
import { AppConstants } from 'src/app/config/index.constants';
import { FormBuilder } from '@angular/forms';
import { catchError, of, Subscription, tap } from 'rxjs';

@Component({
    selector: 'app-forgot-password',
    styleUrls: ['./forgot-password.component.scss'],
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnDestroy {
    appVersion: string;
    errors: string[];
    sub = new Subscription();
    form = this.fb.group({
        username: ''
    })
    constructor(private userService: UserService,
      private cookieService: CookieService,
      private router: Router,
      private fb: FormBuilder,
      public responsiveUIService: ResponsiveUIService,
      private toastService: ToastService) {
      
      this.appVersion = AppConstants.appVersion;
      this.errors = [];
    }
  
    submitUsernameForm() {
     this.sub.add(this.userService.selfServicePasswordReset(this.form.get('username').value).pipe(
        tap((data: any) => {
            const cQList = data.challengeQuestions;
            const userName = this.form.get('username').value;
            const encodedUsername = encodeURIComponent(userName);
            this.cookieService.putObject('challengeQuestionsList', cQList);
            this.cookieService.put('isChallengeQuestionSet', data.isChallengeQuestionSet);
            this.cookieService.put('username', encodedUsername);
            this.router.navigate(['answerChallengeQuestions']);
        }),
        catchError((error: any) =>  {
            this.toastService.showToast(error.developerMessage, 0);
            return of()
        })
     ).subscribe());
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
    }
  }
  