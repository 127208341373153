
  
<div class="dashboard-module">
  <div class="dashboard-module-title-background">
    <h3 *ngIf="!responsiveUI.isMobile" class="dashboard-module-title-text-2">User Search</h3>
    <h1 *ngIf="responsiveUI.isMobile" class="dashboard-module-title-text-2">User Search</h1>
  </div>

  <div class="dashboard-module-body layout-padding">
    <div class="student-record-search">
      <p>Ensure that your search contains at least one of the following elements: First Name, Last Name, or Username.</p>
      <button class="clear-button" (click)="clearUserSearchFields()" aria-label="Clear" tabindex="0">
        <i class="fa fa-times" aria-hidden="true"></i>
        <span>Clear</span>
      </button>
    </div>
    <div [formGroup]="form">

      <div [ngClass]="getFormFieldLayout()">
        <div [ngClass]="getNameContainerClass()">
          <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input (keydown.enter)="searchUser()" matInput type="string" formControlName="firstName" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input (keydown.enter)="searchUser()" matInput type="string" formControlName="lastName" />
          </mat-form-field>
        </div>

        <div [ngClass]="getUsernameContainerClass()">
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input (keydown.enter)="searchUser()" matInput type="string" formControlName="username" />
          </mat-form-field>
        </div>
      </div>
      <div class="search-btn">
        <button class="md-button" 
        (click)="searchUser()"
         [disabled]="!form.get('firstName').value?.trim() && !form.get('lastName').value?.trim() && !form.get('username').value?.trim()">
          Search
        </button>
      </div>
    </div>
  </div>
</div>