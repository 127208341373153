import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConstants }  from '../config/index.constants';
import { LoginService } from '../services/login.service';
import { EncoderService } from '../services/encoder.service';
import { UserService } from '../services/user.service';
// import * as angular from 'angular';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router'; 
import { roleClasses } from '../config/index.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  [x: string]: any;
  //private acUserRoles = AppConstants.roleClasses;
  private apiUrl = AppConstants.api;
  private router: string;
  private timeoutFlag: boolean;
  private currUser: any;
  private isRoleAuthorized: {};
  private authRoles: {};
  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private encoderService: EncoderService,
    private userService: UserService,
    private cookieService: CookieService,
    private _router: Router) {
    
    this.router = _router.url;
    this.timeoutFlag = false;
    this.currUser = {
      betaUser: false,
      roles: []
    };
  }

  getAuthRoles() {
    return this.authRoles;
  }

  setAuthRoles(authorizedRoles) {
    this.authRoles = authorizedRoles;
  }
  
  // TODO - Is this being called from anywhere?
  getCurrentUser() {
    console.log('auth.service.getCurrentUser() - Am I being called?');
    // Get current user data.
    this.userService.getCurrentUser().then( data => {
      this.currUser = data;
      console.log(this.currUser);
    });

    return this.currUser;

    /* this.userService.getCurrentUser().then({
      // Callback for success
      next: data => {
        this.currUser = data;
        console.log(data);
        return data;
      },
      error: error => {
        // Callback for failure
        console.error('Error getting current user');
    }
    }); */
  }

  // Close the filter modal
  /*closeDialog() {
    this.$mdDialog.cancel();
  }
  */
  logout(msg) {
    localStorage.removeItem('savedTab');
    // close modal in case of timeout
    //this.closeDialog();
    // close IntroJS tour in case of timeout
    //TODO: Install this._ngIntroService.exit();
    //this._Idle.unwatch();
    this.userService.logout();
    return this.loginService.logout(msg);
  }

  setTimeoutFlag(boolean) {
    this.timeoutFlag = boolean;
  }

  getTimeoutFlag() {
    return this.timeoutFlag;
  }

  // setIdleConfig() {
  //   //starting idle watch only when isAuthenticated is called - any page that requires authentication
  //   this._Idle.setIdle(900); // not considered idle until 900 seconds - 15 minutes
  //   this._Idle.setTimeout(300); // log out after 300 seconds of being idle - 20 minutes
  //   this._Idle.watch();
  // }

  isAuthenticated() {
    if (this.cookieService.getObject('user')) {
      //this.setIdleConfig();
      return true;
    }
    return false;
  }
  
  isAuthorized(authorizedRoles) {
    if (!Array.isArray(authorizedRoles)) {
      authorizedRoles = [authorizedRoles];
    }
    if (!this.isAuthenticated()) {
      return false;
    }
    var roleAuthorized = false;
    
    let o = JSON.parse(this.cookieService.get('user'));
    let roles: string[] = JSON.parse(o.roles);

    for (var role in roles) {
      if (authorizedRoles.indexOf(roles[role]) !== -1) {
        return true;
      }
    }
    return roleAuthorized;
  }

  async isRoleClassAuthorized(roleClassArray): Promise<any> {

    // Get current user data.
    await this.userService.getCurrentUser().then( data => {
      this.currUser = data;
    });

    console.log("Authservice isRoleClassAuthorized(): ", this.currUser);

    this.isRoleAuthorized = await this.isRoleClassAuthorizedHelper(roleClassArray);
    return this.isRoleAuthorized;
  }

  private isRoleClassAuthorizedHelper(roleClassArray) {
    var isAuthorizedForClasses = {};
    for (var roleClass in roleClassArray) {
      var authorizedRoles = roleClasses[roleClassArray[roleClass]];
      if (authorizedRoles) {
        for (var role in this.currUser.roles) {
          if (authorizedRoles.indexOf(this.currUser.roles[role]) !== -1) {
            isAuthorizedForClasses[roleClassArray[roleClass]] = true;
            break;
          }
        }
      }
    }
    return isAuthorizedForClasses;
  }

  isBetaUser(){
    return this.currUser.betaUser;
  }

  init() {}
}
