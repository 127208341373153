import * as d3 from 'd3';
import { Component, Input, SimpleChanges} from '@angular/core';
// import * as angular from 'angular';
import * as _ from 'lodash'

const ChartTypes = Object.freeze({
  GRADHSE: "gradHseChart",
  GRADE11ALG: "grade11AlgChart",
  RECORDSUBMISSION: "recordSubmissionChart",
  ACCOUNTMANAGE: "accountManagementChart"
});

// Make sure that the keys match the ChartTypes constant!
const TooltipHeights = {
  "gradHseChart": 30,
  "grade11AlgChart": 50,
  "recordSubmissionChart": 30,
  "accountManagementChart": 30
};

const TooltipWidths = {
  "gradHseChart": 250,
  "grade11AlgChart": 300,
  "recordSubmissionChart": 200,
  "accountManagementChart": 200
};

@Component({
  selector: 'msix-line-chart',
  templateUrl: './msixLineChart.component.html',
  styleUrls: ['./msixLineChart.component.scss']
})

// Reference the examples housed under d3js.org with questions
export class MsixLineChartComponent {
  // Gets info from Angular bindings, data is coming from the API as a DatabaseWrapper class containing the majority of chart info
  @Input() chart: any;
  @Input() data: any;
  @Input() id: any;

  constructor() {}

  ngOnInit() {
      this.paint();
  }
  // Call the paint function, add listeners for resize or if the chart attribute changes value (filter change)
  
  ngOnChanges(changes: SimpleChanges) {
      window.addEventListener("resize", this.paint);
      this.paint();
  }

  // Function for building the full chart
  paint() {
    // Removes old iterations of the chart in order to allow for resize
    d3.select("#" + this.id).selectAll("*").remove();

    // If there's no data, short circuit method
    if (this.data === undefined)
      return;

    var hoverMap = {};

    // Resolve a bunch of chart constants through functions
    // Including grabbing from the chart data and info stored on front-end
    var margin = resolveMargin(this.chart);
    var height = window.innerHeight / 1.8 > 500 ? window.innerHeight / 1.8 : 500;
    var width = document.getElementById("chart-section").offsetWidth > 700 ? document.getElementById("chart-section").offsetWidth : 700;
    var xValues = resolveXValues(this.data);
    var groupCategories = resolveGroupCategories(this.data);
    var colors = resolveColors(this.chart, groupCategories.length);
    var shapes = resolveShapes(this.chart);
    var legendMap = resolveLegendMap(this.data);
    var data = resolveData(this.chart, this.data);
    var tickCount = resolveTickCount(this.chart);
    var missingValue = resolveMissingValue(this.chart);

    // Build out d3 axis for x and y (contains info about axis, does not render)
    var x = resolveXAxis(this.chart, xValues);
    var y = resolveYAxis(this.chart, data);

    // Maps the colors to their respective line names
    var color = d3.scaleOrdinal()
      .domain(groupCategories)
      .range(colors);

    var shapeScale = d3.scaleOrdinal()
      .domain(groupCategories)
      .range(shapes);

    var xAxis;

    if (this.chart == ChartTypes.RECORDSUBMISSION || this.chart == ChartTypes.ACCOUNTMANAGE || this.chart == ChartTypes.GRADE11ALG) {
      // Render x-axis with YY below MON
      xAxis = g => g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(
            d3.axisBottom(x).ticks(width).tickSizeOuter(0)
        )
        .attr("class", "axis")
        .style("font-family", "Work Sans, sans-serif")
        .style("font-size", "15px")
        .style("color", "#4A4A4A")
        .selectAll(".axis text")
        .call(formatMonths)
        .append("g")
        .attr("class", "grid");
    } else {
      // Render x-axis and wrap if necessary
      xAxis = g => g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(
            d3.axisBottom(x).ticks(width).tickSizeOuter(0)
        )
        .attr("class", "axis")
        .style("font-family", "Work Sans, sans-serif")
        .style("font-size", "15px")
        .style("color", "#4A4A4A")
        .selectAll(".axis text")
        .call(wrap, x.bandwidth())
        .append("g")
        .attr("class", "grid");
    }

    // "Function" to render y-axis
    var yAxis = g => g
      .attr("transform", `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).tickValues(y.ticks().filter(Number.isInteger)).tickFormat(d3.format(",")).ticks(tickCount))
      .attr("class", "axis")
      .style("font-family", "Work Sans, sans-serif")
      .style("font-size", "15px")
      .style("color", "#4A4A4A");

    // Legend "function"
    var legend = svg => resolveLegend(svg, this.chart);

    // Vertical gridlines
    var grid = g => g
      .attr("stroke", "currentColor")
      .attr("class", "grid")
      .attr("stroke-opacity", 0.2)
      .attr("stroke-width", 2)
      // Vertical gridlines are disabeled by the commented out data line
      .call(g => g.append("g")
        .selectAll("line")
        //.data(x)
        .join("line")
        .attr("x1", d => 0.5 + x(d))
        .attr("x2", d => 0.5 + x(d))
        .attr("y1", margin.top)
        .attr("y2", height - margin.bottom))
      // Horizontal gridlines (conntected to above)
      .call(g => g.append("g")
        .selectAll("line")
        .data(y.ticks().filter(Number.isInteger))
        .join("line")
        .attr("y1", d => 0.5 + y(d))
        .attr("y2", d => 0.5 + y(d))
        .attr("x1", margin.left)
        .attr("x2", width - margin.right));

    // D3 line resolving function
    // Grabs data and then accesses x/y values according to specifications, currently coded in line with DashboardInfo API class
    var line = d3.line()
      .defined(d => d.value != missingValue)
      .x(d => x(d.category))
      .y(d => {
          return y(d.value);
      });

    // Define the div for the tooltip
    var div = d3.select("#" + this.id).append("div")
      .attr("class", "linechart-tooltip")
      .style("opacity", 0);

    // The actual svg element of the chart
    var svg = d3.select("#" + this.id).append('svg')
      .attr("class", "dashboard-svg")
      .attr("viewBox", [0, 0, width, height])
      .style("overflow", "visible");


    // Calls the functions from earlier
    svg.append("g")
      .call(xAxis);

    svg.append("g")
      .call(yAxis);

    svg.append("g")
      .call(legend);

    svg.append("g")
      .call(grid);

    // Small screen adjustment (carryover from original lineChart directive)
    var horizShift = 0
    if (window.innerWidth < 1100) {
      horizShift = 50
    }

    // Appending all line data
    var g = svg.append("g");

    // Data is in form of array of arrays
    // Each subarray represents a single line of data (the set)
    for (var set of data) {
      if (set.length == 0)
        continue;

      // Builds out the line (path is flexible svg element)
      g.append("path")
        // .datum(set)
        .attr("fill", "none")
        .attr("stroke", color(set[0].subcategory))
        .attr("stroke-width", 2)
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-dasharray", d => {
          return resolveDashed(this.chart, set[0].subcategory);
        })
        .attr("d", line(set))
        .attr("transform", `translate(${x.bandwidth() / 2} , 0)`);

      if (this.chart == ChartTypes.ACCOUNTMANAGE) { // Account Managment has custom shapes, add new charts with custom shapes here
        svg.append("g")
            .selectAll("path")
            .data(set.filter(iter => iter.value != missingValue))
            .enter()
            .append("path")
            .attr("transform", (d, i) => `translate(${x(d.category) + x.bandwidth() / 2},${y(d.value)})`)
            .attr("fill", d => color(d.subcategory))
            .attr("d", d => d3.symbol().type(shapeScale(d.subcategory))())
            .on("pointerover", (event, d) => { // Tooltip stuff
              if (event.pointerType == "touch") {
                event.preventDefault();
                event.stopPropagation();
                return;
              }

              div.transition()
                .duration(200)
                .style("opacity", .9);

              div.html(resolveHover(this.chart, d))
                .style("left", (event.pageX) + "px")
                .style("top", (event.pageY) + "px")
                .style("height", TooltipHeights[this.chart] * hoverMap[d.category][d.value].length + "px")
                .style("width", TooltipWidths[this.chart] + "px")
                .style("position", "absolute") // had to add styles from "linechart-tooltip" class to here specifically, class wasnt being applied
                .style("text-align", "center")
                .style("font", "12px sans-serif")
                .style("border", "0px")
                .style("padding", "2px")
                .style("background", "lightsteelblue")
                .style("border-radius", "8px")
                .style("pointer-events", "none");
            })
            .on("pointerout", (event, d) => {
              if (event.pointerType == "touch") {
                event.preventDefault();
                event.stopPropagation();
                return;
              }

              div.transition()
                .duration(500)
                .style("opacity", 0);
            })
            .on('pointermove', (event, d) => {
              if (event.pointerType == "touch") {
                event.preventDefault();
                event.stopPropagation();
                return;
              }

              div.style('top', (event.pageY - 10 - TooltipHeights[this.chart] * hoverMap[d.category][d.value].length) + 'px')
                .style('left', (event.pageX - horizShift - 100) + 'px');
            })
            .on("touchend touchstart", (event, d) => {
              event.preventDefault();
            });
      } else {
        // Adds a dot at each vertex of the line, no custom shapes
        svg.append("g")
          .selectAll("dot")
          .data(set.filter(iter => iter.value != missingValue))
          .enter()
          .append("circle")
          .attr("cx", (d, i) => x(d.category) + x.bandwidth() / 2)
          .attr("cy", d => y(d.value))
          .attr("r", 5)
          .attr("fill", d => color(d.subcategory))
          // Add the hover on each dot
          .on("pointerover", (event, d) => {
            if (event.pointerType == "touch") {
              event.preventDefault();
              event.stopPropagation();
              return;
            }

            div.transition()
              .duration(200)
              .style("opacity", .9);

            div.html(resolveHover(this.chart, d))
              .style("left", (event.pageX) + "px")
              .style("top", (event.pageY) + "px")
              .style("height", TooltipHeights[this.chart] * hoverMap[d.category][d.value].length + "px")
              .style("width", TooltipWidths[this.chart] + "px")
              .style("position", "absolute") // had to add styles from "linechart-tooltip" class to here specifically, class wasnt being applied
              .style("text-align", "center")
              .style("font", "12px sans-serif")
              .style("border", "0px")
              .style("padding", "2px")
              .style("background", "lightsteelblue")
              .style("border-radius", "8px")
              .style("pointer-events", "none");
        })
        .on("pointerout", (event, d) => {
          if (event.pointerType == "touch") {
            event.preventDefault();
            event.stopPropagation();
            return;
          }

          div.transition()
            .duration(500)
            .style("opacity", 0);
        })
        .on('pointermove', (event, d) => {
          if (event.pointerType == "touch") {
            event.preventDefault();
            event.stopPropagation();
            return;
          }

          div.style('top', (event.pageY - 10 - TooltipHeights[this.chart] * hoverMap[d.category][d.value].length) + 'px')
            .style('left', (event.pageX - horizShift - 100) + 'px');
        })
        .on("touchend touchstart", (event, d) => {
          event.preventDefault();
        });
      }
    }

    // Y Axis Label
    svg.append("text")
      .attr("class", "axis")
      .attr("transform", "rotate(-90)")
      .attr("y", -5)
      .attr("x", -((height - margin.bottom + margin.top) / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-family", "Work Sans, sans-serif")
      .style("font-size", "15px")
      .style("color", "#4A4A4A")
      .text(this.data.y);

    // X Axis Label
    svg.append("text")
      .attr("class", "axis")
      .attr("y", height - margin.bottom + 40)
      .attr("x", width / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-family", "Work Sans, sans-serif")
      .style("font-size", "15px")
      .style("color", "#4A4A4A")
      .text(this.data.x);

    function resolveLegend(svg, chartType) {
      const g = svg
        .attr("transform", resolveLegendTransformation(chartType))
        .attr("text-anchor", "start")
        .attr("class", "legend")
        .style("font-family", "Work Sans, sans-serif")
        .style("font-size", "16px")
        .style("color", "#4A4A4A")
        .selectAll("g")
        .data(color.domain())
        .join("g")
        .attr("transform", (d, i) => resolveLegendSetTransformation(chartType, i));

      g.append("text")
        .attr("x", 0)
        .attr("y", 9.5)
        .attr("dy", "0.35em")
        .text(d => legendMap[d])
        .call(wrap, (width - margin.left) * .8);

      if (chartType == ChartTypes.RECORDSUBMISSION) { // Record submission uses colored squares instead of lines
        g.append("rect")
          .attr("x", -25)
          .attr("width", 19)
          .attr("height", 19)
          .attr("fill", color);
      } else {

        g.append("line")
          .attr("x1", -40)
          .attr("x2", -10)
          .attr("y1", 10)
          .attr("y2", 10)
          .attr("stroke-width", 2)
          .style("stroke-dasharray", d => {
            return resolveDashed(chartType, d);
          })
          .attr("stroke", color);

        if (chartType == ChartTypes.ACCOUNTMANAGE) {
          g.append("g")
            .append("path")
            .attr("transform", d => `translate(${-25},${10})`)
            .attr("fill", color)
            .attr("d", d => {
              return d3.symbol().type(shapeScale(d))();
            });
        }
      }

    }

    // Return array of hex strings that represent colors
    function resolveColors(chartType, numberSeries) {
      if (chartType == ChartTypes.GRADHSE) {
        if (numberSeries == 2) {
          return ["#112E51", "#F74B00"];
        }
        return ["#112E51", "#112E51", "#F74B00", "#F74B00"];
      }
      if (chartType == ChartTypes.GRADE11ALG)
        return ["#112E51", "#2CA02C"];

      if (chartType == ChartTypes.RECORDSUBMISSION)
        return ["#2CA02C", "#77113F"];

      if (chartType == ChartTypes.ACCOUNTMANAGE)
        return ["#313443", "#F74C00", "#77113F", "#2CA02C"];
    }

    function resolveShapes(chartType) {
      if (chartType == ChartTypes.ACCOUNTMANAGE) {
        return [d3.symbolTriangle, d3.symbolSquare, d3.symbolStar, d3.symbolCircle];
      }
      return [];
    }

    // Return object representing margin information
    function resolveMargin(chartType) {
      if (chartType == ChartTypes.GRADHSE) {
        return { top: 20, right: 30, bottom: 170, left: 50 };
      }
      if (chartType == ChartTypes.GRADE11ALG)
        return { top: 20, right: 30, bottom: 170, left: 80 };
      if (chartType == ChartTypes.RECORDSUBMISSION)
        return { top: 30, right: 30, bottom: 150, left: 80 };
      if (chartType == ChartTypes.ACCOUNTMANAGE)
        return { top: 20, right: 30, bottom: 170, left: 80 };

      return { top: 20, right: 30, bottom: 170, left: 80 };
    }

    // Check if line is supposed to be dashed, return 0 (not dashed) if not
    // Currently only Grad/HSE chart requires dashed, and only on national lines
    function resolveDashed(chartType, d) {
      if (chartType == ChartTypes.GRADHSE) {
        if (d != undefined && d.includes("national")) {
          return "6";
        }
      }

      return "0";
    }

    // Resolve the x-values from the API and build out the hover map so that the hover can be accessed for all points when value is equal
    function resolveXValues(fullData) {
      for (let x of fullData.xValues) {
        hoverMap[x] = {};
      }
      return fullData.xValues;
    }

    function resolveGroupCategories(fullData) {
      return fullData.groupCategories;
    }

    function resolveData(chartType, fullData) {
      let adjustedData = _.cloneDeep(fullData.data);
      for (let row of adjustedData) {
        for (let point of row) {
          point.value = Number(point.value); // Convert String to Number

          if (point.value in hoverMap[point.category])
            hoverMap[point.category][point.value].push(point);
          else
            hoverMap[point.category][point.value] = [point];
        }
      }
      return adjustedData;
    }

    function resolveLegendMap(fullData) {
      return fullData.legendMap;
    }

    // Returns an html string that will be used for the hover display
    // Hover map is needed to allow the hover to include all data that is at the same point
    function resolveHover(chartType, d) {
      let htmlReturn = "";

      if (chartType == ChartTypes.GRADHSE) {
        for (let x of hoverMap[d.category][d.value]) {
          htmlReturn += legendMap[x.subcategory] + " " + x.category + ": " + d3.format(".2f")(x.value) + "%<br/><br/>";
        }
        return htmlReturn;
      }

      if (chartType == ChartTypes.GRADE11ALG) {
        for (let x of hoverMap[d.category][d.value]) {
          htmlReturn += legendMap[x.subcategory] + "<br/>" + x.category + ": " + x.value + "<br/><br/>";
        }
        return htmlReturn;
      }

      if (chartType == ChartTypes.RECORDSUBMISSION || chartType == ChartTypes.ACCOUNTMANAGE) {
        for (let x of hoverMap[d.category][d.value])
          htmlReturn += legendMap[x.subcategory] + ": " + x.value + "<br/><br/>";
        return htmlReturn;
      }

    }

    // Places legend in space in the svg
    function resolveLegendTransformation(chartType) {
      if (chartType == ChartTypes.RECORDSUBMISSION)
        return `translate(${margin.left + 25},${height - margin.bottom / 2})`;
      return `translate(${margin.left + 50},${height - margin.bottom / 1.6})`;
    }

    // Resolves how each item in the legend is rendered (currently all in a vertical column, with changes to the y-coordinate)
    function resolveLegendSetTransformation(chartType, i) {
      if (chartType == ChartTypes.GRADHSE)
        return `translate(${0},${i * 20})`;
      if (chartType == ChartTypes.GRADE11ALG)
        return `translate(${0},${i * 30})`;
      if (chartType == ChartTypes.RECORDSUBMISSION)
        return `translate(${0},${i * 25})`;

      return `translate(${0},${i * 15})`;
    }

    // Encapsulate x-axis for later use
    function resolveXAxis(chartType, xVals) {
      return d3.scaleBand()
        .domain(xVals)
        .range([margin.left, width - margin.right]);
    }

    // Encapsulate y-axis for later use
    function resolveYAxis(chartType, data) {
      if (chartType == ChartTypes.GRADHSE) {
        return d3.scaleLinear()
          .domain([0, 100])
          .nice(tickCount)
          .range([height - margin.bottom, margin.top]);
    }
    else {
      return d3.scaleLinear()
        .domain([0,
            Math.max(d3.max(data, d => {
                return d3.max(d, subD => subD.value);
            }) * 1.1, 10)])
        .nice(tickCount)
        .range([height - margin.bottom, margin.top]);
      }

    }

    // Line up the student achievement charts when displayed horizontally
    function resolveTickCount(chartType) {
      if (chartType == ChartTypes.GRADE11ALG || chartType == ChartTypes.GRADHSE)
        return 10;
      return;
    }

    function resolveMissingValue(chartType) {
      return -1;
    }

    // Wraps text utilizing tspans
    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this);
        var words = text.text().split(/\s+/).reverse();
        var word = words.pop();
        var line = [];
        var lineNumber = 0;
        var lineHeight = 1; // ems
        var y = text.attr("y");
        var dy = parseFloat(text.attr("dy"));
        var tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
        while (word != undefined) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
          }
          word = words.pop();
        }
      });
    }

    function formatMonths(text) {
      text.each(function () {
        var text = d3.select(this);
        var words = text.text().split(/\s+/).reverse();
        var word = words.pop();
        // var line = [];
        var lineNumber = 0;
        var lineHeight = 1; // ems
        var y = text.attr("y");
        var dy = parseFloat(text.attr("dy"));
        text.text(null);
        while (word != undefined) {
          text.append("tspan").attr("x", 0).attr("y", y).attr("dy", lineNumber++ * lineHeight + dy + "em").text(word);
          word = words.pop();
        }
      });
    }

  }
}