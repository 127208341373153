<div class="background-awesome">
    <div class="form-wrapper">
        <h1>Forgot your password?</h1>
        <p>Please enter your username then click Submit.</p>
        <p>If you have forgotten your username, please contact your designated State Contact for support. </p>
    
        <form [formGroup]="form" (ngSubmit)="submitUsernameForm()">
            <mat-form-field appearance="outline">
                <mat-label>Username</mat-label>
                <input type="text" matInput formControlName="username">
            </mat-form-field>
        </form>
        <button class="md-button" type="submit" (click)="submitUsernameForm()" [disabled]="!form.get('username').value">Submit</button>
    </div>
    <p class="version-number">{{appVersion}}</p>
</div>
    