<div class="login-page background-awesome layout-flex-row"  >
  <div *ngIf="!verifyUserUsingCQA" class="msix-login" [formGroup]="loginForm">
    <div class="mfaBanner"
      *ngIf="!loginForm.get('isMFA').value && loginForm.get('showRegistrationDecision').value && mfaSetupStep ==''">
      Multi-Factor Authentication is now mandatory in MSIX.
      Please configure an MFA application to access MSIX.
      Contact the MSIX Help Desk at 1-866-878-9525 if you need assistance.
    </div>
    <div class="login-section"
      *ngIf="!loginForm.get('isMFA').value && loginForm.get('showRegistrationDecision').value && mfaSetupStep ==''">
      <div class="form">
        <div class="title">
          <h2>Migrant Student Information Exchange</h2>
        </div>

        <div *ngIf="errors.length > 0">
          <div class="errors flex-inline" *ngFor="let error of errors">
            <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
            <p class="validation">{{ error }}</p>
          </div>
        </div>

        <div class="success flex-inline" *ngIf="successFlag">
          <i class="fa fa-check-circle" aria-hidden="true"></i>&nbsp;
          <p class="successP">Your password has been successfully reset.</p>
        </div>

        <div  class="login-form">
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input (keydown.enter)="submitForm()" matInput type="string" formControlName="username" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input (keydown.enter)="submitForm()" matInput type="password" formControlName="password" />
          </mat-form-field>
          <div class="action-buttons">
            <button #signInButton (click)="submitForm('login')" type="submit" class="md-button" [disabled]="!loginForm.get('username').value || !loginForm.get('password').value">
              Sign In</button>
            <a tabindex="0" ng-keypress="goToForgotYourPassword()" (click)="goToForgotYourPassword()"
              class="loginHelpButton"><span>Forgot Your Password?</span></a>
            <a tabindex="0" ng-keypress="goToStateContactSearch()" (click)="goToStateContactSearch()"
              class="loginHelpButton"><span>State Contact Search</span></a>
          </div>
        </div>
      </div>
      <div class="center hillsCenter">
        <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills" />
      </div>

    </div>

    <div *ngIf="loginForm.get('isMFA').value || mfaSetupStep != ''">
      <!--MFA code entry form-->
      <div class="mfa-login" *ngIf="loginForm.get('isMFA').value && !loginForm.get('isRegistrationWorkflow').value && !verifyUserUsingCQA">
        <div  class="mfa-login-wrapper">
          <div class="title">
            Verify your login to MSIX
          </div>

          <div class="subTitle">
            Enter the six-digit code displayed in your authenticator app
          </div>

          <mat-form-field appearance="outline">
            <mat-label>MFA Code</mat-label>
            <input matInput type="text" formControlName="mfaCode">
          </mat-form-field>

          <div class="center layout-flex-row">
            <a tabindex="0" class="loginHelpButton" (click)="configureNewDeviceAfterUnamePwdLogin()">
              <span>Reset Multi-Factor Authentication</span>
            </a>
          </div>

          <div class="center layout-flex-row">
            <button (click)="submitMFA($event)" class="md-button" [disabled]="(!loginForm.get('mfaCode').value) || attempts >= 3">Submit</button>
          </div>

          <div *ngIf="invalidMFACode">
            <div class="errors flex-inline" *ngFor="let error of errors">
              <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
              <p class="mfavalidation">The code could not be verified.<br />
                {{ error }} <br />
              </p>
            </div>
          </div>
        </div>
        <div class="center hillsCenter">
          <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills" />
        </div>
      </div>

      <!-- Registration decision form -->
      <div class="mfa-login-decision"  *ngIf="mfaSetupStep === 'default'"> 
        <div name="mfaRegistrationDecision" class="mfa-login-form" >
          <div class="title">
            MSIX Multi Factor Authentication Setup
          </div>
          <div class="subTitle">
            Configure your MSIX account <br />
            to use a Time-Based One-Time <br />
            Password (OTP) from an Authenticator Application.
          </div>

          <div class="action-btn-mfa">
            <button class="md-button" (click)="mfaSetupNext('download')">Next</button>
            <a *ngIf="mfaSkipAllowed" tabindex="0" ng-keypress="mfaSetupSkipOrBack('skip')" 
              (click)="mfaSetupSkipOrBack('skip')" class="loginHelpButton"><span>Skip for now</span></a>
          </div>
        </div>
        <div class="center hillsCenter">
          <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills">
        </div>
      </div>

      <!-- MFA Registration - Download app form -->
      <div class="mfa-register" *ngIf="loginForm.get('isRegistrationWorkflow').value && mfaSetupStep === 'download'" 
      [ngClass]="{'mobile-portrait-width': (responsiveUI.isMobile && responsiveUI.isPortrait)}">
        <div>
          <div layout="column">
            <div layout="row" layout-align="center center" class="mfa-breadcrumbs icon-row">
              <i class="fa fa-circle statusCircleCurrent breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-circle-thin breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-circle-thin breadcrumb-icons"></i>
            </div>
            <div layout="row" layout-align="center center" class="mfa-breadcrumbs">
              <p class="skipDisclaimer breadcrumb-stages">Download App</p>
              <p class="skipDisclaimer breadcrumb-stages">Configure</p>
              <p class="skipDisclaimer breadcrumb-stages">Verify</p>
            </div>
          </div>
          <div class="title" style="padding-bottom: 15px;">
            Download Authenticator Application
          </div>
          <div class="skipDisclaimer" style="padding: 0px 13px;">
            To utilize Multi-Factor Authentication (MFA)
            to login to MSIX, an authenticator app must be
            downloaded to your device. Supported devices
            include PCs (Windows and MacOS), Mobile (Android
            and iOS), and web browsers such as Firefox and
            Google Chrome. </div>

          <div class="skipDisclaimer">
            Download one of the four authenticators listed below: <br /><br />
            Google Authenticator
            (<a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US">Android</a>
            and <a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/google-authenticator/id388497605">iOS</a>) <br />
            <br />
            Microsoft Authenticator
            (<a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US&gl=US">Android</a>
            and <a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458">iOS</a>) <br />
            <br />
            Authy
            (<a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://authy.com/download/">Windows</a>,
            <a class="authLink" target="_blank" rel="noopener noreferrer" href="https://authy.com/download/">MacOS</a>,
            <a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en_US&gl=US">Android</a>
            and <a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/authy/id494168017">iOS</a>) <br />
            <br />
            Authenticator
            (<a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en">Chrome</a>
            and
            <a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://addons.mozilla.org/en-US/firefox/addon/auth-helper/">Firefox</a> Browser Extensions)<br />
          </div>

          <div class="skipDisclaimer" style="padding: 0px 13px;">
            After the authenticator application is installed on your device, select the next button to proceed with the
            configuration.<br />
          </div>
          <div class="skipDisclaimer_underline" style="padding: 0px 13px;">
            If you need assistance, please reach out to the MSIX Help Desk at 1-866-878-9525.</div>

          <div class="action-buttons">
            <button (click)="mfaSetupNext('register')" class="md-button">Next
            </button>
            <a tabindex="0" ng-keypress="mfaSetupSkipOrBack('default')" (click)="mfaSetupSkipOrBack('default')"
              class="loginHelpButton"><span>Back</span></a>
          </div>
          <div style="padding-top: 20px;" class="center hillsCenter">
            <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills">
          </div>
        </div>
      </div>

      <!-- MFA Registration - MFA Rest Warning Page-->
      <div class="mfa-confirmReset" *ngIf="loginForm.get('isRegistrationWorkflow').value && mfaSetupStep === 'confirmReset'">
        <div class="title">
          Are you sure?
        </div>

        <div class="subTitle">
          Selecting next will remove your current MFA configuration and allow you to configure a
          new authenticator application.
        </div>
        <br />
        <div class="action-buttons">
          <button type="submit" class="md-button" (click)="mfaSetupNext('reset')">Next
          </button>
          <a tabindex="0" ng-keypress="mfaSetupSkipOrBack('mfaEntry')" (click)="mfaSetupSkipOrBack('mfaEntry')"
            class="loginHelpButton"><span>Back</span></a>
        </div>
        <div class="center hillsCenter">
          <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills">
        </div>
      </div>

      <!-- MFA Registration - QR Code form-->
      <div class="mfa-register" *ngIf="loginForm.get('isRegistrationWorkflow').value && mfaSetupStep === 'register'" [ngClass]="{'mobile-portrait-width': (responsiveUI.isMobile && responsiveUI.isPortrait)}">
        <div name="mfaRegistrationQRCode">
          <div layout="column">
            <div layout="row" layout-align="center center" class="mfa-breadcrumbs icon-row">
              <i class="fa fa-check-circle statusCircleComplete breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-circle statusCircleCurrent breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-circle-thin breadcrumb-icons"></i>
            </div>
            <div layout="row" layout-align="center center" class="mfa-breadcrumbs">
              <p class="skipDisclaimer breadcrumb-stages">Download App</p>
              <p class="skipDisclaimer breadcrumb-stages">Configure</p>
              <p class="skipDisclaimer breadcrumb-stages">Verify</p>
            </div>
          </div>
          <div class="title" style="padding-bottom: 15px;">
            Configure Authenticator Application
          </div>

          <div class="subTitle">
            Scan the image below.
          </div>
          <div>
            <!--img data-ng-src="{{qrCode}}" alt="QR Code" class="qrCode"-->
            <img [src]="qrCode" alt="QR Code" class="qrCode">
          </div>
          <div class="subTitle">
            If you are unable to scan the image, enter
            the following information in your app. </div>
          <div class="skipDisclaimer">
            {{ secretKey }}
          </div>
          <div class="subTitle">If the app displays a six-digit code, it has been configured!</div>

          <br />

          <div class="subTitle">
            For more detailed steps to configure your
            authenticator app, please select the link
            for the authenticator you downloaded:
          </div>

          <br />

          <div layout="row" class="center">
            <a tabindex="0" target="_blank" rel="noopener noreferrer"
              href="assets/trainingCorner/resources/msixguides/Google_Authenticator_Configuration_Aid.pdf">
              <span class="removeUnderline">Google Authenticator </span></a>
            <a tabindex="0" target="_blank" rel="noopener noreferrer"
              href="assets/trainingCorner/resources/msixguides/Microsoft_Authenticator_Configuration_Aid.pdf">
              <span class="removeUnderline">Microsoft Authenticator </span></a>
            <a tabindex="0" target="_blank" rel="noopener noreferrer"
              href="assets/trainingCorner/resources/msixguides/Authy_Configuration_Aid.pdf">
              <span class="removeUnderline"> Authy </span></a>
            <a tabindex="0" target="_blank" rel="noopener noreferrer"
              href="assets/trainingCorner/resources/msixguides/Authenticator_Browser_Extension_Configuration_Steps_Aid.pdf">
              <span class="removeUnderline"> Authenticator </span></a>
          </div>

          <div class="skipDisclaimer_underline">If you need assistance, please reach out to the MSIX Help Desk at
            1-866-878-9525.</div>
          <div layout="row" class="action-buttons">
            <button (click)="mfaSetupNext('verify')" class="md-button">Next</button>
            <a tabindex="0" ng-keypress="mfaSetupSkipOrBack('download')" (click)="mfaSetupSkipOrBack('download')"
              class="loginHelpButton"><span>Back</span></a>
          </div>
          <div class="center hillsCenter">
            <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills">
          </div>
        </div>
      </div>

      <!-- MFA Registration - Verify QR Code form -->
      <div class="mfa-login qr-code" *ngIf="loginForm.get('isRegistrationWorkflow').value && mfaSetupStep === 'verify'">
        <div>
          <div layout="row" layout-align="center center" class="mfa-breadcrumbs icon-row">
            <i class="fa fa-check-circle statusCircleComplete breadcrumb-icons"></i>
            <hr class="thinHr breadcrumb-dashes">
            <i class="fa fa-check-circle statusCircleComplete breadcrumb-icons"></i>
            <hr class="thinHr breadcrumb-dashes">
            <i class="fa fa-circle statusCircleCurrent breadcrumb-icons"></i>
          </div>
          <div layout="row" layout-align="center center" class="mfa-breadcrumbs">
            <p class="skipDisclaimer breadcrumb-stages">Download App</p>
            <p class="skipDisclaimer breadcrumb-stages">Configure</p>
            <p class="skipDisclaimer breadcrumb-stages">Verify</p>
          </div>
        </div>
        <div class="title">
          Verify Authenticator <br> Application
        </div>
        <div class="skipDisclaimer">
          Enter the six-digit code displayed on your app
        </div>
        <mat-form-field appearance="outline">
          <mat-label [ngClass]="{'label-error' : invalidMFACode}">MFA Code</mat-label>
          <input matInput type="text" formControlName="mfaCode"
            [ngClass]="{'input-error' : invalidMFACode,'mat-input-has-value': (!invalidMFACode && loginForm.get('mfaCode').value)}">
        </mat-form-field>

        <div class="errors flex-inline" *ngIf="invalidMFACode">
          <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
          <p class="mfavalidation">The code could not be verified.<br />
            Please enter a new code from the authenticator application.
          </p>
        </div>

        <div class="action-buttons">
          <button (click)="mfaSetupNext('confirmRegistration')" [disabled]="(!loginForm.get('mfaCode').value)" class="md-button">Next</button>
          <a tabindex="0" ng-keypress="mfaSetupSkipOrBack('register')" (click)="mfaSetupSkipOrBack('register')"
            class="loginHelpButton"><span>Back</span></a>
        </div>
        <div class="center hillsCenter">
          <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills">
        </div>
      </div>

      <!-- MFA Registration - Success form -->
      <div class="mfa-login" *ngIf="loginForm.get('isRegistrationWorkflow').value && mfaSetupStep === 'success'">
        <form name="mfaRegistrationSuccess" (ngSubmit)="mfaSetupNext('done')">
          <div class="layout-flex-column">
            <div layout-align="center center" class="mfa-breadcrumbs icon-row layout-flex-row">
              <i class="fa fa-check-circle statusCircleComplete breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-check-circle statusCircleComplete breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-check-circle statusCircleComplete breadcrumb-icons"></i>
            </div>
            <div layout-align="center center" class="mfa-breadcrumbs layout-flex-row">
              <p class="skipDisclaimer breadcrumb-stages">Download App</p>
              <p class="skipDisclaimer breadcrumb-stages">Configure</p>
              <p class="skipDisclaimer breadcrumb-stages">Verify</p>
            </div>
          </div>
          <div class="title">
            Success!
          </div>
          <div class="skipDisclaimer">
            Your account is configured to use Multi-Factor Authentication.
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>

          <div>
            <div class="center layout-flex-row">
              <button type="submit" class="md-button">
                Done
              </button>
            </div>
          </div>
        </form>
      </div>

     

    </div>
  </div>

   <!-- Verify user using challenge auestions and answers -->
  <div *ngIf="verifyUserUsingCQA" class="challenge-questions">
    <div class="mfaCqa-section">
      <div class="center" style="margin: 5px;">
        <p class="center">Challenge Question 1: {{ challengeQuestions?.[0].question }}</p>
        <mat-form-field appearance="outline">
          <mat-label>Response 1</mat-label>
          <input matInput [(ngModel)]="response[0]" name="response1">
        </mat-form-field>
        <p class="mfaCQA-section-questions-padding">Challenge Question 2: {{ challengeQuestions?.[1].question }}</p>
        <mat-form-field appearance="outline">
          <mat-label>Response 2</mat-label>
          <input matInput [(ngModel)]="response[1]" name="response2">
        </mat-form-field>
        <p class="mfaCQA-section-questions-padding">Challenge Question 3: {{ challengeQuestions?.[2].question }}</p>
        <mat-form-field appearance="outline">
          <mat-label>Response 3</mat-label>
          <input matInput [(ngModel)]="response[2]" name="response3">
        </mat-form-field>
      </div>
      <div class="action-buttons">
        <button  class="md-button" (click)="submitToVerifyChallengeQAndAToVerifyUser()" [disabled]="(!response[0] || !response[1] || !response[2])">
          Submit
        </button>
      </div>

      <div style="padding-top: 30px;" class="center hillsCenter">
        <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills">
      </div>
    </div>
  </div>

</div>