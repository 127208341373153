import { Component, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { AppConstants } from 'src/app/config/index.constants';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'childMobility',
  templateUrl: './childMobility.component.html',
  styleUrls: ['./childMobility.component.scss']
})
export class ChildMobilityComponent {
  toPrevId: string = "toPrev";
  toPrevChart: string = "toPrevChart";
  toCurrId: string = "toCurr";
  toCurrChart: string = "toCurrChart";
  fromPrevId: string = "fromPrev";
  fromPrevChart: string = "fromPrevChart";
  fromCurrId: string = "fromCurr";
  fromCurrChart: string = "fromCurrChart";
  qadEnrollId: string = "qadEnroll";
  qadEnrollChart: string = "qadEnrollChart";
  chart: string = "moveToMap";
  mobilityMapExportCall: string = "mobilityMapExport";
  qadEnrollmentsExportCall: string = "qadEnrollmentsExport";
  private appConst = AppConstants;
  private userStateName: string;
  private childMobilityFilterParams: any = {};
  private activeChildMobilityFilter: any = {};
  private toPrevData: any = {};
  private toCurrData: any = {};
  private fromPrevData: any = {};
  private fromCurrData: any = {};
  private qadEnrollData: any = {};
  private drillDown: any;
  drillDownInfo: any = {};
  showDrill: boolean;
  private ua: string;
  private activeTab: string;
  private periodText: string;
  private reportGroupVar: string;
  private reportDrillDownVar: string;
  private blankRes: boolean;
  private tabChangeDone: boolean;
  private drillDownData: any;
  private roleClasses: any[];
  private authRoleClasses: any;
  private dateObj: any;
  private displayData: any;
  private mapExport: any;
  private toPrevExport: any = {};
  private toCurrExport: any = {};
  private fromPrevExport: any = {};
  private fromCurrExport: any = {};
  private mobilityMapDataHasLoaded: boolean;
  private hideMobilityMap: boolean;
  private qadEnrollmentsDataHasLoaded: boolean;
  private hideQadEnrollments: boolean;
  private dataHasLoaded: boolean;
  private lastUpdated: any;
  sortAscending: boolean = true;
  currentSortColumn: string = 'column1';
  showFilter: boolean;
  constructor(
    public dialog: MatDialog,
    public responsiveUI: ResponsiveUIService,
    private cookieService: CookieService,
    private reportsService: ReportsService,
    private reportInfoService: ReportInfoService,
    private authService: AuthService,
    private toastService: ToastService) {
      this.showDrill = false;
      this.ua = navigator.userAgent;
      this.reportGroupVar = 'studentInfo';
      this.reportDrillDownVar = 'ChildMobilityDrilldown';
      this.activeTab = 'totalLogins';
      this.periodText = '';
      this.blankRes = true;
      this.tabChangeDone = false;
      this.mobilityMapDataHasLoaded = false;
      this.hideMobilityMap = false;
      this.qadEnrollmentsDataHasLoaded = false;
      this.hideQadEnrollments = false;
      this.dataHasLoaded = true;
      this.lastUpdated = new Date();
      this.showFilter = true;
      this.dateObj = {
        current: '',
        recent: '',
        currentStartYear: '',
        currentEndYear: '',
        recentStartYear: '',
        recentEndYear: ''
      };
      this.displayData = {
        current: "",
        recent: "",
        state: "",
        recordName: ""
      };
      this.authRoleClasses = {};
      this.drillDown = {
        beginRow: 1,
        endRow: 20,
        sortField: "MONTH_DESC",
        stateName: this.childMobilityFilterParams.selectedState,
        performancePeriod: this.childMobilityFilterParams.periodType,
        chartType: 'accountManagement',
        tab: this.activeTab
      };
      this.roleClasses = [
        'dataDashboard_studentAchievement_with_sua',
        'dataDashboards',
        'dataAdmins'
      ];
  }

  ngOnInit() {
    this.getReportInfo();
  }

  goToReport() {
    this.showDrill = false;
  }

  //Gets info needed to display the filter: list of states, user's state, both performance periods, and the authorized role classes
  async getReportInfo() {
    let reportInfo = await this.reportInfoService.getReportInfo(this.roleClasses);

    console.log("ChildMobility.getReportInfo(): ", reportInfo);
    this.userStateName = reportInfo.userStateName;
    this.dateObj.current = reportInfo.currentPeriod;
    this.dateObj.recent = reportInfo.recentPeriod;
    this.authRoleClasses = reportInfo.authRoleClasses;
    if (reportInfo.authRoleClasses.dataAdmins) {
      console.log("reportInfo.authRoleClasses.dataAdmins: ", reportInfo.authRoleClasses.dataAdmins);
      this.childMobilityFilterParams = {
        rptGrping: 'State',
        selectedState: this.userStateName,
        states: []
      };
    } else {
      console.log("reportInfo.authRoleClasses.dataAdmins else: ", reportInfo.authRoleClasses.dataAdmins);
      this.childMobilityFilterParams = {
        rptGrping: 'National',
        selectedState: "",
        states: []
      };
    }
    this.setStatesArray(reportInfo.stateData);

    //Generate the report automatically if in mobile mode or if a data administrator
    //Otherwise open the filter dialog
    if (this.responsiveUI.isMobile || this.authRoleClasses.dataAdmins) {
      this.activeChildMobilityFilter = cloneDeep(this.childMobilityFilterParams);
      this.submitFilter();
    } else {
      this.filterActivate();
    }
  }

  // Trigger Filter Modal
  filterActivate() {    
    this.showFilter = true;
    const dialogRef = this.dialog.open(FilterChildMobility, {
      height: 'auto',
      width: '50%',
      minHeight: '250px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: {params: this.childMobilityFilterParams},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.activeChildMobilityFilter = result;
        this.childMobilityFilterParams = result;
        this.submitFilter();
        this.showFilter = false;
      }
    });
  }

  submitFilter() {
    this.mobilityMapDataHasLoaded = false;
    this.qadEnrollmentsDataHasLoaded = false;
    this.hideMobilityMap = false;
    this.hideQadEnrollments = false;
    if (!this.childMobilityFilterParams.selectedState || this.childMobilityFilterParams.selectedState == undefined){
      this.childMobilityFilterParams.selectedState = '';
    }
    let apiCall = this.reportsService.getChildMobilityFilter(this.childMobilityFilterParams.selectedState);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.childMobilityFilterParams = cloneDeep(this.activeChildMobilityFilter);

        console.log("ChildMobility.submitFilter(): ", data);
        this.toPrevData = data.moveToPrevious;
        this.toCurrData = data.moveToCurrent;
        this.fromPrevData = data.moveFromPrevious;
        this.fromCurrData = data.moveFromCurrent;
        this.qadEnrollData = data.qadEnroll;
        this.dataHasLoaded = true;
        this.mobilityMapDataHasLoaded = true;
        this.qadEnrollmentsDataHasLoaded = true;

        //Set export objects
        if (this.childMobilityFilterParams.selectedState != '') {
          this.toPrevExport = {
            stateName: this.childMobilityFilterParams.selectedState,
            performancePeriod: 'PREVIOUS',
            direction: 'TO'
          };
          this.toCurrExport = {
            stateName: this.childMobilityFilterParams.selectedState,
            performancePeriod: 'CURRENT',
            direction: 'TO'
          };
          this.fromPrevExport = {
            stateName: this.childMobilityFilterParams.selectedState,
            performancePeriod: 'PREVIOUS',
            direction: 'FROM'
          };
          this.fromCurrExport = {
            stateName: this.childMobilityFilterParams.selectedState,
            performancePeriod: 'CURRENT',
            direction: 'FROM'
          };
        }
        subscribed.unsubscribe();
        this.setLastUpdated();

        /*if (this.toPrevData.data[0].length == 0  && this.toCurrData.data[0].length == 0
          && this.fromPrevData.data[0].length == 0 && this.fromCurrData.data[0].length == 0) {
          this.hideMobilityMap = true;
        }*/
        if (this.qadEnrollData.data.length == 0) {
          this.hideQadEnrollments = true;
        }
      },
      error: error => {
        console.log("Error - ChildMobility.submitFilter()", error);
        this.dataHasLoaded = true;
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  setLastUpdated() {
    this.reportsService.getFirstDayOfMonth().subscribe({
      next: data => {
        this.lastUpdated = data.date;
      },
      error: error => {
        console.log("Error - ChildMobility.setLastUpdated(): ", error);
      }
    });
  }

  // removes DC, PR, and WY from states array and sets the fullStateArray with list of states
  setStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i].key
        };
        this.childMobilityFilterParams.states.push(responseData[i]);
      }
    }
  }

  //Open pop up window with more information on the dashboard
  goToLearnMore() {
    this.showFilter = true;
    const dialogRef = this.dialog.open(LearnMoreChildMobility, {
      height: 'auto',
      width: '50%',
      minHeight: '325px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: {params: this.authRoleClasses},
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.showFilter = false;
    });
  }

  goToDDLearnMore() {
    this.showFilter = true;
    const dialogRef = this.dialog.open(LearnMoreDDChildMobility, {
      height: 'auto',
      width: '50%',
      minHeight: '225px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: {params: this.chart},
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.showFilter = false;
    });
  }

  showDrilldown(chart, periodType) {
    this.showDrill = true;
    this.chart = chart;
    this.sortAscending = true;
    if (chart === "qadEnroll") {
      this.sortAscending = false;
    }
    this.currentSortColumn = 'column1';
    this.childMobilityFilterParams.periodType = periodType;
    if (chart == "moveToMap") {
      if (periodType == "PREVIOUS")
        this.mapExport = this.toPrevExport;
      else
        this.mapExport = this.toCurrExport;
    }
    else {
      if (periodType == "PREVIOUS")
        this.mapExport = this.fromPrevExport;
      else
        this.mapExport = this.fromCurrExport;
    }
    this.drillDownInit();
  }

  //Pull in Drilldown data
  drillDownInit() {
    this.drillDown = {
      beginRow: 1,
      endRow: 20,
      stateName: this.childMobilityFilterParams.selectedState,
      performancePeriod: this.childMobilityFilterParams.periodType,
      chartType: this.chart,
      tab: this.activeTab
    };
    if (this.chart === 'moveToMap') {
      this.drillDown.sortField = 'QUAL_MOVE_FROM_STATE_ASC';
    } else if (this.chart === 'moveFromMap') {
      this.drillDown.sortField = 'QUAL_MOVE_TO_STATE_ASC';
    } else {
      this.drillDown.sortField = 'ENROLLMENT_DATE_DESC';
    }
    this.updateDrilldown(this.drillDown);
  }

  updateDrilldown(drillDown) {
    this.drillDownData = {};
    this.reportGroupVar = 'dashboard';
    this.reportDrillDownVar = 'childMobilityDrilldown';
    let apiCall = this.reportsService.drillDownView(drillDown, this.reportGroupVar, this.reportDrillDownVar);
    let subscribed = apiCall.subscribe({
      next: data => {
        if (data.rows.length === 0) {
          this.blankRes = true;
        } else {
          console.log("Child Mobility updateDrilldown(): ", data);
          this.blankRes = false;
          this.drillDownData = data;
          this.drillDownData.query = drillDown;
          this.drillDownInfo = {
            drillDown: drillDown, 
            reportGroupVar: this.reportGroupVar,
            reportDrillDownVar: this.reportDrillDownVar
          }
        }
      },
      error: error => {
        console.log("Error - ChildMobility.updateDrilldown(): ", error);
      }
    });
  }

  //Sort Drill Down Data
  handleDrillDownSortClick(newSortKey) {
    this.drillDown.sortField = newSortKey.sortKey;
    this.updateDrilldown(this.drillDown);
  }

  //Updates which column and direction the table will be sorted by
  sortByColumn(data: {column: string, ascending: boolean}) {
    this.currentSortColumn = data.column;
    this.sortAscending = data.ascending;
  }

  //stringifies an object
  jsonStringify(obj) {
    return JSON.stringify(obj);
  }
}

// Separate component for the ChildMobility report's filter
@Component({
  selector: 'filterChildMobility',
  templateUrl: './filterChildMobility.html',
  styleUrls: ['./childMobility.component.scss']
})
export class FilterChildMobility {
  childMobilityFilterParams: { 
    rptGrping: string, 
    selectedState: string,
    states: any[]
  };
  constructor(
    public dialogRef: MatDialogRef<FilterChildMobility>,
    public responsiveUI: ResponsiveUIService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.childMobilityFilterParams = cloneDeep(data.params);
      if (this.childMobilityFilterParams.rptGrping == '' || !this.childMobilityFilterParams.rptGrping){
        this.resetFilter();
      }
  }

  resetFilter() {
    this.childMobilityFilterParams = {
      rptGrping: 'National',
      selectedState: "",
      states: this.data.params.states
    }
  }

  onSubmit(){
    this.dialogRef.close(this.childMobilityFilterParams);
  }

  selectState() {
    this.childMobilityFilterParams.rptGrping = 'State';
  }

  selectNational() {
    this.childMobilityFilterParams.rptGrping = 'National';
    this.childMobilityFilterParams.selectedState = '';
  }
  selectDrilldownState(state) {
    this.childMobilityFilterParams.selectedState = state;
  }

  onClose() {
    this.childMobilityFilterParams = cloneDeep(this.data.params);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.childMobilityFilterParams = cloneDeep(this.data.params);
    this.dialogRef.close();
  }
}

// Separate component for the ChildMobility report's Learn More dialog box
export interface LearnMoreParameters {
  params: {
    dataDashboards: boolean, 
    dataAdmins: boolean
  }
}
@Component({
  selector: 'learnMoreChildMobility',
  templateUrl: 'learnMoreChildMobility.html',
  styleUrls: ['./childMobility.component.scss']
})
export class LearnMoreChildMobility {
  authRoleClasses: any = {};
  constructor(
    public dialogRef: MatDialogRef<LearnMoreChildMobility>,
    @Inject(MAT_DIALOG_DATA) public data: LearnMoreParameters
  ) {
    this.authRoleClasses = data.params;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the ChildMobility report's drilldown Learn More dialog box
@Component({
  selector: 'learnMoreDDChildMobility',
  templateUrl: 'learnMoreDDChildMobility.html',
  styleUrls: ['./childMobility.component.scss']
})
export class LearnMoreDDChildMobility {
  constructor(
    public dialogRef: MatDialogRef<LearnMoreDDChildMobility>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
