<div class="create-edit-delete-new">
    <div class="header">
      <h2>Edit/Delete List</h2>
      <button class="md-button" type="button" (click)="onClose()" (keypress)="onClose()">
        <i class="fa fa-times ng-scope" aria-hidden="true"></i>
      </button>
    </div>

    <form>
      <div class="hint">Edit the name of this list by entering a new list name in the “List Name” field.
        Delete this list by selecting “Delete List”.
        Any action on this list will only affect this list.
      </div>

      <mat-form-field appearance="outline">
        <mat-label>List Name</mat-label>
        <input matInput [disabled]="deleteListisChecked" [(ngModel)]="editListName" name="editListName" />
      </mat-form-field>
      <div class="error" *ngIf="editListName.length > 60">List names must be 60 characters or less.</div>
      <div class="error" *ngIf="checkEditListName()">List name already exists. Enter a different name.</div>

      <mat-checkbox (change)="toggleDelete()">
        Delete List
      </mat-checkbox>

      <div class="hint" *ngIf="deleteListisChecked">
        Selecting Delete List will permanently delete this list.
        This action cannot be undone.
      </div>
      <div class="action-buttons">
        <button class="cancel-btn" (click)="onClose()" mat-button>Cancel</button>
        <button class="md-button" type="submit" (click)="editDeleteList()"
          [disabled]="!editListName || editListName.length > 60 || editListNameExists">Submit</button>
      </div>
    </form>
</div>