<div class="login-page background-awesome">
  <div class="md-content" [ngClass]="{'layoutMobP-chkEmlMsg': (responsiveUI.isMobile && responsiveUI.isPortrait), 'layoutMobL-chkEmlMsg': (responsiveUI.isMobile && !responsiveUI.isPortrait),
    'layoutTabP-chkEmlMsg': (responsiveUI.isTablet && responsiveUI.isPortrait), 'layoutTabL-chkEmlMsg': (responsiveUI.isTablet && !responsiveUI.isPortrait), 
    'layoutDesktop-chkEmlMsg': responsiveUI.isDesktop}">

    <div class="title">
      <p class="checkEmailMsg">You will receive an email with the next steps.</p>
      <br/>
      <p class="checkEmailMsg">If you do not receive an email, please reach out to your designated State Contact for support.</p>
    </div>

  </div>
  <p class="version-number">{{appVersion}}</p>
</div>
  