import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';

@Component({
    selector: 'app-user-search',
    templateUrl: './user-search.component.html',
    styleUrls: ['./user-search.component.scss']
})

export class UserSearch implements OnInit {
    form: FormGroup
    constructor(private fb: FormBuilder, private router: Router, public responsiveUI: ResponsiveUIService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        const searchParams = this.route.snapshot.queryParams;
        this.form = this.fb.group({
            firstName: [''],
            lastName: [''],
            username: ['']
          });
        this.form.reset(searchParams)
    }

    searchUser() {
        if (!this.form.get('firstName').value?.trim() && !this.form.get('lastName').value?.trim() && !this.form.get('username').value?.trim()) {
            return;
        }
        if (this.router.url.includes('/userAdmin')) {
            this.router.navigate([], {
                queryParams: this.form.value
            })
        } else {
            this.router.navigate(['userAdmin'], {
                queryParams: this.form.value
            });

        }
    }

    clearUserSearchFields() {
        this.form.reset();
    }

    getFormFieldLayout() {
        const classes = {'form-fields': true};
        if (this.responsiveUI.isMobile) {
            classes['form-fields'] = false;
            classes['form-fields-mobile'] = true;
        }

        return classes;
    }

    getNameContainerClass() {
        const classes = {'name-container': true};
        if (this.responsiveUI.isMobile) {
            classes['name-container'] = false;
            classes['name-container-mobile'] = true;
        }

        return classes;
    }
    getUsernameContainerClass() {
        const classes = {'username-container': true};
        if (this.responsiveUI.isMobile) {
            classes['username-container'] = false;
            classes['username-container-mobile']=true;
        }

        return classes;
    }

    
}