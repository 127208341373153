import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of, Subscription, tap } from 'rxjs';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { SavedListService } from 'src/app/services/savedlist.service';
import { SearchService } from 'src/app/services/search.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  showAdminDetails: boolean = false;
  adminIndex: number = -1;
  states: any[];
  search: {};
  totalRecords: any;
  sub = new Subscription();
  fullList: any[] = new Array();
  showSearch: boolean = true;
  newCheckedLists: any[];
  savedListsNames: any[];
  savelistObject = {
    msixID: '',
    stateStudentKey: '',
    savedListKeys: [],
    unsavedListKeys: [],
    newListName: ''
  };
  student = {
    saved: '',
    msixId: '',
    staStuKey: ''
  };
  searchParams: SearchType;
  pageSize: number;
  partialListLength: number;
  studentIndex: number = -1;

  constructor(private searchService: SearchService,
    private savedListService: SavedListService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private _router: Router,
    public responsiveUI: ResponsiveUIService) {
  }

  ngOnInit(): void {
    this.sub.add(this.route.queryParams.subscribe(searchParams => {
      this.searchParams = searchParams as SearchType;
      if (Object.keys(this.searchParams).length > 0) this.searchForStudents(searchParams as SearchType, false);
    }))
    this.getSavedListsNames();
  }

  searchForStudents(searchObject: SearchType, isLoadMore: boolean = false) {
    if (!isLoadMore) {
      this.fullList = new Array();
    }

    if (searchObject && (searchObject.identifier || searchObject.firstName || searchObject.lastName
      || searchObject.year || searchObject.parentFirstName || searchObject.parentLastName)) {

      this.searchAgain();

      this.sub.add(this.searchService.postSearchResults(searchObject).pipe(
        tap((res: any) => {
          this.totalRecords = res.results.totalRecords;
          this.pageSize = res.query.pageSize;
          this.partialListLength = res.results.partialList.length;
          this.fullList = this.fullList.concat(res.results.partialList);
          this.showSearch = false;
      }), catchError(err => of(this.toastService.showToast(err.error.developerMessage, 0))
      )).subscribe());
    }
  }

  //Retrieves a list of the names of a user's saved lists
  getSavedListsNames() {
    let apiCall = this.savedListService.getSaveListsNames();
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: result => {
        let res: any = result;

        console.debug(res);
        this.savedListsNames = res.data;

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this.toastService.showToast(error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  viewDetails(student) {
    let userActivity = {
      msixID: student.msixId,
      pageName: 'APP_STU_VW_CONS',
      activityNotes: '',
      sessionID: ''
    }
    
    this.searchService.logUserActivity(userActivity);

    this._router.navigate(['studentDetails'], {
      queryParams: {
        msixId: student.msixId,
        name: student.stuName
      }
    });
  }

  loadMoreResults() {
    this.searchForStudents({...this.searchParams, pageNum: this.searchParams.pageNum + 1}, true);
  }

  showStudentDetailsFn(index) {
    if (this.studentIndex === index) {
      this.studentIndex = -1;
    } else {
      this.studentIndex = index;
    }
  }

  //adds student to user's My Default List when button is selected in mobile/tablet mode
  addToMyDefaultList(msixId) {
    for (let list of this.savedListsNames) {
      if (list.saveListName === 'My Default List') {
        this.newCheckedLists.push(list.saveListKey);
      }
    }

    this.savelistObject = {
      msixID: msixId,
      savedListKeys: this.newCheckedLists,
      unsavedListKeys: [],
      newListName: '',
      stateStudentKey: null
    }

    let apiCall = this.savedListService.updateStudentToSavedLists(this.savelistObject);
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: result => {
        console.debug(result);
        this.newCheckedLists = [];

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this.toastService.showToast(error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  searchAgain() {
    this.totalRecords = -1;
    this.showSearch = true;
  }

  ngOnDestroy(): void {
      this.sub.unsubscribe()
  }
}

export interface SearchType {
  firstName: string,
  lastName: string,
  lastName2: string,
  middleName: string,
  gender: string,
  month: string,
  day: string,
  year: string,
  multiBirth: string,
  parentFirstName: string,
  parentLastName: string,
  username: string,
  state: string,
  birthState: string,
  birthCountry: string,
  schoolDistrict: string,
  schoolProjectName: string,
  facilityName: string,
  identifier: string,
  identifierType: string,
  pageNum: number
};
