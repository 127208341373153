import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { ReportsService } from 'src/app/services/reports.service';
import { UserService } from 'src/app/services/user.service';
import { FileSubmissionService } from './fileSubmission.service';
import * as _ from 'lodash'

@Component({
  selector: 'fileSubmission',
  templateUrl: './fileSubmission.component.html',
  styleUrls: ['./fileSubmission.component.scss']
})
export class FileSubmissionComponent {

  mdeExportCall: string = 'fileSubmissionMdeExport';
  exportCall: string = '';
  router: string;
  animal: string;
  name: string;
  showFilter: boolean;
  isInit: boolean;
  showDrill: boolean = false;
  showMdeDrill: boolean = false;
  isReportLoadedAtLeaseOnce: boolean;
  statesSelectedFlag: boolean = false;
  mdeDrilldown: any = {};
  mdeDrillDownExport: any = {};

  jobsArray: any[];
  jobTitle: any;
  response: any;
  blankRes: boolean;
  userKeys: any[];
  // holds the list of states
  states: any[] = [];
  // holds the set of selected stateKeys
  fullStateArray: any[] = [];
  showHistory: boolean;
  disableWithdrawalDate: boolean;
  params: any;
  stateMap: any;

  resetFilters: boolean;
  reportInfo: any;
  drillDown: any;
  sortAscending: boolean = true;
  currentSortColumn: string = 'column1';
  fileCountDetailsSortAscending: boolean = true;
  currentFileCountDetailsSortColumn: string = "column1";
  fileErrorDetailsSortAscending: boolean = true;
  currentFileErrorDetailsSortColumn: string = "column1";
  mdeDetailsSortAscending: boolean = true;
  currentMdeDetailsSortColumn: string = "column1";
  stayOnDrillDown: boolean;
  drillDownDetails: any;
  drillDownData: any;
  disablePrev: boolean = false;
  disableNext: boolean = false;
  userKeysIndex: number;
  reportGroupVar: string = "product";
  reportDrillDownVar: string = "findUserLoginInfo";
  assignedDistricts: any[];
  //hold export count for Reports
  exportCount: number;


  //holds filter parameters
  filterParameters: any = {
    selectedStatesArray: [],
    fullStateArray: []
  };
  selectedStatesArray: any = [];
  userStatusArray: any;
  userTypeArray: any;
  creationDate: any;
  activationDate: any;
  loginDate: any;
  expirationDate: any;

  // holds the current active filter parameters
  activeStatusArray: any;
  activeTypeArray: any;
  activeCreationDate: any;
  activeActivationDate: any;
  activeLoginDate: any;
  activeExpirationDate: any;

  //this object holds current & recent reporting period for display purposes
  displayData = {
    current: "",
    recent: "",
    category: "",
    subcategory: ""
  };
  drillDownData1: any = {};
  drillDownData2: any = {};

  //holds report init values
  isMdeDetailsInit: boolean = true;
  isFileCountsInit: boolean = true;
  isFileErrorsInit: boolean = true;
  stateKeyArray: any[] = [];
  reportPaginationBeginRow: number = 1;
  reportPaginationEndRow: number = 100;
  reportLevelSort: string = 'RECEIVED_DATE_DESC';
  drillDownMdeDetails: any = {};
  availableReportRows: number = 0;
  statesString: string = '';
  ssoreiName: string = '';
  exportParams: any = { statesString: '', exportType: {} };
  updateSort: boolean = false;

  constructor(
    public dialog: MatDialog,
    private reportsService: ReportsService,
    private userService: UserService,
    private reportInfoService: ReportInfoService,
    private _router: Router,
    private service: FileSubmissionService) {
    this.router = _router.url;
    this.showFilter = true;
    this.isInit = false;
    this.response = {};
    this.blankRes = false;
    this.isReportLoadedAtLeaseOnce = false;
    this.userKeys = [];
    this.resetFilters = false;
    this.userKeysIndex = 0;
    this.stayOnDrillDown = false;
    this.exportCount = 0;
    this.showHistory = false;
    this.disableWithdrawalDate = false;
    this.reportInfo = {};
    this.drillDownDetails = {};
    this.drillDownData = {};
    this.stateMap = new Map();
    this.userStatusArray = [];
    this.filterParameters = {
      selectedStatesArray: [],
      fullStateArray: [],
      exportType: 'CSV'
    };
    this.selectedStatesArray = [];
    this.userStatusArray = [];
    this.userTypeArray = [];
    this.jobTitle = {
      other: ''
    };
  }

  ngOnInit() {
    //Pull the information to load the filter
    this.getReportInfo();
  }

  goToReport(page) {
    if (page === 'Details') {
      this.showDrill = true;
    } else {
      this.showDrill = false;
    }
    this.showMdeDrill = false;
  }

  // gets info needed to generate report
  async getReportInfo() {
    this.reportInfo = await this.reportInfoService.getReportInfo(['dataQuality_all', 'dataQuality'])

    this.mineAndSetStatesArray(this.reportInfo.stateData);
    // generates report
    if (this.reportInfo.authRoleClasses.dataQuality) {
      this.filterActivate();
    } else {
      this.submitFilter();
    }
  }

  // Trigger Filter Modal
  filterActivate() {
    this.filterParameters.authRoleClasses = JSON.parse(JSON.stringify(this.reportInfo.authRoleClasses));
    this.filterParameters.states = JSON.parse(JSON.stringify(this.states));
    this.filterParameters.fullStateArray = JSON.parse(JSON.stringify(this.fullStateArray));
    this.filterParameters.statesSelectedFlag = JSON.parse(JSON.stringify(this.statesSelectedFlag));
    this.filterParameters.selectedStatesArray = JSON.parse(JSON.stringify(this.selectedStatesArray));

    this.showFilter = true;
    console.log('filter should be showing');
    const dialogRef = this.dialog.open(FilterFileSubmission, {
      height: 'auto',
      width: '60%',
      minHeight: '325px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: { params: this.filterParameters },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      //this.filterParameters = this.activeFileSubmissionFilter;
      if (result) {
        this.filterParameters = result;
        this.selectedStatesArray = JSON.parse(JSON.stringify(this.filterParameters.selectedStatesArray));
        this.statesSelectedFlag = JSON.parse(JSON.stringify(this.filterParameters.statesSelectedFlag));
        this.submitFilter();
      }
      this.showFilter = false;
    });
  }

  // initialize the report
  submitFilter() {
    this.isInit = true;
    this.isMdeDetailsInit = true;
    this.isFileCountsInit = true;
    this.isFileErrorsInit = true;
    this.stateKeyArray = [];
    this.reportPaginationBeginRow = 1;
    this.reportPaginationEndRow = 100;
    this.reportLevelSort = 'RECEIVED_DATE_DESC';
    if (this.selectedStatesArray.length === 0) {
      this.blankRes = true;
      this.response = [];
    } else {
      this.blankRes = false;
      this.submitReport();
    }
  }

  submitReport() {
    // Reset the selected State keys array.
    this.stateKeyArray = [];

    for (let i = 0; i < this.filterParameters.selectedStatesArray.length; i++) {
      this.stateKeyArray[i] = this.filterParameters.selectedStatesArray[i]; //use i instead of 0
    }

    this.statesString = this.stateKeyArray.join();
    var roles = this.reportInfo.authRoleClasses;
    if (roles.dataQuality) {
      if (this.statesSelectedFlag === true) {
        this.fileSubmissionLoadInfoSelectedStates(this.statesString);
        this.setExportData(this.statesString);
      } else {
        this.fileSubmissionLoadInfoAllStates();
        this.setExportData(null);
      }
    } else {
      this.fileSubmissionLoadInfoSelectedStates(this.reportInfo.userState);
      this.setExportData(this.reportInfo.userState)
    }
  }

  //Sets exportCall 
  //Set after filter is submitted
  setExportData(statesString) {
    var exportType = this.filterParameters.exportType;
    if (statesString == null) {
      this.exportCall = 'findDataLoadInfoExportAll';
      this.exportParams = {
        exportType: exportType
      };
    } else {
      this.exportCall = 'findDataLoadInfoExport';
      this.exportParams = {
        statesString: statesString,
        exportType: exportType
      };
    }
  }

  // Learn More Activate
  learnMoreActivate() {
    this.showFilter = true;
    if (this.showDrill) {
      const dialogRef = this.dialog.open(FileSubmissionDDLearnMore, {
        height: 'auto',
        width: '57%',
        panelClass: 'cdk-overlay-container',
        data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
        this.showFilter = false;
      });
    } else if (this.showMdeDrill) {
      let mdeParams = {
        mdeDrilldown: this.mdeDrilldown
      };
      const dialogRef = this.dialog.open(FileSubmissionMdeDDLearnMore, {
        height: 'auto',
        width: '60%',
        panelClass: 'cdk-overlay-container',
        data: { params: mdeParams },
      });
      dialogRef.afterClosed().subscribe(result => {
        this.showFilter = false;
      });
    } else {
      const dialogRef = this.dialog.open(FileSubmissionLearnMore, {
        height: 'auto',
        width: '60%',
        panelClass: 'cdk-overlay-container',
        data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
        this.showFilter = false;
      });
    }
  }

  fileSubmissionLoadInfoAllStates() {
    let apiCall = this.reportsService.fileSubmissionLoadInfoAllStates(this.reportPaginationBeginRow, this.reportPaginationEndRow, this.reportLevelSort);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("FileSubmission.fileSubmissionLoadInfoAllStates(): ", data);
        this.response = data;
        if (!this.updateSort) {
          this.sortAscending = false;
          this.currentSortColumn = 'column3';
        }
        this.availableReportRows = data.totalAvailableRows;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - FileSubmission.fileSubmissionLoadInfoAllStates(): ", error);
        subscribed.unsubscribe();
      }
    });
  }

  fileSubmissionLoadInfoSelectedStates(statesString) {
    let apiCall = this.reportsService.fileSubmissionLoadInfoSelectedStates(statesString, this.reportPaginationBeginRow, this.reportPaginationEndRow, this.reportLevelSort);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("FileSubmission.fileSubmissionLoadInfoSelectedStates(): ", data);
        this.response = data;
        if (!this.updateSort) {
          this.sortAscending = false;
          this.currentSortColumn = 'column3';
        }
        this.availableReportRows = data.totalAvailableRows;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - FileSubmission.fileSubmissionLoadInfoSelectedStates(): ", error);
        subscribed.unsubscribe();
      }
    });
  }


  // removes DC, PR, and WY from states array and sets the selectedStatesArray with list of states
  mineAndSetStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i].stateKey
        }
        this.states.push(responseData[i]);
        this.fullStateArray.push(obj);
        this.selectedStatesArray.push(responseData[i].stateKey);
      }
    }
  }

  //Report Level Pagination - back
  pageBack() {
    this.isInit = false;
    if (this.reportPaginationBeginRow > 1) {
      this.reportPaginationBeginRow = this.reportPaginationBeginRow - 100;
      this.reportPaginationEndRow = this.reportPaginationEndRow - 100;
      this.submitReport();
    }
  }

  //Report Level Pagination - forward
  pageForward() {
    this.isInit = false;
    this.reportPaginationBeginRow = this.reportPaginationBeginRow + 100;
    this.reportPaginationEndRow = this.reportPaginationEndRow + 100;
    this.submitReport();
  }

  //Init for getting data for File Count and File Error tables on File Submission Details page
  drillDownInit(data) {
    console.log("FileSubmission.drillDownInit(): ", data)
    this.drillDownDetails = data.data;

    //format file name
    var fileName = this.drillDownDetails.fileName;
    var formatFile = fileName.split('/')
    formatFile = formatFile[formatFile.length - 1]
    formatFile = formatFile.replace("_P", "")
    this.drillDownDetails.fileName = formatFile;
    this.drillDownDetails.fileKey = this.drillDownDetails.fileKey;

    this.drillDown = {
      fileKey: this.drillDownDetails.fileKey,
      fileName: this.drillDownDetails.fileName,
      state: this.drillDownDetails.state,
      beginEffectiveDate: this.drillDownDetails.beginEffectiveDate,
      endEffectiveDate: this.drillDownDetails.endEffectiveDate,
      receivedDate: this.drillDownDetails.receivedDate,
      fileFormat: this.drillDownDetails.fileFormat,
      fileStatus: this.drillDownDetails.fileStatus,
      processedDate: this.drillDownDetails.processedDate,
      totalStudentCount: this.drillDownDetails.totalStudentCount,
      totalRecordCount: this.drillDownDetails.totalRecordCount,
      totalStudentRejectionCount: this.drillDownDetails.totalStudentRejectionCount === "NA" ? "0" : this.drillDownDetails.totalStudentRejectionCount,
      totalRecordRejectionCount: this.drillDownDetails.totalRecordRejectionCount === "NA" ? "0" : this.drillDownDetails.totalRecordRejectionCount,
      totalValidityIssueCount: this.drillDownDetails.totalValidityIssueCount === "NA" ? "0" : this.drillDownDetails.totalValidityIssueCount,
      totalStudentDeactivatedCount: this.drillDownDetails.totalStudentDeactivatedCount === "NA" ? "0" : this.drillDownDetails.totalStudentDeactivatedCount,
      newStudentCount: this.drillDownDetails.newStudentCount === "NA" ? "0" : this.drillDownDetails.newStudentCount,
      nearMatchStudentCount: this.drillDownDetails.nearMatchStudentCount === "NA" ? "0" : this.drillDownDetails.nearMatchStudentCount,
      existingStudentCount: this.drillDownDetails.existingStudentCount === "NA" ? "0" : this.drillDownDetails.existingStudentCount,
      enrollmentRecordCount: this.drillDownDetails.enrollmentRecordCount === "NA" ? "0" : this.drillDownDetails.enrollmentRecordCount,
      qualMoveRecordCount: this.drillDownDetails.qualMoveRecordCount === "NA" ? "0" : this.drillDownDetails.qualMoveRecordCount,
      assessmentRecordCount: this.drillDownDetails.assessmentRecordCount === "NA" ? "0" : this.drillDownDetails.assessmentRecordCount,
      courseHistoryRecordCount: this.drillDownDetails.courseHistoryRecordCount === "NA" ? "0" : this.drillDownDetails.courseHistoryRecordCount
    }
    //show Drill Down - hide Report
    this.showDrill = !this.showDrill;
    this.updateDrilldownFileCount(this.drillDown, 'MDE_CATEGORY_ASC');
    this.updateDrilldownFileError(this.drillDown, 'ERROR_TYPE_ASC');
  }

  // get Drill down data for File Count table on File Submission Details page.
  updateDrilldownFileCount(drillDown, sortField) {

    let drilldownFileCount = _.cloneDeep(drillDown);
    drilldownFileCount.sortField = sortField;

    let apiCall = this.reportsService.drillDownView(drilldownFileCount, this.reportGroupVar, "findDataLoadErrorInfoByMDEGroup/" + sortField);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("FileSubmission.updateDrilldownFileCount(): ", data);
        this.drillDownData1 = data;
        this.drillDownData1.query = drilldownFileCount;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - FileSubmission.updateDrilldownFileCount(): ", error);
        subscribed.unsubscribe();
      }
    });
  }

  // get Drill down data for File Error table on File Submission Details page.
  updateDrilldownFileError(drillDown, sortField) {

    let drilldownFileError = _.cloneDeep(drillDown);
    drilldownFileError.sortField = sortField;

    let apiCall = this.reportsService.drillDownView(drilldownFileError, this.reportGroupVar, "findDataLoadErrorInfoByErrorType/" + sortField);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("FileSubmission.updateDrilldownFileError(): ", data);
        this.drillDownData2 = data;
        this.drillDownData2.query = drilldownFileError;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - FileSubmission.updateDrilldownFileError(): ", error);
        subscribed.unsubscribe();
      }
    });
  }

  //Sort Drill Down Data
  handleReportSpecialSortClick(data) {
    this.isInit = false;
    this.reportLevelSort = data.sortKey;
    this.reportPaginationBeginRow = 1;
    this.reportPaginationEndRow = 100;
    this.updateSort = true;
    this.submitReport();
  }

  // Sort MDE Details drill down data
  handleReportSpecialSortClickForMdeDetails(data) {
    this.isMdeDetailsInit = false;

    this.drillDown.sortField = data.sortKey;

    this.getDrillDownMDEData(this.drillDown);
  }

  // Sort File Counts table data on File Submission Details page
  handleReportSpecialSortClickForFileCounts(data) {
    this.isFileCountsInit = false;

    this.updateDrilldownFileCount(this.drillDown, data.sortKey);
  }

  // Sort File Errors table data on File Submission Details page
  handleReportSpecialSortClickForFileErrors(data) {
    this.isFileErrorsInit = false;

    this.updateDrilldownFileError(this.drillDown, data.sortKey);
  }

  getDrillDownMDEData(drillDownDetails) {
    let apiCall = this.reportsService.drillDownView(drillDownDetails, this.reportGroupVar, 'mdeDrillDown');
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("FileSubmission.getDrillDownMDEData(): ", data);
        this.drillDownMdeDetails = data;
        this.drillDownMdeDetails.query = drillDownDetails;
        if (data.rows[0]) {
          this.ssoreiName = data.rows[0].originalResult.ssoreiName;
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - FileSubmission.getDrillDownMDEData(): ", error);
        subscribed.unsubscribe();
      }
    });

    //Set export object
    this.mdeDrillDownExport = {
      mdeType: drillDownDetails.mdeType,
      fileKey: drillDownDetails.fileKey,
      fileName: drillDownDetails.fileName,
      sortField: drillDownDetails.sortField
    };
  }

  //Pull in Drilldown data
  drillDownMdeInit(data) {
    console.log("FileSubmission.drillDownMdeInit(): ", data);

    let defaultSortfield = 'MDE_NUMBER_ASC';

    if (data.errorType == 'Non-MDE') {
      defaultSortfield = 'MDE_ASC';
    }

    this.drillDownMdeDetails = data.data;
    this.mdeDrilldown = data.data.errorType;
    this.drillDown = {
      mdeType: data.data.errorKey,
      fileKey: this.drillDownDetails.fileKey,
      sortField: defaultSortfield,
      fileName: this.drillDownDetails.fileName
    };

    this.getDrillDownMDEData(this.drillDown);

    this.showDrill = false;
    this.showMdeDrill = true;
  }

  //Updates which column and direction the table will be sorted by
  sortByColumn(data, ascending) {
    this.currentSortColumn = data.column;
    this.sortAscending = data.ascending;
  }

  //Updates which column and direction the MDE details table will be sorted by
  sortByColumnForMdeDetails(data, ascending) {
    this.currentMdeDetailsSortColumn = data.column;
    this.mdeDetailsSortAscending = data.ascending;
  }

  //Updates which column and direction the File Count table on File Submission Details page will be sorted by
  sortByColumnForFileCountDetails(data) {
    this.currentFileCountDetailsSortColumn = data.column;
    this.fileCountDetailsSortAscending = data.ascending;
  }

  //Updates which column and direction the File Erro table on File Submission Details page will be sorted by
  sortByColumnForFileErrorDetails(data) {
    this.currentFileErrorDetailsSortColumn = data.column;
    this.fileErrorDetailsSortAscending = data.ascending;
  }
}

// Separate component for the FileSubmission report's filter
export interface FilterParameters {
  params: {
    userState: string,
    authRoleClasses: any,
    dateObj: any,
    fullStateArray: any,
    selectedStatesArray: any,
    statesSelectedFlag: boolean
  }
}
@Component({
  selector: 'filterFileSubmission',
  templateUrl: './filterFileSubmission.html',
  styleUrls: ['./fileSubmission.component.scss']
})
export class FilterFileSubmission {
  filterParameters: {
    userState: string,
    authRoleClasses: any,
    dateObj: any,
    fullStateArray: any,
    selectedStatesArray: any,
    statesSelectedFlag: boolean

  };
  constructor(
    public dialogRef: MatDialogRef<FilterFileSubmission>,
    @Inject(MAT_DIALOG_DATA) public data: FilterParameters) {
    console.log("DATA passed in for the filter: ", data);
    this.filterParameters = data.params;
    console.log("filterParameters passed in for the filter: ", this.filterParameters);
  }

  resetFilter() {
    //this.filterParameters = this.data;
    this.selectAll();
  }

  selectAll() {
    if (this.filterParameters.authRoleClasses.dataQuality == true) {
      this.filterParameters.selectedStatesArray = this.filterParameters.fullStateArray.map(state => state.key);
    } else {
      this.filterParameters.selectedStatesArray = [{ key: this.filterParameters.userState }]
    }
  }

  selectNone() {
    this.filterParameters.selectedStatesArray = [];
  }

  // sets the ng-checked to true or false based on if stateKey exists in the selectedStatesArray
  existsInSelectedStatesArray(stateKey) {
    if (this.filterParameters.selectedStatesArray.indexOf(stateKey) > -1) {
      return true;
    } else {
      return false;
    }
  }

  // adds and removes stateKeys from the selectedStatesArray based on user click of states' checkbox
  checkedStates(stateKey) {
    this.filterParameters.statesSelectedFlag = true;
    var statesIndex = this.filterParameters.selectedStatesArray.indexOf(stateKey);
    if (statesIndex === -1) {
      this.filterParameters.selectedStatesArray.push(stateKey);
    } else {
      this.filterParameters.selectedStatesArray.splice(statesIndex, 1);
    }
  }

  onSubmit() {
    this.dialogRef.close(this.filterParameters);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the FileSubmission report's Learn More dialog box
@Component({
  selector: 'fileSubmissionLearnMore',
  templateUrl: 'fileSubmissionLearnMore.html',
  styleUrls: ['./fileSubmission.component.scss']
})
export class FileSubmissionLearnMore {
  constructor(
    public dialogRef: MatDialogRef<FileSubmissionLearnMore>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the FileSubmission report's drilldown Learn More dialog box
@Component({
  selector: 'fileSubmissionDDLearnMore',
  templateUrl: 'fileSubmissionDDLearnMore.html',
  styleUrls: ['./fileSubmission.component.scss']
})
export class FileSubmissionDDLearnMore {
  constructor(
    public dialogRef: MatDialogRef<FileSubmissionDDLearnMore>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the FileSubmission report's MDE drilldown Learn More dialog box
export interface LearnMoreMdeDDParameters {
  params: {
    mdeDrilldown: string
  }
}
@Component({
  selector: 'fileSubmissionMdeDDLearnMore',
  templateUrl: 'fileSubmissionMdeDDLearnMore.html',
  styleUrls: ['./fileSubmission.component.scss']
})
export class FileSubmissionMdeDDLearnMore {
  learnMoreParameters: { mdeDrilldown: string; };
  constructor(
    public dialogRef: MatDialogRef<FileSubmissionMdeDDLearnMore>,
    @Inject(MAT_DIALOG_DATA) public data: LearnMoreMdeDDParameters) {
    this.learnMoreParameters = data.params;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
