import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { ToastService } from 'src/app/services/toast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'userAdmin',
  templateUrl: './userAdmin.component.html',
  styleUrls: ['./userAdmin.component.scss']
})
export class UserAdminComponent implements OnInit, OnDestroy {
  showResults: boolean;
  isAuthenticatedUser: boolean;
  userIndex: number;
  userList: any;
  totalRecords: number;
  searchParams: SearchObject;
  sub = new Subscription()
  
  constructor(private userService: UserService,
    private reportInfoService: ReportInfoService,
    private router: Router,
    public responsiveUI: ResponsiveUIService,
    private toastService: ToastService,
    private route: ActivatedRoute) {
    this.showResults = false;
    this.isAuthenticatedUser = false;
  }
  

  ngOnInit() {
    this.setAuthenticatedStatus();
    this.sub.add(this.route.queryParams.subscribe(search => {
      if (!Object.values(search).length) {
        return
      }
      this.searchParams = search as SearchObject;
      this.userSearch();
    }))
  }

  private async setAuthenticatedStatus() {
    const userObj = await this.reportInfoService.getReportInfo(['stateAdmin', 'regionalAdmin']);
    this.isAuthenticatedUser = userObj.isAuthenticated;
  }

  resetPassword(username) {
    let apiCall = this.userService.adminResetPassword(username);
    let subscribed = apiCall.subscribe({
      next: res => {
        console.log(res);
        this.toastService.showToast('Password reset!');

        subscribed.unsubscribe();
      },
      error: error => {
        console.log(error);
        this.toastService.showToast(error.data.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  showUserDetailsFn(index) {
    if (this.userIndex === index) {
      this.userIndex = -1;
    } else {
      this.userIndex = index;
    }
  }

  searchAgain() {
    this.showResults = false;
  }

  goToNewUserPage() {
    this.router.navigate(['newUser']);
  }

  userSearch() {
    let apiCall = this.userService.getUsers(this.searchParams.username, this.searchParams.firstName, this.searchParams.lastName);
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: res => {
        console.log(res);

        let result: any = res;
        this.userList = result;
        this.totalRecords = result.length;
        this.showResults = true;

        subscribed.unsubscribe();
      },
      error: error => {
        console.log(error);
        error = error.errors;
        this.toastService.showToast(error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  viewDetails(userId) {
    this.router.navigate(['userDetails', {
      username: userId
    }]);
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
}

interface SearchObject {
  firstName: string,
  lastName: string,
  username: string
}