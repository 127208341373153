import { Component, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { WorklistService } from 'src/app/services/worklist.service';
import { AppConstants } from 'src/app/config/index.constants';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { Router } from '@angular/router'; 
import { cloneDeep } from 'lodash';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'recordsExchange',
  templateUrl: './recordsExchange.component.html',
  styleUrls: ['./recordsExchange.component.scss']
})
export class RecordsExchangeComponent {
  topRecordsExchangeId: string = "topRecordsExchange";
  topRecordsExchangeChart: string = "topRecordsExchangeChart";
  worklistAgingId: string = "worklistAging";
  worklistAgingChart: string = "worklistAgingChart";
  chart: string = "topRecordsExchange";
  topRecordsExchangeExportCall = "topRecordsExchangeExport";
  worklistAgingExportCall = "worklistAgingExport";
  private router: any;
  private appConst = AppConstants;
  private userStateName: string;
  private recordsExchangeFilterParams: any = {};
  private activeRecordsExchangeFilter: any = {};
  private topRecordsExchangeData: any;
  private worklistAgingData: any;
  private drillDown: any;
  drillDownInfo: any = {};
  showDrill: boolean;
  private ua: string;
  private activeTab: string;
  private periodText: string;
  private reportGroupVar: string;
  private reportDrillDownVar: string;
  private blankRes: boolean;
  private tabChangeDone: boolean;
  private drillDownData: any;
  private roleClasses: any[];
  private authRoleClasses: any;
  private dateObj: any;
  private displayData: any;
  private recordsExchangeExportObject: any;
  private top10RecExDataHasLoaded: boolean;
  private hideTop10RecEx: boolean;
  private dataReqWorklistDataHasLoaded: boolean;
  private hideDataReqWorklist: boolean;
  private dataHasLoaded: boolean;
  private lastUpdated: any;
  private previousDay: any;
  sortAscending: boolean = false;
  currentSortColumn: string = 'column1';
  showFilter: boolean;
  constructor(
    public dialog: MatDialog,
    private cookieService: CookieService,
    private reportsService: ReportsService,
    private reportInfoService: ReportInfoService,
    private authService: AuthService,
    private toastService: ToastService,
    private worklistService: WorklistService,
    private _router: Router,
    public responsiveUI: ResponsiveUIService) {
      this.router = _router.url;
      this.showDrill = false;
      this.ua = navigator.userAgent;
      this.reportGroupVar = 'studentInfo';
      this.reportDrillDownVar = 'accountManagementDrilldown';
      this.activeTab = 'totalLogins';
      this.periodText = '';
      this.blankRes = true;
      this.tabChangeDone = false;
      this.top10RecExDataHasLoaded = false;
      this.hideTop10RecEx = false;
      this.dataReqWorklistDataHasLoaded = false;
      this.hideDataReqWorklist = false;
      this.topRecordsExchangeData = {};
      this.worklistAgingData = {};
      this.dataHasLoaded = true;
      this.lastUpdated = new Date();
      this.previousDay = new Date();
      this.showFilter = true;
      this.dateObj = {
        current: '',
        recent: '',
        currentStartYear: '',
        currentEndYear: '',
        recentStartYear: '',
        recentEndYear: ''
      };
      this.displayData = {
        current: "",
        recent: "",
        state: "",
        recordName: ""
      };
      this.authRoleClasses = {};
      this.drillDown = {
        beginRow: 1,
        endRow: 20,
        sortField: "MONTH_DESC",
        stateName: this.recordsExchangeFilterParams.selectedState,
        performancePeriod: this.recordsExchangeFilterParams.periodType,
        chartType: 'accountManagement',
        tab: this.activeTab
      };
      this.roleClasses = [
        'dataDashboard_studentAchievement_with_sua',
        'dataDashboards',
        'dataAdmins',
        'dataRequest_ga'
      ];
  }

  ngOnInit() {
    this.getReportInfo();
  }

  goToReport() {
    this.showDrill = false;
  }

  //Gets info needed to display the filter: list of states, user's state, both performance periods, and the authorized role classes
  async getReportInfo() {
    let reportInfo =  await this.reportInfoService.getReportInfo(this.roleClasses);

    console.log("RecordsExchange.getReportInfo(): ", reportInfo);
    this.userStateName = reportInfo.userStateName;
    this.dateObj.current = reportInfo.currentPeriod;
    this.dateObj.recent = reportInfo.recentPeriod;
    this.authRoleClasses = reportInfo.authRoleClasses;
    if (reportInfo.authRoleClasses.dataAdmins) {
      this.recordsExchangeFilterParams = {
        rptGrping: 'State',
        selectedState: this.userStateName,
        periodType: 'CURRENT',
        authRoleClasses: this.authRoleClasses,
        dateObj: this.dateObj,
        states: []
      };
    } else {
      this.recordsExchangeFilterParams = {
        rptGrping: 'National',
        selectedState: "",
        periodType: 'CURRENT',
        authRoleClasses: this.authRoleClasses,
        dateObj: this.dateObj,
        states: []
      };
    }
    this.setStatesArray(reportInfo.stateData);

    //Generate the report automatically if in mobile mode
    //Otherwise open the filter dialog
    if (this.responsiveUI.isMobile) {
      this.submitFilter();
    } else {
      this.filterActivate();
    }
  }

  // Trigger Filter Modal
  filterActivate() {
    this.showFilter = true;
    const dialogRef = this.dialog.open(FilterRecordsExchange, {
      height: 'auto',
      width: '60%',
      minHeight: '325px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: {params: this.recordsExchangeFilterParams},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.activeRecordsExchangeFilter = result;
        this.recordsExchangeFilterParams = result;
        this.submitFilter();
        this.showFilter = false;
      }
    });
  }

  submitFilter() {

    this.top10RecExDataHasLoaded = false;
    this.hideTop10RecEx = false;
    let apiCall = this.reportsService.getRecordsExchangeFilter(this.recordsExchangeFilterParams.selectedState, this.recordsExchangeFilterParams.periodType);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.recordsExchangeFilterParams = JSON.parse(JSON.stringify(this.activeRecordsExchangeFilter));

        console.log("RecordsExchange.submitFilter(): ", data);
        this.topRecordsExchangeData =data.topRecordsExchange;
        this.worklistAgingData = data.worklistAging;
        this.dataHasLoaded = true;
        this.top10RecExDataHasLoaded = true;
        this.dataReqWorklistDataHasLoaded = true;

        this.setLastUpdatedTop10RecEx();
        this.setLastUpdatedDataReq();

        if (this.topRecordsExchangeData.data.length == 0) {
          this.hideTop10RecEx = true;
        }

        if (this.worklistAgingData.data.length == 0) {
          this.hideDataReqWorklist = true;
        }

        if (this.recordsExchangeFilterParams.selectedState && this.recordsExchangeFilterParams.periodType) {
          this.recordsExchangeExportObject = {
            stateName: this.recordsExchangeFilterParams.selectedState,
            performancePeriod: this.recordsExchangeFilterParams.periodType
          };
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - RecordsExchange.submitFilter(): ", error);
        this.dataHasLoaded = true;
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  setLastUpdatedTop10RecEx() {
    this.reportsService.getFirstDayOfMonth().subscribe({
      next: data => {
        this.lastUpdated = data.date;
      },
      error: error => {
        console.log("Error - RecordsExchange.setLastUpdatedTop10RecEx(): ", error);
      }
    });
  }

  setLastUpdatedDataReq() {
    this.reportsService.getPreviousDay().subscribe({
      next: data => {
        this.previousDay = data.date;
      },
      error: error => {
        console.log("Error - RecordsExchange.setLastUpdatedDataReq(): ", error);
      }
    });
  }

  // removes DC, PR, and WY from states array and sets the fullStateArray with list of states
  setStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i
          ].key
        };
        this.recordsExchangeFilterParams.states.push(responseData[i]);
      }
    }
  }

  //Open pop up window with more information on the dashboard
  goToLearnMore() {
    this.showFilter = true;
    const dialogRef = this.dialog.open(LearnMoreRecordsExchange, {
      height: 'auto',
      width: '50%',
      minHeight: 'fit-content',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,  
      data: {params: this.authRoleClasses},
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.showFilter = false;
    });
  }

  goToDDLearnMore() {
    this.showFilter = true;
    const dialogRef = this.dialog.open(LearnMoreDDRecordsExchange, {
      height: 'auto',
      width: '50%',
      minHeight: 'fit-content',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,  
      data: {params: this.chart},
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.showFilter = false;
    });
  }

  showDrilldown(chart) {
    this.showDrill = true;
    this.chart = chart;
    this.sortAscending = false;
    this.currentSortColumn = 'column1';
    if (chart === 'topRecordsExchange') {
      this.activeTab = 'childrenMoving';
    }

    this.drillDownInit();
  }

  //Pull in Drilldown data
  drillDownInit() {
    this.drillDown = {
      beginRow: 1,
      endRow: 20,
      stateName: this.recordsExchangeFilterParams.selectedState,
      performancePeriod: this.recordsExchangeFilterParams.periodType,
      chartType: this.chart,
      tab: this.activeTab
    };
    if (this.chart === 'dataReqWorklist') {
      this.drillDown.sortField = 'MONTH_DESC';
    } else {
      if (this.activeTab == 'moveNotices' || this.activeTab == 'dataRequests')
        this.drillDown.sortField = 'WORKLIST_ID_DESC';
      else
        this.drillDown.sortField = 'MSIX_ID_DESC';
    }
    this.updateDrilldown(this.drillDown);
  }

  drillDownWorklist(param) {
    /*if (this.chart === 'dataReqWorklist' && data.column === 'column4' && !this.authRoleClasses.dataRequest_ga) {
      //open the student details page from hyperlink MSIX ID in a new tab
      var url = '/msix/#/studentDetails';
      url += '?msixId=' + data.drillDownCount;
      window.open(url, '_blank');
    }*/
    if ((this.chart === 'dataReqWorklist' && param.column === 'column2')
      || ((this.activeTab == 'moveNotices' || this.activeTab == 'dataRequests') && param.column === 'column1')) {
      var query = {
        queryType: 'cor',
        worklistId: param.data.worklistID
      };

      let apiCall = this.worklistService.searchCOR(query);
      let subscribed = apiCall.subscribe({
        next: data => {
          var worklistType = data.results.partialWlList[0].worklistType.replace(/([A-Z])/g, " $1").trim();

          const query = {
            worklistId: param.data.worklistID,
            worklistType: worklistType,
            worklistStatus: ''
          };
          const queryParams = new HttpParams({ fromObject: query }).toString();
          const url = `#/worklistHistoryRecord?${queryParams}`;
          window.open(url, '_blank')
          subscribed.unsubscribe();
        },
        error: error => {
          console.log("Error - Records Exchange.drillDownWorklist()", error);
          this.toastService.showToast('Error occured while retrieving Worklist record. Please contact the MSIX Help Desk if you keep getting this error.', 0);
          subscribed.unsubscribe();
        }
      });
    }
  }

  updateDrilldown(drillDown) {
    //this.blankRes = true; // This will remove the reports-table from the DOM and prevent the persistence of sorting across tabs (MSIX-4936)
    this.tabChangeDone = false;
    this.drillDownData = {};
    this.reportGroupVar = 'dashboard';
    this.reportDrillDownVar = 'recordsExchangeDrilldown';
    let apiCall = this.reportsService.drillDownView(drillDown, this.reportGroupVar, this.reportDrillDownVar);
    let subscribed = apiCall.subscribe({
      next: data => {
        if (data.rows.length === 0) {
          this.blankRes = true;
          this.tabChangeDone = true;
        } else {
          console.log("RecordsExchange updateDrilldown(): ", data);
          this.blankRes = false;
          this.tabChangeDone = true;
          this.drillDownData = data;
          this.drillDownData.query = drillDown;
          this.drillDownInfo = {
            drillDown: drillDown, 
            reportGroupVar: this.reportGroupVar,
            reportDrillDownVar: this.reportDrillDownVar
          }
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - Records Exchange.updateDrilldown()", error);
        subscribed.unsubscribe();
      }
    });
  }

  //Sort Drill Down Data
  handleDrillDownSortClick(newSortKey) {
    this.drillDown.sortField = newSortKey.sortKey;
    this.updateDrilldown(this.drillDown);
  }

  //Updates which column and direction the table will be sorted by
  sortByColumn(data: {column: string, ascending: boolean}) {
    this.currentSortColumn = data.column;
    this.sortAscending = data.ascending;
  }

  //Change which tab the user is viewing
  changeTab(tab) {
    this.activeTab = tab;
    this.drillDownInit();
  }

  //stringifies an object
  jsonStringify(obj) {
    return JSON.stringify(obj);
  }
}

// Separate component for the RecordExchange report's filter
export interface FilterParameters {
  params: {
    rptGrping: string,
    selectedState: string, 
    periodType: string,
    authRoleClasses: any,
    dateObj: any,
    states: any[]
  }
}
@Component({
  selector: 'filterRecordsExchange',
  templateUrl: './filterRecordsExchange.html',
  styleUrls: ['./recordsExchange.component.scss']
})
export class FilterRecordsExchange {
  recordsExchangeFilterParams: {
      rptGrping: string, 
      selectedState: string,
      periodType: string,
      authRoleClasses: any,
      dateObj: any,
      states: any[]
  };
  constructor(
    public dialogRef: MatDialogRef<FilterRecordsExchange>,
    public responsiveUI: ResponsiveUIService,
    @Inject(MAT_DIALOG_DATA) public data: FilterParameters) {
      this.recordsExchangeFilterParams = cloneDeep(this.data.params);
      if (this.recordsExchangeFilterParams.rptGrping == '' || !this.recordsExchangeFilterParams.rptGrping){
        this.resetFilter();
      }
  }

  resetFilter() {
    if (this.recordsExchangeFilterParams.authRoleClasses.dataAdmins) {
      this.recordsExchangeFilterParams = {
        rptGrping: 'State',
        selectedState: this.data.params.selectedState,
        periodType: 'CURRENT',
        authRoleClasses: this.data.params.authRoleClasses,
        dateObj: this.data.params.dateObj,
        states: this.data.params.states
      };
    } else {
      this.recordsExchangeFilterParams = {
        rptGrping: 'National',
        selectedState: "",
        periodType: 'CURRENT',
        authRoleClasses: this.data.params.authRoleClasses,
        dateObj: this.data.params.dateObj,
        states: this.data.params.states
      };
    }
  }

  onSubmit(){
    this.dialogRef.close(this.recordsExchangeFilterParams);
  }

  selectState() {
    this.recordsExchangeFilterParams.rptGrping = 'State';
  }

  selectNational() {
    this.recordsExchangeFilterParams.rptGrping = 'National';
    this.recordsExchangeFilterParams.selectedState = '';
  }
  selectDrilldownState(state) {
    this.recordsExchangeFilterParams.selectedState = state;
  }

  selectPeriodType(periodType){
    this.recordsExchangeFilterParams.periodType = periodType;
  }

  onClose() {
    this.recordsExchangeFilterParams = cloneDeep(this.data.params);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.recordsExchangeFilterParams = cloneDeep(this.data.params);
    this.dialogRef.close();
  }
}

// Separate component for the RecordExchange report's Learn More dialog box
export interface LearnMoreParameters {
  params: {
    dataDashboards: boolean, 
    dataAdmins: boolean
  }
}
@Component({
  selector: 'learnMoreRecordsExchange',
  templateUrl: 'learnMoreRecordsExchange.html',
  styleUrls: ['./recordsExchange.component.scss']
})
export class LearnMoreRecordsExchange {
  authRoleClasses: any = {};
  constructor(
    public dialogRef: MatDialogRef<LearnMoreRecordsExchange>,
    @Inject(MAT_DIALOG_DATA) public data: LearnMoreParameters
  ) {
    this.authRoleClasses = data.params;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the RecordExchange report's drilldown Learn More dialog box
@Component({
  selector: 'learnMoreDDRecordsExchange',
  templateUrl: 'learnMoreDDRecordsExchange.html',
  styleUrls: ['./recordsExchange.component.scss']
})
export class LearnMoreDDRecordsExchange {
  constructor(
    public dialogRef: MatDialogRef<LearnMoreDDRecordsExchange>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
