import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PrivacyComponent } from './views/privacy/privacy.component';
import { ErrorPageComponent } from './views/error-page/error-page.component';
import { LoginComponent, RulesOfBehaviorComponent, CheckEmailMsgComponent } from './views/login/login.component';
import { AnswerChallengeQuestionsComponent } from './views/challengeQuestions/answerChallengeQuestions.component';
import { SetChallengeQuestionsComponent } from './views/challengeQuestions/setChallengeQuestions.component';
import { PasswordUpdateComponent } from './views/passwordUpdate/passwordUpdate.component';
import { RequestAccountComponent } from './views/request-account/request-account.component';
import { ResourcesComponent } from './views/resources/resources.component'
import { HelpComponent } from './views/help/help.component'
import { PasswordResetComponent } from './views/password-reset/passwordReset.component';
import { AdminSearchResultsComponent } from './views/adminSearchResults/adminSearchResults.component'
import { TrainingComponent } from './views/training/training.component';
import { HomeComponent } from './views/home/home.component';
import { DataAdminComponent } from './views/dataAdmin/dataAdmin.component';
import { SearchResultsComponent } from './views/search-results/search-results.component';
import { StudentDetailsComponent } from './views/student-details/student-details.component';
import { ReportsComponent } from './views/reports/reportsMain/reports.component';
import { AppConstants } from './config/index.constants';
import { UserAdminComponent } from './views/userAdmin/userAdmin.component';
import { UserDetailsComponent, NewUser } from './views/userDetails/userDetails.component';
import { SelectJobTitlesComponent } from './views/selectJobTitles/selectJobTitles.component';
import { SavedListComponent } from './views/savedList/savedList.component';
import { WorklistComponent } from './views/worklist/worklist.component';
import { WorklistHistoryRecordComponent } from './views/worklistHistoryRecord/worklistHistoryRecord.component';
import { WorklistReassignComponent } from './views/worklistReassign/worklistReassign.component';
// Reports - Dashboards section
import { AccountManagementComponent } from './views/reports/dashboards/accountManagement/accountManagement.component';
import { DataManagementComponent } from './views/reports/dashboards/dataManagement/dataManagement.component';
import { RecordsExchangeComponent } from './views/reports/dashboards/recordsExchange/recordsExchange.component';
import { ChildMobilityComponent } from './views/reports/dashboards/childMobility/childMobility.component';
import { MepPopulationComponent } from './views/reports/dashboards/mepPopulation/mepPopulation.component';
import { StudentAchievementComponent } from './views/reports/dashboards/studentAchievement/studentAchievement.component';
// Reports - Data Quality section
import { FileSubmissionComponent } from './views/reports/dataQuality/fileSubmission/fileSubmission.component';
import { DataValidityComponent } from './views/reports/dataQuality/dataValidity/dataValidity.component';
import { DataLogicComponent } from './views/reports/dataQuality/dataLogic/dataLogic.component';
import { DataCompletenessComponent } from './views/reports/dataQuality/dataCompleteness/dataCompleteness.component';
import { PotentialDuplicatesComponent } from './views/reports/dataQuality/potentialDuplicates/potentialDuplicates.component';
// Reports - Student Mobility section
import { GeneralMoveFromComponent } from './views/reports/studentMobility/generalMoveFrom/generalMoveFrom.component';
import { GeneralMoveToComponent } from './views/reports/studentMobility/generalMoveTo/generalMoveTo.component';
// Reports - User Account section
import { AccountListComponent } from './views/reports/userAccount/accountList/accountList.component';
import { AccountMetricsComponent } from './views/reports/userAccount/accountMetrics/accountMetrics.component';
//import { MyAccountComponent } from './components/my-account/my-account.component';
import { Myaccount2Component } from './views/myaccount2/myaccount2.component';
import { MsixUsageComponent } from './views/reports/msixUsage/msixUsage/msixUsage.component';
import { StudentRecordsExchangeComponent } from './views/reports/msixUsage/studentRecordsExchange/studentRecordsExchange.component';
import { ChildDetailsComponent } from './views/child-details/child-details.component';
import { WorklistHistoryComponent } from './views/work-history/work-history.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';


const routes: Routes = [
  
  { path: '', component: PrivacyComponent, pathMatch: 'full', data: { showHeader: true, showFooter: true } },
  { path: 'home', component: HomeComponent, data: { showHeader: true, showFooter: false }},
  { path: 'login', component: LoginComponent, data: { showHeader: true, showFooter: true } },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { showHeader: true, showFooter: false } },
  { path: 'checkEmailMsg', component: CheckEmailMsgComponent, data: { showHeader: true, showFooter: false } },
  { path: 'rulesOfBehavior', component: RulesOfBehaviorComponent, data: { showHeader: true, showFooter: false } },
  { path: 'answerChallengeQuestions', component: AnswerChallengeQuestionsComponent, data: { showHeader: true, showFooter: false } },
  { path: 'setChallengeQuestions', component: SetChallengeQuestionsComponent, data: { showHeader: true, showFooter: false } },
  { path: 'passwordUpdate', component: PasswordUpdateComponent, data: { showHeader: true, showFooter: false } },
  { path: 'requestAccount', component: RequestAccountComponent, data: { showHeader: true, showFooter: false } },
  { path: 'resources', component: ResourcesComponent, data: { showHeader: true, showFooter: false } },
  { path: 'help', component: HelpComponent, data: { showHeader: true, showFooter: false } },
  { path: 'pwReset', component: PasswordResetComponent, data: { showHeader: true, showFooter: true } },
  { path: 'adminSearchResults', component: AdminSearchResultsComponent, data: { showHeader: true, showFooter: true } },
  { path: 'training', component: TrainingComponent, data: { showHeader: true, showFooter: false } },
  { path: 'dataAdmin', component: DataAdminComponent, data: { showHeader: true, showFooter: false } },
  { path: 'searchResults', component: SearchResultsComponent, data: { showHeader: true, showFooter: false } },
  { path: 'studentDetails', component: StudentDetailsComponent, data: { showHeader: true, showFooter: false } },
  { path: 'reports/ageBirthThroughTwo/report/childDetails', component: ChildDetailsComponent, data: { showHeader: true, showFooter: false } },
  { path: 'reports', component: ReportsComponent, data: { showHeader: true, showFooter: false } },
  
  
  { path: 'userAdmin', component: UserAdminComponent, data: { showHeader: true, showFooter: false } },
  { path: 'newUser', component: NewUser, data: { showHeader: true, showFooter: false } },
  { path: 'userDetails', component: UserDetailsComponent, data: { showHeader: true, showFooter: false } },
  { path: 'selectJobTitles', component: SelectJobTitlesComponent, data: { showHeader: true, showFooter: false } },
  { path: 'savedList', component: SavedListComponent, data: { showHeader: true, showFooter: false } },
  { path: 'worklist', component: WorklistComponent, data: { showHeader: true, showFooter: false } },
  { path: 'worklistHistory', component: WorklistHistoryComponent, data: { showHeader: true, showFooter: false } },
  { path: 'worklistHistoryRecord', component: WorklistHistoryRecordComponent, data: { showHeader: true, showFooter: false } },
  { path: 'worklistReassign', component: WorklistReassignComponent, data: { showHeader: true, showFooter: false } },
  // Reports - Dashboards section
  { path: 'accountManagement', component: AccountManagementComponent, data: { showHeader: true, showFooter: false } },
  { path: 'dataManagement', component: DataManagementComponent, data: { showHeader: true, showFooter: false } },
  { path: 'childMobility', component: ChildMobilityComponent, data: { showHeader: true, showFooter: false } },
  { path: 'recordsExchange', component: RecordsExchangeComponent, data: { showHeader: true, showFooter: false } },
  { path: 'mepPopulation', component: MepPopulationComponent, data: { showHeader: true, showFooter: false } },
  { path: 'studentAchievement', component: StudentAchievementComponent, data: { showHeader: true, showFooter: false } },
  // Reports - Student Information Section - lazy loaded
  {
    path: 'reports/studentInfo',
    data: { showHeader: true, showFooter: false },
    loadChildren: () => import('./views/reports/studentInfo/student-info-reports.module').then(m => m.StudentInfoReportsModule)
  },
  // Reports - Worklists Section - lazy loaded
  {
    path: 'reports/worklists',
    data: {showHeader: true, showFooter: false},
    loadChildren: () => import('./views/reports/worklists/worklists.module').then(m => m.WorklistsReportsModule)
  },
  // Reports - Reconciliation - lazy loaded
  {
    path: 'reports/reconciliation',
    data: {showHeader: true, showFooter: false},
    loadChildren: () => import('./views/reports/reconciliation/reconciliation.module').then(m => m.ReconciliationModule)
  },
  // Reports - Data Quality section
  { path: 'fileSubmission', component: FileSubmissionComponent, data: { showHeader: true, showFooter: false } },
  { path: 'dataValidity', component: DataValidityComponent, data: { showHeader: true, showFooter: false } },
  { path: 'dataLogic', component: DataLogicComponent, data: { showHeader: true, showFooter: false } },
  { path: 'dataCompleteness', component: DataCompletenessComponent, data: { showHeader: true, showFooter: false } },
  { path: 'potentialDuplicates', component: PotentialDuplicatesComponent, data: { showHeader: true, showFooter: false } },
  
 

  // Merge and Split by User
  
  // Reports - Student Mobility section
  { path: 'generalMoveFrom', component: GeneralMoveFromComponent, data: { showHeader: true, showFooter: false } },
  { path: 'generalMoveTo', component: GeneralMoveToComponent, data: { showHeader: true, showFooter: false } },

  //Reports - User Account section
  { path: 'accountList', component: AccountListComponent, data: { showHeader: true, showFooter: false } },
  { path: 'accountMetrics', component: AccountMetricsComponent, data: { showHeader: true, showFooter: false } },
  { path: 'myaccount', component: Myaccount2Component, data: { showHeader: true, showFooter: false } },
  //Reports - MSIX Usage
  { path: 'reports/msixUsage/userPageCounts', component: MsixUsageComponent, data: { showHeader: true, showFooter: false } },
  { path: 'reports/msixUsage/studentRecordsExchange', component: StudentRecordsExchangeComponent, data: { showHeader: true, showFooter: false } },
  // WARNING: This should be the last path to match. Following path (i.e **) matches everthing so if user tries an URL that doesn't match with one of our paths, then they will receive Error Page.
  { path: '**', redirectTo: '/home' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      useHash: true
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }