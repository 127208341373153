<div class="dataAdmin-page background-creative" [ngClass]="{'main-padding' : (!responsiveUI.isMobile && !responsiveUI.isTablet)}">
  <div *ngIf="states">
    <div *ngIf="responsiveUI.isDesktop">
      <div class="layout-flex-row" layout-align=" start">
        <h1 style="display: flex; flex: 80%" class="layout-margin left-right-margin-50">State & Region Administration</h1>
        <button *ngIf="stateRegionAdmin" class="md-button regionButton flex-20" 
          (click)="addRegion($event)">
          Add Region
        </button>
      </div>

      <div class="layout-margin-worklist-module layout-padding-worklist-module">
        <!--Settings Start-->
        <div class="dataModules">
          <div class="tableHeader">
            <div class="layout-flex-row tableRow headerRow">
              <p>States</p>
            </div>
          </div>
        </div>
        <!--Settings End-->

        <!-- states -->
        <div *ngFor="let state of states; let idx = index" class="newtable2Row" [ngClass]="{activeOutline: showStateIndex === idx}">

          <div (click)="showStateFn(idx, state.stateKey, state.name)" class="layout-flex-row" style="flex: auto">
            <div style="display: flex; flex: 95%" class="greyBottomBorder">
              <!-- <div (click)="showStateFn(idx, state.stateKey, state.name)" class="flex-container section1"> -->
              <div class="flex-container section1">
                <h3 class="bold" style="display: flex; flex: auto">{{state.name}}</h3>
              </div>
            </div>
            <!-- <div (click)="showStateFn(idx, state.stateKey, state.name)" class="expandColumn-green flex-container" [ngClass]="{borderBottomGreen2px: showStateIndex === idx, borderBottomWhite2px: !(showStateIndex === idx)}" style="display: flex; flex: auto"> -->
            <div class="expandColumn-green flex-container" [ngClass]="{borderBottomGreen2px: showStateIndex === idx, borderBottomWhite2px: !(showStateIndex === idx)}" style="display: flex; flex: auto">
              <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(showStateIndex === idx)"></i>
              <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="showStateIndex === idx"></i>
            </div>
          </div>

          <div class="interiorContainer" *ngIf="shouldDisplayStateList && shouldDisplayStateList[idx]">
            <div class="layout-flex-row" style="flex: auto">
              <span class="layout-flex-column" style="flex: 5%"></span>
              <div style="flex: 40%" class="layout-flex-column stateRegionCol">
                <div style="flex:auto" class="layout-flex-row section-title">
                  <div class="block2" style="display: flex; flex: auto">State</div>
                </div>
                <div class="flexSpaceBetween layout-flex-row">
                  <p style="display: flex; flex: 80%">Use district of residence for worklist functionality</p>
                  <mat-slide-toggle (change)="disableUpdateSettings()" ngDefaultControl style="display: flex; flex: 20%" [(ngModel)]="switch.regionsEnabled" aria-label="Save Record Switch"></mat-slide-toggle>
                </div>
              </div>
              <span class="layout-flex-column" style="flex: 10%"></span>
              <div style="flex: 40%" class="layout-flex-column stateRegionCol">
                <div style="flex: auto" class="layout-flex-row section-title">
                  <div class="block2" style="display: flex; flex: auto">Regions</div>
                </div>
                <div class="flexSpaceBetween layout-flex-row">
                  <p style="display: flex; flex: 80%">Use regional structure for user administration</p>
                  <mat-slide-toggle (change)="disableUpdateSettings()" ngDefaultControl style="display: flex; flex: 20%" [(ngModel)]="switch.rua" aria-label="Save Record Switch"></mat-slide-toggle>
                </div>
                <div class="flexSpaceBetween layout-flex-row">
                  <p style="display: flex; flex: 80%">Use regional structure for data administration</p>
                  <mat-slide-toggle (change)="disableUpdateSettings()" ngDefaultControl style="display: flex; flex: 20%" [(ngModel)]="switch.rda" aria-label="Save Record Switch"></mat-slide-toggle>
                </div>
              </div>
              <span class="layout-flex-column" style="flex: 5%"></span>
            </div>
            
            <div class="layout-flex-row" style="padding-right: 5%;" class="align-right">
              <button [disabled]="disableUpdateButton" class="md-button updateButton" (click)="submitFlags()" aria-label="Update Settings">
                Update Settings
              </button>
              <!-- <span></span> -->
            </div>

            <div class="layout-flex-row">
              <span class="flex-5"></span>
              <div class="worklist-header-titles layout-flex-row" style="flex: 100%">
                <!-- <div style="display: flex; flex-direction: row; flex: auto" layout-align="start start"> -->
                  <div class="block2" style="display: flex; flex: 30%" class="region block2">Region</div>
                  <span style="display: flex; flex: 30%"></span>
                  <div class="block2" style="display: flex; flex: 40%" class="status block2">Status</div>
                <!-- </div> -->
              </div>
              <span class="flex-5"></span>
            </div>
            <div class="layout-flex-row layout-row">
              <span class="flex-5"></span>

              <div class="flex-90 layout-flex-column subAccordion">
                <div *ngFor="let region of state.regionMap | sortBy:'asc': 'name'; let indx = index " class="subAccordionBackground subAccordionMargin" [ngClass]="{borderLightGreyOpen: showRegionIndex === indx, borderLightGreyClosed: showRegionIndex !== indx}">
                  <div class="layout-align-center-none layout-flex-row subAccordionBorderBottom flex">
                    <div class="flex-95">
                      <div layout-align="start start" (click)="showRegionFn(indx)" class="layout-flex-row flex-container section1">
                        <p class="flex-30">{{region.name}}</p>
                        <a class="flex-5" *ngIf="showRegionIndex === indx && stateRegionAdmin" (click)="editRegion($event, region.name, region.description, region.key, region.active)">
                          <span>Edit</span>
                        </a>
                        <span class="flex-5" *ngIf="showRegionIndex !== indx"></span>
                        <span class="flex-30"></span>
                        <p class="flex-40">{{(region.active) ? "Enabled" : "Disabled"}}</p>
                      </div>
                    </div>
                    <div (click)="showRegionFn(indx)" class="expandColumn-white flex-container flex">
                      <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(showRegionIndex === indx)"></i>
                      <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="showRegionIndex === indx"></i>
                    </div>
                  </div>

                  <div *ngIf="showRegionIndex === indx">
                    <div class="worklist-history-accordion" *ngIf="stateRegionAdmin; else notStateRegionAdmin">
                      <div style="display: flex" class="worklist-header-titles">
                        <div style="width: 40%; margin-bottom: .5rem;">District</div>
                        <div style="width: 15%; margin-bottom: .5rem;">State District ID</div>
                        <div style="width: 15%; margin-bottom: .5rem;">School ID</div>
                        <div style="width: 30%; margin-bottom: .5rem;">Region Membership</div>
                      </div>
                      
                      <div *ngFor="let district of region.districts; let districtIndex = index" [ngClass]="{tableTextBottomBorder: !stateRegionAdmin}" 
                      style="display: flex; align-items: center;">
                        <p style="width: 40%">{{district.name}}</p>
                        <p style="width: 15%">{{district.stateId}}</p>
                        <p style="width: 15%">{{district.ncesId}}</p>
                        <div style="width: 30%">
                          <mat-form-field style="width: 100%;">
                            <mat-select [(ngModel)]="regionDistricts[districtIndex]" aria-label="Region Membership">
                              <mat-option *ngFor="let region2 of state.regionMap | keyvalue" 
                                [selected]="region2.value.name == region.name ? true : false"
                                (change)="regionSelected(districtIndex, district, region, region2)"
                                value="{districtID: district.key, districtName: district.name, stateID:
                                   district.stateId, currentRegion: region2.value.name, originalRegion: 
                                   region.name, currentRegionKey: region2.key}">
                                {{region2.value.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="flex-5"></span>
                  <ng-template #notStateRegionAdmin>
                    <div class="worklist-history-accordion">
                      <div style="display: flex"  class="worklist-header-titles">
                        <div style="width: 50%; margin-bottom: .5rem;">District</div>
                        <div style="width: 25%; margin-bottom: .5rem;">State District ID</div>
                        <div style="width: 25%; margin-bottom: .5rem;">School ID</div>
                      </div>
                      <div *ngFor="let district of region.districts; let districtIndex = index" [ngClass]="{tableTextBottomBorder: !stateRegionAdmin}" 
                      style="display: flex; align-items: center;">
                        <p style="width: 50%">{{district.name}}</p>
                        <p style="width: 25%">{{district.stateId}}</p>
                        <p style="width: 25%">{{district.ncesId}}</p>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="layout-row updateContainer layout-align-end-center">
                  <button *ngIf="stateRegionAdmin" class="md-button updateButton flex" (click)="submitUpdatedDistrict()" type="submit">
                    Update
                  </button>
                </div>
              </div>

              <span class="flex-5"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-content-padding layout-padding header-offset-70" *ngIf="responsiveUI.isMobile || responsiveUI.isTablet">
        <h1 flex="100" class="layout-margin">State & Region Administration</h1>
        <p>To view the State & Region Administration, please access this page on desktop.</p>
      </div>

    </div>
  </div>
</div>