<div class="wrapper">
  <div class="title-wrapper">
    <h3 class="title">{{title}}</h3>
  </div>

  <form class="content" name="worklist-history-search-form">
    <div class="sub-title">
      <p>
        At least one of the search parameters is required in order to return valid results. You can only search for
        worklists assigned to, or originating from, users in your state.
      </p>
      <button (click)="clearSearchFields()" [disabled]="!worklistQuery" type="button" class="clear-btn"
        tabindex="0" aria-label="Clear">
        <i class="fa fa-times" aria-hidden="true"></i>
        <span>Clear</span>
      </button>
    </div>

    <div class="form-fields">
      
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Student First Name</mat-label>
          <input matInput [(ngModel)]="worklistQuery.studentFirstName" name="studentFirstName" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Student Last Name</mat-label>
          <input matInput [(ngModel)]="worklistQuery.studentLastName" name="studentLastName" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>User's First Name</mat-label>
          <input matInput [(ngModel)]="worklistQuery.userFirstName" name="userFirstName" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>User's Last Name</mat-label>
          <input matInput [(ngModel)]="worklistQuery.userLastName" name="userLastName" />
        </mat-form-field>

        <div class="dates">
          <div class="dates-title">Worklist created between</div>
          <div class="date-range">
            <mat-form-field appearance="outline">
              <mat-label>MM</mat-label>
              <input matInput [(ngModel)]="worklistQuery.createdFromDateMM" name="createdFromDateMM" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>DD</mat-label>
              <input matInput [(ngModel)]="worklistQuery.createdFromDateDD" name="createdFromDateDD" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>YYYY</mat-label>
              <input matInput [(ngModel)]="worklistQuery.createdFromDateYYYY" name="createdFromDateYYYY" />
            </mat-form-field>

            <div class="flex dateDash">
              <p >-</p>
            </div>

            <mat-form-field appearance="outline">
              <mat-label>MM</mat-label>
              <input matInput [(ngModel)]="worklistQuery.createdToDateMM" name="createdToDateMM" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>DD</mat-label>
              <input matInput [(ngModel)]="worklistQuery.createdToDateDD" name="createdToDateDD" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>YYYY</mat-label>
              <input matInput [(ngModel)]="worklistQuery.createdToDateYYYY" name="createdToDateYYYY" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Student Student ID</mat-label>
          <input matInput [(ngModel)]="worklistQuery.stateStudentId" name="stateStudentId" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Current Worklist Status</mat-label>
          <mat-select [ngModelOptions]="{standalone: true}"
            [(ngModel)]="worklistQuery.worklistStatus" aria-label="State">
            <mat-option value="initiated" *ngIf="worklistHistorySearchType == 'corWorklistHistory'">Initiated
            </mat-option>
            <mat-option value="active" *ngIf="worklistHistorySearchType == 'corWorklistHistory'">Active</mat-option>
            <mat-option value="resolved">Resolved</mat-option>
            <mat-option value="unresolved" *ngIf="worklistHistorySearchType == 'mergeAndSplitWorklistHistory'">
              Unresolved</mat-option>
            <mat-option value="canceled">Canceled</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="worklistHistorySearchType == 'mergeAndSplitWorklistHistory'" appearance="outline">
          <mat-label>Worklist Type</mat-label>
          <mat-select  [ngModelOptions]="{standalone: true}" [(ngModel)]="worklistQuery.worklistType" aria-label="State">
            <mat-option value="NEAR MATCH">Near Match</mat-option>
            <mat-option value="VALIDATE MERGE">Validate Merge</mat-option>
            <mat-option value="VALIDATE USER INITIATED MERGE">Validate User Initiated Merge</mat-option>
            <mat-option value="VALIDATE SPLIT">Validate Split</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="worklistHistorySearchType == 'corWorklistHistory'" appearance="outline">
          <mat-label>Initiating State</mat-label>
          <mat-select [ngModelOptions]="{standalone: true}" [(ngModel)]="worklistQuery.initiatingState">
            <mat-option *ngFor="let state of states; let i = index" [value]="i + 1">
              {{state.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field appearance="outline">
          <mat-label>Worklist ID</mat-label>
          <input matInput [(ngModel)]="worklistQuery.worklistId" name="worklistId" />
        </mat-form-field>

        <div class="dates">
          <div class="dates-title">Worklist resolved between</div>
          <div class="date-range">
          <mat-form-field appearance="outline">
            <mat-label>MM</mat-label>
            <input matInput [(ngModel)]="worklistQuery.resolvedFromDateMM" name="resolvedFromDateMM" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>DD</mat-label>
            <input matInput [(ngModel)]="worklistQuery.resolvedFromDateDD" name="resolvedFromDateDD" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>YYYY</mat-label>
            <input matInput [(ngModel)]="worklistQuery.resolvedFromDateYYYY" name="resolvedFromDateYYYY" />
          </mat-form-field>

          <div flex class="dateDash">
            <p>-</p>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>MM</mat-label>
            <input matInput [(ngModel)]="worklistQuery.resolvedToDateMM" name="resolvedToDateMM" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>DD</mat-label>
            <input matInput [(ngModel)]="worklistQuery.resolvedToDateDD" name="resolvedToDateDD" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>YYYY</mat-label>
            <input matInput [(ngModel)]="worklistQuery.resolvedToDateYYYY" name="resolvedToDateYYYY" />
          </mat-form-field>
        </div>
        </div>
      </div>
    </div>
    
    <div>
      <div>
        <p *ngIf="showCreateErrMsg">The Worklist created end date must be after the start date</p>
      </div>
      <div>
        <p *ngIf="showResolveErrorMessage">The Worklist resolved end date must be after the start date</p>
      </div>
    </div>
    <button class="md-button action-button"
      [ngClass]="{'no-top-bottom-right-margin-padding': responsiveUI.isMobile, 'no-top-bottom-margin-padding': !responsiveUI.isMobile}"
      type="Submit" (click)="searchWorklistHistory()" [disabled]="searchValidationLogic()">Search
    </button>
  </form>
  
</div>