import { Component, Inject } from '@angular/core';
import * as moment from 'moment';
import * as angular from "angular";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { DataLogicFilter } from './types';

@Component({
  selector: 'dataLogic',
  templateUrl: './dataLogic.component.html',
  styleUrls: ['./dataLogic.component.scss']
})
export class DataLogicComponent {
  exportCall: string = 'dataLogicInfoExport';
  drilldownExportCall: string = 'dataLogicDrillDownInfoExport';
  showDrill: boolean = false;
  drillDown: any = {};
  drillDownData: any = {};
  reportInfo: any = {};
  showFilter: boolean = false;

  // holds the list of states
  states: any[] = [];

  // holds the set of selected stateKeys
  fullStateArray: any[] = [];

  // holds the current active filter parameters
  filterParameters: any = {
    dataViewType: "State",
    dataPeriodType: "No Period",
    noyes: "no",
    authRoleClasses: {},
    stateList: [],
    selectedStatesArray: []
  };
  activeFilter: any = {};
  activeSelectedStatesArray: any[] = [];

  //this object holds Report Data for initial display via reportInit()
  response: any = {};
  blankRes: boolean = false;

  //holds values for maintaining sort
  sortAscending: boolean = true;
  currentSortColumn: string = 'column1';

  // holds the API call variables for ReportsService
  reportGroupVar: string = 'studentInfo';
  reportDrillDownVar: string = 'dataLogicDrillDownInfo';

  //this object holds current & recent reporting period for display purposes
  displayData: any = {
    current: "",
    recent: "",
    state: "",
    recordName: ""
  };

  //reportName = $location.path().split('/')[3];
  constructor(
    public dialog: MatDialog,
    private reportsService: ReportsService,
    private reportInfoService: ReportInfoService,
    private _router: Router) {

  }

  ngOnInit() {
    this.getReportInfo();
  }

  goToReport() {
    this.showDrill = false;
    this.blankRes = false;
  }

  // gets info needed to generate report
  async getReportInfo() {
    this.reportInfo = await this.reportInfoService.getReportInfo(['dataQuality_all', 'dataQuality']);

    this.mineAndSetStatesArray(this.reportInfo.stateData);
    this.setSelectedStates();
    // generates report
    this.filterActivate();
  }

  // removes DC, PR, and WY from states array and sets the selectedStatesArray with list of states
  mineAndSetStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i].stateKey
        }
        this.states.push(responseData[i]);
        this.fullStateArray.push(obj);
        this.filterParameters.selectedStatesArray.push(responseData[i].stateKey);
        this.filterParameters.stateList.push(obj);
      }
    }
  }

  // sets the selected states in the filter object, based on user role
  setSelectedStates() {
    var roles = this.reportInfo.authRoleClasses;
    if (roles.dataQuality == true) {
      this.filterParameters.stateList = this.filterParameters.selectedStatesArray;
    } else {
      let arr = [{ key: this.reportInfo.userState }]
      this.filterParameters.stateList = arr;
    }
  }

  // Trigger Filter Modal
  filterActivate() {
    //combine the fields necessary into 1 object (filterParameters) to pass to the filter
    this.filterParameters.states = JSON.parse(JSON.stringify(this.states));
    this.filterParameters.fullStateArray = JSON.parse(JSON.stringify(this.fullStateArray));
    this.filterParameters.userState = JSON.parse(JSON.stringify(this.reportInfo.userState));
    this.filterParameters.recentPeriod = JSON.parse(JSON.stringify(this.reportInfo.recentPeriod));
    this.filterParameters.currentPeriod = JSON.parse(JSON.stringify(this.reportInfo.currentPeriod));
    this.filterParameters.authRoleClasses = JSON.parse(JSON.stringify(this.reportInfo.authRoleClasses));
    this.filterParameters.userStateName = JSON.parse(JSON.stringify(this.reportInfo.userStateName));

    this.showFilter = true;
    
    //Set activeFilter before opening to set back to if not submitting
    this.activeFilter = cloneDeep(this.filterParameters);
    const dialogRef = this.dialog.open(FilterDataLogic, {
      height: 'auto',
      width: '58%',
      minHeight: '325px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: { params: this.filterParameters },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filterParameters = result;
        this.submitFilter();
      } else {
        this.filterParameters = this.activeFilter;
      }
      this.showFilter = false;
    });
  }

  // initialize the report
  submitFilter() {
    console.log("Current filterParameters before submitting report: ", this.filterParameters);

    if (this.filterParameters.stateList.length === 0) {
      this.blankRes = true;
      this.response = [];
    } else {
      let apiCall = this.reportsService.dataLogicInfo({
        dataViewType: this.filterParameters.dataViewType,
        stateList: this.filterParameters.stateList,
        dataPeriodType: this.filterParameters.dataPeriodType
      });
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("DataLogic submitFilter(): ", data);
          this.blankRes = false;
          // this.filterParameters = JSON.parse(JSON.stringify(this.activeFilter));

          this.response = data;
          this.sortAscending = true;
          this.currentSortColumn = 'column1';
          subscribed.unsubscribe();
        },
        error: error => {
          console.log("Error - DataLogic submitFilter(): ", error);
          this.blankRes = false;
          subscribed.unsubscribe();
        }
      });
    }
  }

  // Learn More Activate
  learnMoreActivate() {
    this.showFilter = true;
    if (this.showDrill) {
      const dialogRef = this.dialog.open(DataLogicDDLearnMore, {
        height: 'auto',
        width: '58%',
        minHeight: '325px',
        maxHeight: 'fit-content',
        maxWidth: '95vw',
        hasBackdrop: true,
        disableClose: false,
      });
      dialogRef.afterClosed().subscribe(result => {
        this.showFilter = false;
      });
    } else {
      const dialogRef = this.dialog.open(DataLogicLearnMore, {
        height: 'auto',
        width: '50%',
        minHeight: '325px',
        maxHeight: 'fit-content',
        maxWidth: '95vw',
        hasBackdrop: true,
        disableClose: false,
      });
      dialogRef.afterClosed().subscribe(result => {
        this.showFilter = false;
      });
    }
  }

  handleMSIXIDClick(msixId: string) {
    var url = '/msix/#/studentDetails';
    url += '?msixId=' + msixId;
    window.open(url, '_blank');
  }

  //Pull in Drilldown data
  drillDownInit(data) {
    this.reportsService.sortColumn$.next(null); // emit null, so reportsTable does not set sort column saved in reports service for the drill down

    let reportData = data.data;
    this.displayData.state = reportData.state;
    this.displayData.recordName = reportData.recordName;

    this.drillDown = {
      studentIDFilter: {
        stateKey: null,
        districtId: null,
        category: "No Data Submitted",
        category2: "No Data Submitted",
        beginRow: 1,
        endRow: 20,
        sortFields: "MSIX_ID_ASC"
      },
      dataPeriodType: this.filterParameters.dataPeriodType,
      studentDataQualityInfo: {
        state: reportData.state,
        recordType: reportData.recordType,
        recordName: reportData.recordName,
        qualityStatus: null,
        recordCount: 3
      }
    };
    this.drillDown.exportCount = reportData.recordCount;

    //show Drill Down - hide Report
    this.showDrill = !this.showDrill;
    this.updateDrilldown(this.drillDown);
  }

  updateDrilldown(drillDown) {
    this.drillDownData = {};
    let apiCall = this.reportsService.drillDownView(drillDown, this.reportGroupVar, this.reportDrillDownVar);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("DataLogic updateDrilldown(): ", this.drillDownData);
        if (data.rows.length === 0) {
          this.blankRes = true;
        } else {
          this.blankRes = false;
          this.drillDownData = data;
          this.drillDownData.query = drillDown;
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - DataLogic updateDrilldown(): ", this.drillDownData);
        subscribed.unsubscribe();
      }
    });
  }

  //Sort Drill Down Data
  handleDrillDownSortClick(data) {
    this.drillDown.studentIDFilter.sortFields = data.sortKey;
    this.updateDrilldown(this.drillDown);
  }

  //Updates which column and direction the table will be sorted by
  sortByColumn(data) {
    this.currentSortColumn = data.column;
    this.sortAscending = data.ascending;
  }
}

// Separate component for the DataLogic report's filter
export interface FilterParameters {
  params: {
    userState: string,
    authRoleClasses: any,
    fullStateArray: any,
    selectedStatesArray: any,
    statesSelectedFlag: boolean,
    recentPeriod: any,
    currentPeriod: any,
    dataViewType: string,
    stateList: any[],
    dataPeriodType: string,
    noyes: string
  }
}
@Component({
  selector: 'filterDataLogic',
  templateUrl: './filterDataLogic.html',
  styleUrls: ['./dataLogic.component.scss']
})
export class FilterDataLogic {
  filterParameters: DataLogicFilter;
  states: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<FilterDataLogic>,
    @Inject(MAT_DIALOG_DATA) public data: FilterParameters) {
    this.filterParameters = data.params;
  }

  resetFilter() {
    this.filterParameters.noyes = 'no';
    this.filterParameters.dataPeriodType = "No Period";
    this.filterParameters.dataViewType = "State";
    this.selectAll();
  }

  selectAll() {
    if (this.filterParameters.authRoleClasses.dataQuality == true) {
      this.filterParameters.selectedStatesArray = this.filterParameters.fullStateArray.map(state => state.key);
    } else {
      this.filterParameters.selectedStatesArray = [{ key: this.filterParameters.userState }];
    }
  }

  setSelectedStates() {
    var roles = this.filterParameters.authRoleClasses;
    if (roles.dataQuality == true) {
      this.filterParameters.stateList = this.filterParameters.selectedStatesArray;
    } else {
      let arr = [{ key: this.filterParameters.userState }]
      this.filterParameters.stateList = arr;
    }
  }

  selectNone() {
    this.filterParameters.selectedStatesArray = [];
    this.filterParameters.stateList = [];
  }


  //Filter Logic
  togglePeriodNo() {
    this.filterParameters.noyes = "no";
    this.filterParameters.dataPeriodType = 'No Period';
  }

  togglePeriodYes() {
    this.filterParameters.noyes = "yes";
    this.filterParameters.dataPeriodType = 'Most Recently Closed Period';
  }

  // sets the ng-checked to true or false based on if stateKey exists in the selectedStatesArray
  existsInSelectedStatesArray(stateKey) {
    if (this.filterParameters.selectedStatesArray.indexOf(stateKey) > -1) {
      return true;
    } else {
      return false;
    }
  }

  // adds and removes stateKeys from the selectedStatesArray based on user click of states' checkbox
  checkedStates(stateKey) {
    var statesIndex = this.filterParameters.selectedStatesArray.indexOf(stateKey);
    if (statesIndex === -1) {
      this.filterParameters.selectedStatesArray.push(stateKey);
    } else {
      this.filterParameters.selectedStatesArray.splice(statesIndex, 1);
    }
  }

  onSubmit() {
    if (this.filterParameters.authRoleClasses.dataQuality) {
      this.filterParameters.stateList = [];
      for (let i = 0; i < this.filterParameters.selectedStatesArray.length; i++) {
        let obj = {
          key: this.filterParameters.selectedStatesArray[i]
        }
        this.filterParameters.stateList[i] = obj; //use i instead of 0
      }
    }
    this.dialogRef.close(this.filterParameters);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the DataLogic report's Learn More dialog box
@Component({
  selector: 'dataLogicLearnMore',
  templateUrl: 'dataLogicLearnMore.html',
  styleUrls: ['./dataLogic.component.scss']
})
export class DataLogicLearnMore {
  constructor(
    public dialogRef: MatDialogRef<DataLogicLearnMore>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the DataLogic report's drilldown Learn More dialog box
@Component({
  selector: 'dataLogicDDLearnMore',
  templateUrl: 'dataLogicDDLearnMore.html',
  styleUrls: ['./dataLogic.component.scss']
})
export class DataLogicDDLearnMore {
  constructor(
    public dialogRef: MatDialogRef<DataLogicDDLearnMore>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}