<div class="searchResults-page" 
  [ngClass]="{'main-padding' : (!responsiveUI.isMobile && !responsiveUI.isTablet)}">

  <div class="background-grey1" [ngClass]="{'search-padding-mobile' : (responsiveUI.isMobile || responsiveUI.isTablet)}">

    <!--desktop and mobile search module-->
    <div *ngIf="!(responsiveUI.isMobile && responsiveUI.isPortrait)">
      <student-search></student-search>
    </div>

    <div *ngIf="responsiveUI.isMobile && showSearch" class="no-overflow-x">
      <student-search></student-search>
    </div>

    <!--using the following for testing purposes only-->
    <!--<p>Page Number {{pageNum}}</p>-->

    <div *ngIf="totalRecords === 0" class="center layout-flex-column">
      <h1 style="flex: auto" class="padding-left-10">No results found</h1>
    </div>
    <!--mobile view search results - START -->
    <div *ngIf="responsiveUI.isMobile && !showSearch" style="flex: 100%">
      <h1 class="padding-left-10" *ngIf="totalRecords > 1">{{totalRecords}} Results</h1>
      <h1 class="padding-left-10" *ngIf="totalRecords === 1">{{totalRecords}} Result</h1>
      <a class="padding-left-10" (click)="searchAgain()">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
        <span>Search Again</span>
      </a>
      <div class="search-results-divider thickHr"></div>
      <!-- <md-divider class="search-results-divider"></md-divider> -->
      <div [ngClass]="(studentIndex === idx) ? 'results-border-save' : 'results-border'"
           *ngFor="let student of fullList; let idx = index" style="padding: 5px;" >
        <div layout-padding (click)="showStudentDetailsFn(idx)" class="flexSpaceBetween" style="padding: 8px;">
          <div class="layout-flex-column">
            <label class="uppercase" flex>{{student.stuName}}</label>
            <div flex>
              <p class="small small-grey2andahal uppercase">DOB: {{student.dob}}
                |&nbsp;{{student.gender}}
                | {{student.stateName}}</p>
            </div>
          </div>
          <div>
            <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(studentIndex === idx)"></i>
            <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="studentIndex === idx"></i>
          </div>
        </div>

        <div *ngIf="studentIndex === idx">
          <div class="layout-flex-column">
            <div layout-padding class="layout-flex-row" class="saveRecordRow center" layout-align="center center">
              <button class="md-button no-margins" type="button" (click)="addToMyDefaultList(student.msixId)">
                SAVE TO MY DEFAULT LIST
              </button>
              <!--</div>-->
            </div>
            <div layout-padding>
              <div (click)="showStudentDetailsFn(idx)" class="layout-flex-row">
                <div style="flex:50%" class="layout-flex-column">
                  <block class="block-grey4" flex>MSIX ID:</block>
                  <label flex>{{student.msixId}}</label>
                </div>
                <div style="flex:50%" class="layout-flex-column">
                  <block class="block-gray4" flex>State ID:</block>
                  <label flex>{{student.stateId}}</label>
                </div>
              </div>
              <div class="center">
                <button class="md-button no-margins" (click)="viewDetails(student)">View Details</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--mobile view search results - END -->

    <!-- desktop/tablet search results - START -->
    <div class="layout-margin-2" *ngIf="!responsiveUI.isMobile && totalRecords > 0">

      <div class="removeBorders">
        <div class="table tableHeader">
          <div layout="row" class="tableRow headerRow">
            <h1 *ngIf="totalRecords > 1">{{totalRecords}}
              Results</h1>
            <h1 *ngIf="totalRecords === 1">{{totalRecords}}
              Result</h1>
          </div>
        </div>
      </div>

      <div class="headerTitles layout-flex-row">
        <div class="block2"  style="flex:30%">Student(s)</div>
        <div class="block2"  style="flex:10%">State</div>
        <div class="block2"  style="flex:10%">Sex</div>
        <div class="block2"  style="flex:15%">DOB</div>
        <div class="block2"  style="flex:17%">MSIX ID</div>
        <div class="block2"  style="flex:18%">State ID</div>
      </div>

      <div class="layout-flex-row table2Row" *ngFor="let student of fullList">
        <div class="flex-inline top-bottom-margin-15" style="flex: 100%">
          <a (click)="viewDetails(student)" style="flex:30%" aria-label="Student" tabindex="0">{{student.stuName}}</a>
          <p class="small" style="flex:10%">{{student.stateName}}</p>
          <p style="flex:10%" class="small uppercase">{{student.gender}}</p>
          <p class="small" style="flex:15%">{{student.dob}}</p>
          <p class="small" style="flex:17%">{{student.msixId}}</p>
          <p class="small" style="flex:18%">{{student.stateId}}</p>
        </div>
      </div>
    </div>
    <!-- desktop/tablet search results - END -->

    <div class="center">
      <button class="md-button" *ngIf="totalRecords - pageSize*searchParams.pageNum >= 1" (click)="loadMoreResults()">
        Load More
      </button>
    </div>

  </div>
</div>