<div class="student-search-wrapper">
  <div class="dashboard-module">
    <div class="hide-gt-xs dashboard-module-title-background">
      <h1>Student Record Search</h1>
    </div>
    
    <div class="hide-xs dashboard-module-title-background">
      <h3 class="dashboard-module-title-text-2">Student Record Search</h3>
    </div>

    <div class="dashboard-module-body">  

      <div [ngClass]="{'layout-flex-column': responsiveUI.isMobile}">

        <div class="student-record-search">
          <p>
            Ensure that your search contains at least one of the following elements: 
            First Name, Last Name, Date of Birth, ID, Parent First Name, or Parent Last Name. 
            Free form search fields allow for partial search and wild card search. 
            For more information on how to use partial search and wild card search, 
            click <a href="{{help_url}}help/index.htm#t=Student_Record_Search%2FSearching.htm" 
            target="_blank" rel="noopener noreferrer">here</a>. 
          </p>
          <button class="clear-button" (click)="resetSearchValues()" aria-label="Clear" tabindex="0">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span>Clear</span>
          </button>
        </div>
        <form *ngIf="states$ | async as states" [formGroup]="form" [ngClass]="{'layout-padding-l-14 layout-padding-r-14': (responsiveUI.isMobile && !responsiveUI.isPortrait)}">
          <div [ngClass]="responsiveUI.isMobile ? 'layout-flex-column': 'layout-flex-row'">
            <div [ngClass]="{'form-container' : !responsiveUI.isMobile, 'form-container-mobile': responsiveUI.isMobile}">
              <ng-container *ngIf="!advancedSearch">
                <mat-form-field appearance="outline">
                  <mat-label>First Name</mat-label>
                  <input (keydown.enter)="goToSearchResults()" matInput type="string" formControlName="firstName" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Last Name</mat-label>
                  <input (keydown.enter)="goToSearchResults()" matInput type="string" formControlName="lastName" />
                </mat-form-field>
              </ng-container>

              <ng-container *ngIf="advancedSearch">
                <mat-form-field appearance="outline">
                  <mat-label>First Name</mat-label>
                  <input (keydown.enter)="goToSearchResults()" matInput type="string" formControlName="firstName" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Middle Name</mat-label>
                  <input (keydown.enter)="goToSearchResults()" matInput type="string" formControlName="middleName" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Last Name 1</mat-label>
                  <input (keydown.enter)="goToSearchResults()" matInput type="string" formControlName="lastName" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Last Name 2</mat-label>
                  <input (keydown.enter)="goToSearchResults()" matInput type="string" formControlName="lastName2" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Sex</mat-label>
                  <mat-select formControlName="gender">
                    <mat-option [value]="1">Male</mat-option>
                    <mat-option [value]="2">Female</mat-option>
                    <mat-option [value]="3">Other</mat-option>
                  </mat-select>
                </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state">
                      <mat-option *ngFor="let state of states" [value]="state.stateKey">{{state.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Parent First Name</mat-label>
                    <input (keydown.enter)="goToSearchResults()" matInput type="string" formControlName="parentFirstName" />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Parent Last Name</mat-label>
                    <input (keydown.enter)="goToSearchResults()" matInput type="string" formControlName="parentLastName" />
                  </mat-form-field>
              </ng-container>
            </div>
            <div [ngClass]="{'form-container' : !responsiveUI.isMobile, 'form-container-mobile': responsiveUI.isMobile}">
              <div [ngClass]="{'dob-mobile': responsiveUI.isMobile}" class="dob-container">
                <p class="search-form-label dob">{{(responsiveUI.isMobile) ? 'DOB:' : 'Date of Birth:'}}</p>
                <mat-form-field appearance="outline">
                  <mat-label>MM</mat-label>
                  <input (keydown.enter)="goToSearchResults()" matInput formControlName="month" app-focus-next-element [requiredLength]="2" [maxlength]="2" [focusElement]="dd" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>DD</mat-label>
                  <input (keydown.enter)="goToSearchResults()" #dd matInput formControlName="day" app-focus-next-element [requiredLength]="2" [maxlength]="2" [focusElement]="yyyy" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>YYYY</mat-label>
                  <input (keydown.enter)="goToSearchResults()" #yyyy matInput formControlName="year" [maxlength]="4" />
                </mat-form-field>
              </div>
              <div [ngClass]="{'id-type-wrapper' : responsiveUI.isDesktop || responsiveUI.isTablet, 'id-type-wrapper-mobile': !responsiveUI.isDesktop && !responsiveUI.isTablet}">
                
                <mat-form-field appearance="outline">
                  <mat-label>ID type</mat-label>
                  <mat-select formControlName="identifierType">
                    <mat-option value="msix">MSIX ID</mat-option>
                    <mat-option value="state">State ID</mat-option>
                    <mat-option value="alternate">Alternate ID</mat-option>
                  </mat-select>
                </mat-form-field>
                    
                <mat-form-field appearance="outline">
                  <mat-label>ID</mat-label>
                  <input (keydown.enter)="goToSearchResults()" matInput type="string" formControlName="identifier" />
                </mat-form-field>
              </div>
              <ng-container *ngIf="advancedSearch">
                <mat-form-field appearance="outline">
                  <mat-label>Multi-Birth</mat-label>
                  <mat-select formControlName="multiBirth">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>District of Residence/School District Name</mat-label>
                  <input (keydown.enter)="goToSearchResults()" matInput type="string" formControlName="schoolDistrict" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>School or Project Name</mat-label>
                  <input (keydown.enter)="goToSearchResults()" matInput type="string" formControlName="schoolProjectName" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Facility Name</mat-label>
                  <input (keydown.enter)="goToSearchResults()" matInput type="string" formControlName="facilityName" />
                </mat-form-field>
              </ng-container>
            </div>
          </div>
          <div class="action-button">
              <button [ngClass]="{'no-top-bottom-right-margin-padding': responsiveUI.isMobile, 'clear-button': true}" 
              (click)="toggleSearch()" aria-label="Search Type" tabindex="0" type="button">
                <span>{{advancedSearch ? 'Basic Search' : 'Advanced Search'}}</span>
              </button>

              <button class="md-button" 
              type="button"
              (click)="goToSearchResults()" 
              [disabled]="!(form.valid && (form.get('identifier').value?.trim() || form.get('firstName').value?.trim() || form.get('lastName').value?.trim() || form.get('parentFirstName').value?.trim() || form.get('parentLastName').value?.trim() || (form.get('year').value?.trim() && form.get('year').valid)))">
              Search</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
