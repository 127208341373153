<div class="create-edit-delete-new">
    <div class="header">
      <h2>Create New List</h2>
      <button class="md-button" type="button" (click)="onClose()" (keypress)="onClose()">
        <i class="fa fa-times ng-scope"></i>
      </button>
    </div>

    <form (ngSubmit)="createNewSaveList()">
      <div class="hint">Enter a name for the new list:</div>
      <mat-form-field appearance="outline">
        <mat-label>List Name</mat-label>
        <input type="text" matInput [(ngModel)]="newListName" 
        name="newListName" />
        
      </mat-form-field>
      <div class="error" *ngIf="newListName.length > 60">List names must be 60 characters or less.</div>
      <div class="error" *ngIf="checkCreateListName()">List name already exists. Enter a different name.</div>

      <div class="action-buttons">
        <button class="cancel-btn" (click)="onClose()" mat-button>Cancel</button>
        <button class="md-button" type="submit"
          [disabled]="!newListName || listNameExists || newListName.length > 60">Submit</button>
      </div>
    </form>
  
</div>