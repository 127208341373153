<div class="home-page background-grey1 background-creative">

  <div class="dashboard-tabs">

    <div class="flex-inline" *ngIf="(responsiveUI.isMobile || responsiveUI.isTablet)">
      <div *ngIf="authRoleClasses.studentSearch" class="block customTab flex-container" style="flex:auto"
        (click)="tabIndex = '0'" [ngClass]="{'tabActive': (tabIndex === '0')}">
        Search
      </div>
      <div *ngIf="authRoleClasses.stateAdmin || authRoleClasses.regionalAdmin || authRoleClasses.districtAdmin"
        class="block customTab flex-container" style="flex:auto" (click)="tabIndex = '1'"
        [ngClass]="{'tabActive': (tabIndex === '1')}">
        Admin
      </div>
      <div *ngIf="authRoleClasses.worklist" class="block customTab flex-container" style="flex:auto"
        (click)="tabIndex = '2'" [ngClass]="{'tabActive': (tabIndex === '2')}">
        Worklist
      </div>
    </div>

    <hr class="thickHr" *ngIf="(responsiveUI.isMobile || responsiveUI.isTablet)">

    <div class="tab-content">
      <div class="welcome-container">
        <p class="heading">Welcome <span>{{user}}</span></p>
        <p>MSIX facilitates the exchange of migrant student records to ensure the appropriate
          enrollment, placement, and accrual of credits for migrant children nationwide.</p>
      </div>

      <!-- student search -->
      <student-search *ngIf="authRoleClasses.studentSearch || tabIndex === '0'"></student-search>
      

      <!-- user search -->
      <div *ngIf="(tabIndex == '1' || responsiveUI.isDesktop) && (authRoleClasses.stateAdmin || authRoleClasses.regionalAdmin 
      || authRoleClasses.districtAdmin)">
        <app-user-search></app-user-search>
      </div>
      <div class="layout-flex-row"
          *ngIf="responsiveUI.isDesktop && authRoleClasses.studentSearch && authRoleClasses.sendInfo">
          <div style="flex: 100%" class="dashboard-module">
            <div layout-padding layout-align="center center"
              class="layout-flex-row dashboard-module-title-background display-block">
              <h3 *ngIf="!responsiveUI.isMobile" class="dashboard-module-title-text-2">Data Requests</h3>
            </div>
            <div layout-padding class="dashboard-module-body module-body-overflow layout-margin-2">
              <div *ngIf="dataRequestItems.length == 0">
                <p>You do not have any data requests.</p>
              </div>
              <div *ngIf="!responsiveUI.isMobile && dataRequestItems.length > 0">
                <div *ngFor="let dR of dataRequestItems">
                  <div class="layout-flex-row drRow flex-inline" flex>
                    <div class="layout-flex-column" style="flex: 80%">
                      <div>
                        <a class="drStudentName" (click)="viewStudentDetails(dR.demographics)" 
                        (keypress)="viewStudentDetails(dR.demographics)" tabindex="0">
                          {{dR.demographics.lastName1}},&nbsp;{{dR.demographics.firstName}}&nbsp;{{dR.demographics.midName}}
                        </a>
                        <p class="small uppercase" flex>
                          ID: {{dR.key}} | DOB: {{dR.demographics.birthDate}} | {{dR.demographics.gender}}
                        </p>
                      </div>
                    </div>
                    <div class="layout-flex-column flex-inline" style="flex: 20%">
                      <p *ngIf="dR.daysToResolve<= 0 && !showDate">
                        Past Due
                        <i class="fa fa-exclamation-triangle color-red" aria-hidden="true"></i>
                      </p>
                      <p *ngIf="dR.daysToResolve == 1 && !showDate">
                        {{dR.daysToResolve}} day
                        <i class="fa fa-exclamation-circle color-mustard" aria-hidden="true"></i>
                      </p>
                      <p *ngIf="dR.daysToResolve > 1 && !showDate">{{dR.daysToResolve}} days
                      </p>
                      <p *ngIf="showDate">{{dR.formattedDate}}
                      </p>
                      <p *ngIf="showDate">{{dR.daysSinceCreation}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Data request for Desktop mode. - End -->

      </div>

    <!-- Data request for Mobile and Tablet mode. - Start -->
    <div *ngIf="tabIndex == '2' && authRoleClasses.worklist">

      <div class="medium" *ngIf="!responsiveUI.isDesktop">

        <div flex class="mobile-content-padding">
          <div layout-padding layout-align="center center">
            <h1 flex="70" class="h1-no-margins no-padding-left">Worklist</h1>
          </div>

          <div>
            <div class="mobile-data-padding"
              *ngIf="(mergeWorklist.length === 0 && splitWorklist.length === 0 && validateMergeItems.length === 0 &&
         validateSplitItems.length === 0 && dataRequestItems.length === 0 && residencyChangeItems.length === 0 && nominateMatchItems.length === 0)">
              <p>No worklist items found</p>
            </div>

            <!--Data Requests-->

            <div flex class="worklist-module top-bottom-margin-5"
              *ngIf="!responsiveUI.isDesktop && dataRequestItems.length > 0">
              <div class="dashboard-module-body home-worklist-layout-padding">
                <!--mobile & tablet data requests-->
                <div class="top-bottom-margin-8">
                  <div class="layout-padding-l layout-padding-r">
                    <div class="desktopEnrollments removeBorders">
                      <div class="tableHeader">
                        <div class="layout-flex-row tableRow headerRow no-padding">
                          <p class="worklist-section-header">Data Requests</p>
                        </div>
                      </div>
                    </div>
                    <div class="layout-padding-bottom-15">
                      <p>To resolve a data request, access your worklist on desktop.</p>
                    </div>

                    <div *ngIf="!responsiveUI.isDesktop">
                      <div class="results-border top-bottom-padding-8" *ngFor="let student of dataRequestItems">
                        <div class="layout-flex-row" (click)="viewDetails(student)">
                          <div class="layout-flex-column">
                            <a class="uppercase">{{student.demographics.lastName1}}<span
                                *ngIf="student.demographics.lastName2 !=''"
                                class="removeUnderline">&nbsp;{{student.demographics.lastName2}}</span>,
                              {{student.demographics.firstName}}<span class="removeUnderline"
                                *ngIf="student.demographics.midName != ''">&nbsp;{{student.demographics.midName}}</span>
                            </a>
                            <div flex>
                              <p class="small small-grey2andahalf uppercase">ID: {{student.key}} | DOB:
                                {{student.demographics.birthDate}} |&nbsp;{{student.demographics.gender}} |
                                {{student.demographics.submittingStateAbbv}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!--Validate Merge-->

            <div flex class="worklist-module top-bottom-margin-5"
              *ngIf="!responsiveUI.isDesktop && validateMergeItems.length > 0">
              <div class="dashboard-module-body home-worklist-layout-padding">
                <!--mobile & tablet validate merge-->
                <div class="top-bottom-margin-8">
                  <div class="layout-padding-l layout-padding-r">
                    <div class="desktopEnrollments removeBorders">
                      <div class="tableHeader">
                        <div class="layout-flex-row tableRow headerRow no-padding">
                          <p class="worklist-section-header">Validate Merge Records</p>
                        </div>
                      </div>
                    </div>
                    <div class="layout-padding-bottom-15">
                      <p>To validate a merge, access your worklist on desktop.</p>
                    </div>
                    <div *ngIf="!responsiveUI.isDesktop">
                      <div class="results-border top-bottom-padding-8" *ngFor="let student of validateMergeItems">
                        <div class="layout-flex-row" (click)="viewDetails(student)">
                          <div class="layout-flex-column">
                            <a class="uppercase">{{student.demographics.lastName1}}<span
                                *ngIf="student.demographics.lastName2 !=''"
                                class="removeUnderline">&nbsp;{{student.demographics.lastName2}}</span>,
                              {{student.demographics.firstName}}<span class="removeUnderline"
                                *ngIf="student.demographics.midName != ''">&nbsp;{{student.demographics.midName}}</span>
                            </a>
                            <div flex>
                              <p class="small small-grey2andahalf uppercase">ID: {{student.key}} | DOB:
                                {{student.demographics.birthDate}} |&nbsp;{{student.demographics.gender}} |
                                {{student.demographics.submittingStateAbbv}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!--Process Merge-->

            <div flex class="worklist-module top-bottom-margin-5"
              *ngIf="!responsiveUI.isDesktop && mergeWorklist.length > 0">
              <div class="dashboard-module-body home-worklist-layout-padding">
                <!--mobile & tablet merge-->
                <div class="top-bottom-margin-8">
                  <div class="layout-padding-l layout-padding-r">
                    <div class="desktopEnrollments removeBorders">
                      <div class="tableHeader">
                        <div class="layout-flex-row tableRow headerRow no-padding">
                          <p class="worklist-section-header">Merge Records</p>
                        </div>
                      </div>
                    </div>
                    <div class="layout-padding-bottom-15">
                      <p>To complete a merge request, access your worklist on desktop.</p>
                    </div>
                    <div>
                      <div class="results-border top-bottom-padding-8" *ngFor="let student of mergeWorklist">
                        <div class="layout-flex-row">
                          <div style="flex:65%" class="layout-flex-column" (click)="viewDetails(student)">
                            <a class="uppercase">{{student.demographics.lastName1}}<span
                                *ngIf="student.demographics.lastName2 !=''"
                                class="removeUnderline">&nbsp;{{student.demographics.lastName2}}</span>,
                              {{student.demographics.firstName}}<span class="removeUnderline"
                                *ngIf="student.demographics.midName != ''">&nbsp;{{student.demographics.midName}}</span>
                            </a>
                            <div flex>
                              <p class="small small-grey2andahalf uppercase">ID: {{student.key}} | DOB:
                                {{student.demographics.remobirthDate}} |&nbsp;{{student.demographics.gender}} |
                                {{student.demographics.submittingStateAbbv}}</p>
                            </div>
                          </div>
                          <div style="flex:35%" class="layout-flex-row" layout-align="end center">
                            <div class="mobile-worklist-remove-button remove-button no-margins"
                              id="mobileTabletRemoveButton" (click)="removeFromMergeList(student)">
                              Remove
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!--Near Match-->

            <div flex class="worklist-module top-bottom-margin-5"
              *ngIf="!responsiveUI.isDesktop && nominateMatchItems.length > 0">
              <div class="dashboard-module-body home-worklist-layout-padding">
                <!--mobile & tablet merge-->
                <div class="top-bottom-margin-8">
                  <div class="layout-padding-l layout-padding-r">
                    <div class="desktopEnrollments removeBorders">
                      <div class="tableHeader">
                        <div class="layout-flex-row tableRow headerRow no-padding">
                          <p class="worklist-section-header">Near Matches</p>
                        </div>
                      </div>
                    </div>
                    <div class="layout-padding-bottom-15">
                      <p>To complete a merge request, access your worklist on desktop.</p>
                    </div>
                    <div>
                      <div class="results-border top-bottom-padding-8" *ngFor="let student of nominateMatchItems">
                        <div class="layout-flex-row">
                          <div class="layout-flex-column" (click)="viewDetails(student)">
                            <a class="uppercase">{{student.demographics.lastName1}}<span
                                *ngIf="student.demographics.lastName2 !=''"
                                class="removeUnderline">&nbsp;{{student.demographics.lastName2}}</span>,
                              {{student.demographics.firstName}}<span class="removeUnderline"
                                *ngIf="student.demographics.midName != ''">&nbsp;{{student.demographics.midName}}</span>
                            </a>
                            <div flex>
                              <p class="small small-grey2andahalf uppercase">ID: {{student.key}} | DOB:
                                {{student.demographics.birthDate}} |&nbsp;{{student.demographics.gender}} |
                                {{student.demographics.submittingStateAbbv}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Validate Split-->

            <div class="worklist-module top-bottom-margin-5"
              *ngIf="!responsiveUI.isDesktop && validateSplitItems.length > 0">
              <div class="dashboard-module-body home-worklist-layout-padding">
                <!--mobile & tablet validate split-->
                <div class="top-bottom-margin-8">
                  <div class="layout-padding-l layout-padding-r">
                    <div class="desktopEnrollments removeBorders">
                      <div class="tableHeader">
                        <div class="layout-flex-row tableRow headerRow no-padding">
                          <p class="worklist-section-header">Validate Split Records</p>
                        </div>
                      </div>
                    </div>
                    <div class="layout-padding-bottom-15">
                      <p>To validate a split, access your worklist on desktop.</p>
                    </div>
                    <div *ngIf="!responsiveUI.isDesktop">
                      <div class="results-border top-bottom-padding-8" *ngFor="let student of validateSplitItems">
                        <div class="layout-flex-row" (click)="viewDetails(student.master)">
                          <div class="layout-flex-column">
                            <a class="uppercase">{{student.master.demographics.lastName1}}<span
                                *ngIf="student.master.demographics.lastName2 !=''"
                                class="removeUnderline">&nbsp;{{student.master.demographics.lastName2}}</span>,
                              {{student.master.demographics.firstName}}<span class="removeUnderline"
                                *ngIf="student.demographics.midName != ''">&nbsp;{{student.demographics.midName}}</span>
                            </a>
                            <div flex>
                              <p class="small small-grey2andahalf uppercase">ID: {{student.master.key}} | DOB:
                                {{student.master.demographics.birthDate}} |&nbsp;{{student.master.demographics.gender}}
                                | {{student.master.demographics.submittingStateAbbv}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Process Split-->

            <div class="worklist-module top-bottom-margin-5" *ngIf="!responsiveUI,isDesktop splitWorklist.length > 0">
              <div class="dashboard-module-body home-worklist-layout-padding">
                <!--mobile & tablet split-->
                <div class="top-bottom-margin-8">
                  <div class="layout-padding-l layout-padding-r">
                    <div class="desktopEnrollments removeBorders top-bottom-margin-5">
                      <div class="tableHeader">
                        <div class="layout-flex-row tableRow headerRow no-padding">
                          <p class="worklist-section-header">Split Records</p>
                        </div>
                      </div>
                    </div>
                    <div class="layout-padding-bottom-15">
                      <p>To complete a split request, access your worklist on desktop.</p>
                    </div>
                    <div *ngIf="!responsiveUI.isDesktop">
                      <div class="results-border top-bottom-padding-8" *ngFor="let student of splitWorklist">
                        <div class="layout-flex-row">
                          <div style="flex: 65%" class="layout-flex-row" (click)="viewDetails(student)">
                            <a class="uppercase">{{student.demographics.lastName1}}<span
                                *ngIf="student.demographics.lastName2 !=''"
                                class="removeUnderline">&nbsp;{{student.demographics.lastName2}}</span>,
                              {{student.demographics.firstName}}<span class="removeUnderline"
                                *ngIf="student.demographics.midName != ''">&nbsp;{{student.demographics.midName}}</span>
                            </a>
                            <div flex>
                              <p class="small small-grey2andahalf uppercase">ID: {{student.key}} | DOB:
                                {{student.demographics.birthDate}} |&nbsp;{{student.demographics.gender}} |
                                {{student.demographics.submittingStateAbbv}}</p>
                            </div>
                          </div>
                          <div style="flex:35%" class="layout-flex-row" layout-align="end center">
                            <div class="mobile-worklist-remove-button remove-button"
                              (click)="removeFromSplitList(student)">
                              Remove
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Move Notices-->

            <div class="worklist-module top-bottom-margin-5"
              *ngIf="!responsiveUI.isDesktop && residencyChangeItems.length > 0">
              <div class="dashboard-module-body home-worklist-layout-padding">
                <!--mobile & tablet data requests-->
                <div class="top-bottom-margin-8">
                  <div class="layout-padding-l layout-padding-r">
                    <div class="desktopEnrollments removeBorders">
                      <div class="tableHeader">
                        <div class="layout-flex-row tableRow headerRow no-padding">
                          <p class="worklist-section-header">Move Notices</p>
                        </div>
                      </div>
                    </div>
                    <div class="layout-padding-bottom-15">
                      <p>To dismiss a move notice, access your worklist on desktop.</p>
                    </div>
                    <div *ngIf="!responsiveUI.isDesktop">
                      <div class="results-border top-bottom-padding-8" *ngFor="let student of residencyChangeItems">
                        <div class="layout-flex-row" (click)="viewDetails(student)">
                          <div class="layout-flex-column">
                            <a class="uppercase">{{student.demographics.lastName1}}<span
                                *ngIf="student.demographics.lastName2 !=''"
                                class="removeUnderline">&nbsp;{{student.demographics.lastName2}}</span>,
                              {{student.demographics.firstName}}<span class="removeUnderline"
                                *ngIf="student.demographics.midName != ''">&nbsp;{{student.demographics.midName}}</span>
                            </a>
                            <div flex>
                              <p class="small small-grey2andahalf uppercase">ID: {{student.key}} | DOB:
                                {{student.demographics.birthDate}} |&nbsp;{{student.demographics.gender}} |
                                {{student.demographics.submittingStateAbbv}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>

      </div>

    </div>
    <!-- Data request for Mobile and Tablet mode. - End -->

  </div>
</div>