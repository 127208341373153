import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { StateService } from '../../services/state.service';
import { SearchService } from 'src/app/services/search.service';
import { AppConstants } from 'src/app/config/index.constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { IStateDataShort } from 'src/app/types/interfaces';

@Component({
  selector: 'student-search',
  templateUrl: './student-search.component.html',
  styleUrls: ['./student-search.component.scss']
  })
export class StudentSearchComponent implements OnInit {
  states$: Observable<IStateDataShort[]>;
  help_url = AppConstants.help;
  advancedSearch = false;
  form: FormGroup = null;

  constructor(private stateService: StateService,
    private router: Router,
    private route: ActivatedRoute,
    public responsiveUI: ResponsiveUIService,
    private searchService: SearchService, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.getStates();
    this.initForm();
    if (this.route.snapshot.queryParams) {
      const {middleName, gender, multiBirth, parentFirstName, parentLastName, state, birthState, birthCountry, schoolDistrict, schoolProjectName, facilityName, lastName2 } = this.route.snapshot.queryParams;
      if (middleName || gender || multiBirth || parentFirstName ||
          parentLastName || state || birthState || birthCountry ||
          schoolDistrict || schoolProjectName || facilityName || lastName2) {
        this.advancedSearch = true;
      }
    }
  }
  
  async getStates() {
    this.states$ = this.stateService.getStatesData();
  }


  initForm() {
    this.form = this.fb.group({
      firstName: [''],
      middleName: [''],
      lastName: [''],
      lastName2: [''],
      username: [''],
      gender: [''],
      month: ['', [Validators.pattern(/\d/), Validators.max(12), Validators.min(1)]],
      day: ['', [Validators.pattern(/\d/), Validators.max(31), Validators.min(1)]],
      year: ['', [Validators.pattern(/\d/), Validators.max(2040), Validators.min(1900)]],
      multiBirth: [''],
      parentFirstName: [''],
      parentLastName: [''],
      state: [''],
      birthState: [''],
      birthCountry: [''],
      schoolDistrict: [''],
      schoolProjectName: [''],
      facilityName: [''],
      identifier: [''],
      identifierType: ['msix'],
    });
    const routeParams = this.route.snapshot.queryParams;
    if (Object.values(routeParams).length) {
      this.form.reset(routeParams)
    }
  }

  resetSearchValues() {
    this.form.reset({identifierType: 'msix'});
  }

  toggleSearch(): void{
    this.advancedSearch = !this.advancedSearch;
  }

  goToSearchResults() {
    if (!this.form.valid || (!this.form.get('year').value?.trim() && !this.form.get('identifier').value?.trim() && 
    !this.form.get('firstName').value?.trim() && 
    !this.form.get('lastName').value?.trim() && 
    !this.form.get('parentFirstName').value?.trim() && 
    !this.form.get('parentLastName').value?.trim())) {
      return;
    }
    const userActivity = {
      msixID: '',
      pageName: 'APP_STU_SRCH',
      activityNotes: '',
      sessionID: ''
    };

    this.searchService.logUserActivity(userActivity);

    if (this.router.url.includes('/searchResults')) {
      this.router.navigate([], {
        queryParams: {...this.form.value, pageNum: 1}
      })
    } else {
      this.router.navigate(['searchResults'], {
        queryParams: {...this.form.value, pageNum: 1}
      });
    }
  }
 }
